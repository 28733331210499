import React, { useCallback, useEffect, useRef, useState } from 'react';
import ActivityLoader from '../../common/ui/activity_loader/ActivityLoader';
import LoadMoreBtn from '../../common/ui/load_more_btn/LoadMoreBtn';
import { formatDateDDMonthYYYY, getUrlWithSpecificRendition } from '../../utils/common';
import PlayIconOverlay from '../../common/ui/play_icon_overlay/PlayIconOverlay';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_HEADER_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  ERROR_MESSAGES,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
  SCREEN_DENSITY_QUALIFIERS,
  SEARCH_DEBOUNCE_WAIT_TIME,
} from '../../common/constants/AppConstants';
import { getFeatureComponentUrl, getHeaderComponentUrl } from '../../utils/accessibility';
import DefaultContentView from '../../common/ui/default_content_view/DefaultContentView';
import { logEvent } from '../../utils/FirebaseAnalyticsUtils';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { saveSearchText, selectSearchText, selectShowEmptyContentView } from '../../state/UIState';
import { selectAccessibilityData } from '../../state/AccessibilityData';
import { apiRequest, handleError } from '../../services/Service';
import { CONTESTS_SEARCH } from '../../services/ApiUrls';
import SectionHeader from '../../common/ui/section_header/SectionHeader';
import debounce from 'lodash.debounce';

const ContestsSearchScreen = () => {
  const showEmptyContentView = useSelector(selectShowEmptyContentView);
  const [contests, setContests] = useState([]);
  const [loading, setLoading] = useState(true);
  const accessibilityData = useSelector(selectAccessibilityData);
  const [loadMoreBtn, setLoadMoreBtn] = useState(false);
  const history = useHistory();
  const [dataLength, setDataLength] = useState(0);
  const loadMoreFlag = useRef(true);
  const pageId = useRef(1);
  const [error, setError] = useState(false);
  const dispatch = useDispatch();
  const searchData = useSelector(selectSearchText);
  const [searchText, setSearchText] = useState(searchData ?? '');
  const [isSearchEnabled, setIsSearchEnabled] = useState(true);

  const onClickSearchHandler = () => {
    setIsSearchEnabled(true);
  };

  const resetValue = () => {
    history.goBack();
  };

  useEffect(() => {
    if (pageId.current !== 1) {
      loadMoreFlag.current = false;
      setLoadMoreBtn(false);
    }
  }, [dataLength]);

  useEffect(() => {
    logEvent(ANALYTICS_EVENT_TYPES.CONTESTS_TAB_VISIT, 'search', ANALYTICS_ITEM_NAMES.CONTESTS);
  }, []);

  const callContestApi = useCallback(async (query = '') => {
    setError(false);
    if (loadMoreFlag.current === true) {
      setLoading(true);
      if (pageId.current === 1) {
        setContests([]);
      }
      const get_params = {
        page_id: pageId.current,
        query: query,
      };
      try {
        const apiResponse = await apiRequest(CONTESTS_SEARCH, REQUEST_TYPE.GET, get_params);
        if (Object.keys(apiResponse).length > 0) {
          if (apiResponse.response.status === true) {
            if (apiResponse.response.data) {
              const data = apiResponse.response.data;

              if (data && data.contests && data.contests.length > 0) {
                if (pageId.current === 1) {
                  setContests(data.contests);
                } else {
                  setContests((oldArray) => [...oldArray, ...data.contests]);
                }
                pageId.current = pageId.current + 1;
                loadMoreFlag.current = true;
              } else {
                loadMoreFlag.current = false;
              }
            }
          }
        }
        setLoading(false);
      } catch (err) {
        handleError(err, get_params, CONTESTS_SEARCH, NAVIGATION_ROUTES.CONTESTS_SEARCH);
        console.error(err);
        setLoading(false);

        setError(true);
      }
    } else {
      null;
    }
  }, []);

  useEffect(() => {
    if (searchText) {
      callSubmitFunction();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const debouncedCallback = (event) => {
    setSearchText(event.target.value);
    if (event && event.target.value.length > 2) {
      dispatch(saveSearchText(event.target.value));
      pageId.current = 1;
      loadMoreFlag.current = true;
      callContestApi(event.target.value);
    }
  };

  const debounceInputHandler = useCallback(
    debounce(debouncedCallback, SEARCH_DEBOUNCE_WAIT_TIME),
    []
  );
  const onInputChange = (event) => {
    setSearchText(event.target.value);
    debounceInputHandler(event);
  };

  const callSubmitFunction = (event) => {
    event?.preventDefault();
    if (searchText !== '') {
      dispatch(saveSearchText(searchText));
      setContests([]);
      pageId.current = 1;
      loadMoreFlag.current = true;
      callContestApi(searchText);
    }
  };

  const loadMore = () => {
    if (loadMoreFlag.current) {
      pageId.current = pageId.current + 1;
      callContestApi(searchText);
    }
  };

  const emptyListView = () => {
    return (
      <DefaultContentView
        message={ERROR_MESSAGES.NO_DATA_FOUND}
        iconUri={getFeatureComponentUrl(
          accessibilityData,
          ACCESSIBILITY_FEATURE_COMPONENTS.CELEBRATIONS_SEARCH_FAILED,
          ACCESSIBILITY_IDENTIFIERS.CELEBRATIONS
        )}
      />
    );
  };

  const onPressItem = (item) => {
    history.push(`${NAVIGATION_ROUTES.CONTESTS}/${item.contest_id}`);
  };

  const contestArray =
    contests &&
    contests.map((item, index) => {
      return (
        <div
          key={`${index}`}
          className="col-sm-4 mb-2 common-cursor-pointer"
          onClick={() => onPressItem(item)}>
          <div>
            {item?.content_load_type === '3' && <PlayIconOverlay />}
            <img
              src={getUrlWithSpecificRendition(
                item.promo_image.base_url,
                SCREEN_DENSITY_QUALIFIERS.HDPI,
                item.promo_image.image_path
              )}
              className="embed-responsive embed-responsive-16by9"
            />
            <div className="row ml-1 mt-1">
              <img
                src={getFeatureComponentUrl(
                  accessibilityData,
                  ACCESSIBILITY_FEATURE_COMPONENTS.NEWS_CALENDER,
                  ACCESSIBILITY_IDENTIFIERS.NEWS
                )}
                className="event-calender-style"
              />
              <div className="contests-event-date">
                {formatDateDDMonthYYYY(item.start_date + ' ' + item.start_time)}
              </div>
            </div>
          </div>
          <div className="contests-title">{item.title}</div>
          <div className="contests-summary">{item.summary}</div>
        </div>
      );
    });

  return (
    <div className="container">
      <div className="gcdo-header-row header-view-absolute justify-content-end float-right w-50">
        <div
          className={
            isSearchEnabled ? 'search-bar-with-icon search-bar-border' : 'search-bar-with-icon'
          }>
          {isSearchEnabled ? (
            <form className="directory-search-bar" onSubmit={callSubmitFunction}>
              <input
                autoFocus
                className="ml-2 directory-search-bar"
                type="text"
                placeholder="Search.."
                onChange={onInputChange}
                value={searchText}
              />
            </form>
          ) : (
            <div className="directory-search-bar" />
          )}
          {isSearchEnabled ? (
            <div className="gcdo-icon-row mr-2" onClick={resetValue}>
              <img
                src={getHeaderComponentUrl(
                  accessibilityData,
                  ACCESSIBILITY_HEADER_COMPONENTS.SEARCH_CANCEL
                )}
                className="header-icon"
              />
            </div>
          ) : (
            <div className="gcdo-icon-row mr-2" onClick={onClickSearchHandler}>
              <img
                src={getHeaderComponentUrl(
                  accessibilityData,
                  ACCESSIBILITY_HEADER_COMPONENTS.SEARCH
                )}
                className="header-icon"
              />
            </div>
          )}
        </div>
      </div>
      <SectionHeader identifier={ACCESSIBILITY_IDENTIFIERS.CONTESTS} />
      {showEmptyContentView && !contestArray.length && !loading ? (
        emptyListView()
      ) : (
        <div>
          <div className="row main-content-container">{contestArray}</div>
          <ActivityLoader visible={loading} />
          {loadMoreBtn && (
            <div className="mb-4 mt-4">
              <LoadMoreBtn onClick={loadMore} isLoading={loading} />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ContestsSearchScreen;
