import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Tab } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { saveNewJoineesTabData, selectNewJoineesTabData } from '../../state/TabData';
import {
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_SCREEN_NAMES,
  NAVIGATION_ROUTES,
  NEW_JOINEES_LOWER_LEVELS_IDENTIFIERS,
  REQUEST_TYPE,
} from '../../common/constants/AppConstants';
import SectionHeader from '../../common/ui/section_header/SectionHeader';
import NavTabs from '../../common/ui/tabs/NavTabs';
import { GET_NEW_JOINEES_LOWER_LEVELS_DATA, JOINERS_FEEDBACK } from '../../services/ApiUrls';
import { apiRequest } from '../../services/Service';
import { selectNewJoineesLowerLevelsTabs } from '../../state/MasterData';
import { saveProgressLoadingState, selectBaseUrl } from '../../state/UIState';
import { trackScreen } from '../../utils/FirebaseAnalyticsUtils';
import HigherLevelsSessionTab from './tabs/HigherLevelsTabs/HigherLevelsSessionTab';
import LowerLevelsChecklist from './tabs/LowerLevelsTabs/LowerLevelsChecklistTab';
import LowerLevelsOverviewTab from './tabs/LowerLevelsTabs/LowerLevelsOverviewTab';
import LowerLevelsQuickLinksTab from './tabs/LowerLevelsTabs/LowerLevelsQuickLinksTab';
import _ from 'lodash';
import AllInductionTab from './tabs/HigherLevelsTabs/AllInductionTab';
import FeedbackTab from './tabs/HigherLevelsTabs/FeedbackTab';

const NewJoineesLowerLevelsScreen = ({ location }) => {
  const dispatch = useDispatch();
  const baseUrl = useSelector(selectBaseUrl);
  const [newJoineesData, setNewJoineesData] = useState({});
  const [newJoineeAllInductionData, setNewJoineeAllInductionData] = useState([]);
  const [selectedChecklistItemCount, setSelectedChecklistItemCount] = useState();
  const [error, setError] = useState(false);
  const [key, setKey] = useState(NEW_JOINEES_LOWER_LEVELS_IDENTIFIERS.OVERVIEWS);
  const totalChecklistItemCountRef = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const myTabsList = useSelector(selectNewJoineesLowerLevelsTabs);
  const [newJoineesLowerLevelsTabTitles, setNewJoineesLowerLevelsTabTitles] = useState([]);
  const tabData = useSelector(selectNewJoineesTabData);
  const { state } = location;
  const [dataLength, setDataLength] = useState(0);
  const [surveys, setSurveys] = useState([]);
  const [showSurveys, setShowSurveys] = useState(false);
  const [currentPageId, setCurrentPageId] = useState(1);

  useEffect(() => {
    if (tabData && newJoineesLowerLevelsTabTitles?.length) {
      setKey(tabData);
    }
  }, [tabData, newJoineesLowerLevelsTabTitles]);

  useEffect(() => {
    // Analytics
    trackScreen(
      ANALYTICS_SCREEN_NAMES.NEW_JOINEES_LOWER_LEVELS,
      NAVIGATION_ROUTES.NEW_JOINEES_LOWER_LEVELS
    );
  }, []);

  const callNewJoineesApi = useCallback(
    async (filter = '', pageId = 1) => {
      setError(false);
      try {
        const params1 = {
          route_type: filter,
          page_id: pageId,
        };
        dispatch(saveProgressLoadingState({ isProgressLoading: true }));
        setCurrentPageId(pageId);
        const [newJoineesResponse, feedbackResponse] = await Promise.all([
          apiRequest(GET_NEW_JOINEES_LOWER_LEVELS_DATA, REQUEST_TYPE.GET, params1),
          apiRequest(JOINERS_FEEDBACK, REQUEST_TYPE.GET, {}),
        ]);
        dispatch(saveProgressLoadingState({ isProgressLoading: false }));
        if (Object.keys(feedbackResponse).length > 0) {
          const data = feedbackResponse.response.data;
          setShowSurveys(feedbackResponse.response.data.show_feedback_tab);
          setSurveys(data.surveys);
        }
        if (Object.keys(newJoineesResponse).length > 0) {
          if (newJoineesResponse.response.status) {
            const data = newJoineesResponse.response.data;
            const allInductionData = data?.all_inductions;
            setDataLength(allInductionData?.length);
            setNewJoineesData(data);
            if (pageId === 1) {
              setNewJoineeAllInductionData(allInductionData);
            } else {
              setNewJoineeAllInductionData((prev) => [...prev, ...allInductionData]);
            }
          }
        }
      } catch (err) {
        setError(true);
        dispatch(saveProgressLoadingState({ isProgressLoading: false }));
      }
    },
    [dispatch]
  );

  const handleDayOneFeedbackTab = useCallback(
    (tabs, surveysList, showSurveysTab) => {
      const feedbackTab = myTabsList.find((item) => item.key === 'feedback');
      if (showSurveysTab && surveysList.length > 0 && feedbackTab && feedbackTab.is_active) {
        if (tabs.includes(feedbackTab)) {
          return tabs;
        } else {
          tabs.push(feedbackTab);
          return tabs;
        }
      } else {
        return tabs.filter(
          (item) => item.key !== NEW_JOINEES_LOWER_LEVELS_IDENTIFIERS.DAY_ONE_FEEDBACK
        );
      }
    },
    [myTabsList]
  );
  useEffect(() => {
    const handleTabs = async () => {
      if (myTabsList?.length > 0) {
        if (
          newJoineesData?.show_induction === true &&
          newJoineesData?.show_all_inductions === true
        ) {
          const tabs = await myTabsList.filter((item) => item?.is_active);
          let list = await handleDayOneFeedbackTab(tabs, surveys, showSurveys);
          setNewJoineesLowerLevelsTabTitles(list);
        } else if (
          newJoineesData?.show_induction === false &&
          newJoineesData?.show_all_inductions
        ) {
          const tabs = await myTabsList.filter((item) => item?.lower_level);
          let listIfInductionIsEmpty = await handleDayOneFeedbackTab(tabs, surveys, showSurveys);
          setNewJoineesLowerLevelsTabTitles(listIfInductionIsEmpty);
        } else if (
          newJoineesData?.show_all_inductions === false &&
          newJoineesData?.show_induction
        ) {
          const tabs = await myTabsList.filter((item) => !item?.admin_view);
          let listIfNoAdminView = await handleDayOneFeedbackTab(tabs, surveys, showSurveys);
          setNewJoineesLowerLevelsTabTitles(listIfNoAdminView);
        } else if (
          newJoineesData?.show_all_inductions === false &&
          newJoineesData?.show_induction === false
        ) {
          const tabs = await myTabsList.filter((item) => item?.no_induction_admin_view);
          let listIfNoInductionOrAQdminView = await handleDayOneFeedbackTab(
            tabs,
            surveys,
            showSurveys
          );
          setNewJoineesLowerLevelsTabTitles(listIfNoInductionOrAQdminView);
        }
      }
    };
    handleTabs();
  }, [
    handleDayOneFeedbackTab,
    myTabsList,
    newJoineesData?.show_all_inductions,
    newJoineesData?.show_induction,
    surveys,
    showSurveys,
  ]);
  const renderScreen = useCallback(async () => {
    callNewJoineesApi();
  }, [callNewJoineesApi]);

  useEffect(() => {
    if (baseUrl !== '') {
      renderScreen();
    }
  }, [baseUrl, renderScreen]);

  useEffect(() => {
    if (
      !_.isEmpty(state) &&
      !_.isEmpty(state?.tabkey) &&
      state?.fromRoute === NAVIGATION_ROUTES.NOTIFICATION_LISTING &&
      newJoineesLowerLevelsTabTitles.length > 0 &&
      !_.isEmpty(newJoineesData)
    ) {
      setKey(state?.tabkey);
    }
  }, [state, newJoineesData, newJoineesLowerLevelsTabTitles]);

  const renderTab = (tab) => {
    switch (tab.key) {
      case NEW_JOINEES_LOWER_LEVELS_IDENTIFIERS.OVERVIEWS:
        return (
          <LowerLevelsOverviewTab
            data={newJoineesData[NEW_JOINEES_LOWER_LEVELS_IDENTIFIERS.OVERVIEWS]}
          />
        );
      case NEW_JOINEES_LOWER_LEVELS_IDENTIFIERS.CHECKLISTS:
        return (
          <LowerLevelsChecklist
            data={newJoineesData[NEW_JOINEES_LOWER_LEVELS_IDENTIFIERS.CHECKLISTS]}
            error={error}
            setError={setError}
            selectedChecklistItemCount={selectedChecklistItemCount}
            setSelectedChecklistItemCount={setSelectedChecklistItemCount}
            totalChecklistItemCountRef={totalChecklistItemCountRef}
          />
        );
      case NEW_JOINEES_LOWER_LEVELS_IDENTIFIERS.QUICKLINK:
        return (
          <LowerLevelsQuickLinksTab
            data={newJoineesData[NEW_JOINEES_LOWER_LEVELS_IDENTIFIERS.QUICKLINK]}
          />
        );
      case NEW_JOINEES_LOWER_LEVELS_IDENTIFIERS.MY_INDUCTION:
        return (
          <HigherLevelsSessionTab
            tab={tab}
            data={newJoineesData[NEW_JOINEES_LOWER_LEVELS_IDENTIFIERS.MY_INDUCTION]}
            getTabData={renderScreen}
            isLoading={isLoading}
            tabKey={NEW_JOINEES_LOWER_LEVELS_IDENTIFIERS.MY_INDUCTION}
            tabTitle={tab.title}
            callNewJoineesApi={callNewJoineesApi}
          />
        );
      case NEW_JOINEES_LOWER_LEVELS_IDENTIFIERS.DAY_ONE_FEEDBACK:
        return <FeedbackTab surveys={surveys} />;
      case NEW_JOINEES_LOWER_LEVELS_IDENTIFIERS.ALL_INDUCTION:
        return (
          <AllInductionTab
            tab={tab}
            data={newJoineeAllInductionData}
            isLoading={isLoading}
            tabKey={NEW_JOINEES_LOWER_LEVELS_IDENTIFIERS.ALL_INDUCTION}
            tabTitle={tab.title}
            callNewJoineesApi={callNewJoineesApi}
            dataLength={dataLength}
            currentPageId={currentPageId}
          />
        );
    }
  };

  return (
    <div className="container">
      <div>
        <SectionHeader identifier={ACCESSIBILITY_IDENTIFIERS.NEW_JOINEES} />
        {newJoineesLowerLevelsTabTitles.length > 0 && !_.isEmpty(newJoineesData) && (
          <NavTabs
            activeKey={key}
            onSelect={(k) => {
              setKey(k);
              dispatch(saveNewJoineesTabData(k));
            }}>
            {newJoineesLowerLevelsTabTitles.map((tab, index) => {
              return (
                <Tab className="p-3" key={`${index}`} eventKey={tab.key} title={tab.title}>
                  {renderTab(tab, index)}
                </Tab>
              );
            })}
          </NavTabs>
        )}
      </div>
    </div>
  );
};

export default NewJoineesLowerLevelsScreen;
