import React, { useEffect, useState } from 'react';
import { Carousel } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  FEATURE_TYPE,
  NAVIGATION_ROUTES,
  SCREEN_DENSITY_QUALIFIERS,
} from '../../../common/constants/AppConstants';
import Divider from '../../../common/ui/divider/Divider';
import UserProfilePhoto from '../../../common/ui/user_profile_photo/UserProfilePhoto';
import HTMLView from '../../../common/ui/html_view/HTMLView';
import { selectAccessibilityData } from '../../../state/AccessibilityData';
import { getFeatureComponentUrl, getHeaderComponentUrl } from '../../../utils/accessibility';
import {
  formatDateDDMonthYYYY,
  formatTimeAMPM,
  getSecuredPdfUrl,
  getUrlWithSpecificRendition,
  isToday,
  removeHtmlContent,
} from '../../../utils/common';
import { logEvent } from '../../../utils/FirebaseAnalyticsUtils';
import { selectWebBaseUrl } from '../../../state/MasterData';

const TheWallPostListItem = (props) => {
  const { item, onPressLikeButton, onPressCommentButton, showLikeButton, showCommentButton } =
    props;
  const history = useHistory();
  const accessibilityData = useSelector(selectAccessibilityData);
  const [currentActiveSlideIndex, setCurrentActiveSlideIndex] = useState(0);
  const [textString, setTextString] = useState('');
  const webUrl = useSelector(selectWebBaseUrl);

  const toggleReadFlag = (e) => {
    e.stopPropagation();
    history.push({
      pathname: `${NAVIGATION_ROUTES.THE_WALL_POST_DETAIL}/${item.post_id}`,
      state: { showLikeButton: showLikeButton, showCommentButton: showCommentButton },
    });
  };

  const onPressCarouselHandler = (e) => {
    e.stopPropagation();
  };

  useEffect(() => {
    if (item) {
      setTextString(
        removeHtmlContent(item.description).length <= 256
          ? item.description
          : item.short_description + '...'
      );
    }
  }, [item]);

  const handleHtmlClicks = (e) => {
    const target = e.target.getAttribute('href');
    if (target && target.startsWith('/')) {
      e.stopPropagation();
      e.preventDefault();
      if (target.includes(NAVIGATION_ROUTES.EMPLOYEE_PROFILE_DETAIL)) {
        logEvent(
          ANALYTICS_EVENT_TYPES.USER_MENTION_CLICK,
          e.target.innerText,
          ANALYTICS_ITEM_NAMES.USER_MENTION
        );
        history.push(target);
      } else {
        logEvent(
          ANALYTICS_EVENT_TYPES.HASHTAG_CLICK,
          e.target.innerText,
          ANALYTICS_ITEM_NAMES.HASHTAG
        );
        history.replace(target);
      }
    }
  };

  const renderPdfView = () => {
    return item.encoded_url.map((pdfItem, index) => {
      return (
        <div onClick={(e) => e.stopPropagation()} key={index}>
          <a href={getSecuredPdfUrl(webUrl, pdfItem?.pdf_url)} target="_blank" rel="noreferrer">
            <div className="d-flex align-items-center mt-2">
              <img
                src={getFeatureComponentUrl(
                  accessibilityData,
                  ACCESSIBILITY_FEATURE_COMPONENTS.NEW_JOINEES_PDF_ATTACHMENT,
                  ACCESSIBILITY_IDENTIFIERS.NEW_JOINEES
                )}
                className="communities-pdf-icon"
              />
              <div className="communities-pdf-item-title ml-1">{pdfItem.label}</div>
            </div>
          </a>
        </div>
      );
    });
  };

  const renderNameImageView = () => {
    return (
      <div>
        <div className="communities-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center">
            <UserProfilePhoto
              imageBaseUrl={item.employee.profile_picture.base_url}
              imagePath={item.employee.profile_picture.image_path}
              imageClass="common-user-profile-photo-xs"
              employeeId={item.employee.employee_id}
            />
            <div className="d-flex justify-content-between align-items-center common-cursor-pointer">
              <p className="communities-post-employee-name ml-3 mb-0">
                {item.employee.first_name + ' ' + item.employee.last_name}
              </p>
              {!!item.employee.pronouns && (
                <p className="communities-post-employee-pronoun ml-1 mb-0">
                  {'(' + item.employee.pronouns + ')'}
                </p>
              )}
            </div>
          </div>
          <div className="communities-date-text">
            {isToday(item.published_on)
              ? formatTimeAMPM(item.published_on)
              : formatDateDDMonthYYYY(item.published_on)}
          </div>
        </div>
        {item.title && <div className="mt-2 communities-title">{item.title}</div>}
        {textString !== '' && (
          <div className="mt-1 communities-description text-break">
            <HTMLView html={textString} onClick={handleHtmlClicks} />
            {removeHtmlContent(item.description).length > 256 && (
              <div onClick={toggleReadFlag} className="communities-read-color">
                Read More
              </div>
            )}
          </div>
        )}
        {renderPdfView()}
      </div>
    );
  };

  const renderImages = () => {
    if (item.images.length > 0) {
      return (
        <Carousel
          onClick={onPressCarouselHandler}
          className="communities-post-carousel-container mt-2"
          nextIcon={
            item.images.length > 1 && (
              <span aria-hidden="true" className="carousel-control-next-icon" />
            )
          }
          prevIcon={
            item.images.length > 1 && (
              <span aria-hidden="true" className="carousel-control-prev-icon" />
            )
          }
          activeIndex={currentActiveSlideIndex}
          onSelect={(selectedIndex) => setCurrentActiveSlideIndex(selectedIndex)}>
          {item.images.map((imageItem, index) => {
            return (
              <Carousel.Item key={`${index}`}>
                <div className="d-flex justify-content-center">
                  <img
                    className="communities-post-carousel-image"
                    src={getUrlWithSpecificRendition(
                      imageItem.base_url,
                      SCREEN_DENSITY_QUALIFIERS.XXXHDPI,
                      imageItem.filename
                    )}
                  />
                </div>
              </Carousel.Item>
            );
          })}
        </Carousel>
      );
    }
  };

  const renderVideoLinks = () => {
    return (
      <div>
        {item.videos && item.videos.length > 0 && (
          <div className="communities-url mt-2">Video URL:</div>
        )}
        {item.videos && item.videos.length > 0 && (
          <a
            className="community-link-text"
            target="_blank"
            rel="noreferrer"
            href={item.videos[0].url}
            onClick={(e) => {
              e.stopPropagation();
            }}>
            {item.videos[0].url}
          </a>
        )}
        {item.videos && item.videos.length > 1 && (
          <div className="communities-more">+{`${item.videos.length - 1} more`}</div>
        )}
      </div>
    );
  };

  const renderLinks = () => {
    return (
      <div>
        {item.links && item.links.length > 0 && (
          <div className="communities-url mt-2">Website URL:</div>
        )}
        {item.links && item.links.length > 0 && (
          <div className="text-truncate">
            <a
              className="community-link-text"
              target="_blank"
              rel="noreferrer"
              href={item.links[0].url}
              onClick={(e) => e.stopPropagation()}>
              {item.links[0].url}
            </a>
          </div>
        )}
        {item.links && item.links.length > 1 && (
          <div className="communities-more">+{`${item.links.length - 1} more`}</div>
        )}
      </div>
    );
  };

  const renderLikeCommentView = () => {
    return (
      <div className="d-flex align-items-center mt-1">
        {showLikeButton && (
          <>
            {item.liked ? (
              <div onClick={() => onPressLikeButton(item)}>
                <img
                  src={getHeaderComponentUrl(
                    accessibilityData,
                    ACCESSIBILITY_FEATURE_COMPONENTS.COMMUNITIES_ACTIVE_LIKE,
                    ACCESSIBILITY_IDENTIFIERS.COMMUNITIES
                  )}
                  className="communities-normal-icon"
                />
              </div>
            ) : (
              <div onClick={() => onPressLikeButton(item)}>
                <img
                  src={getHeaderComponentUrl(
                    accessibilityData,
                    ACCESSIBILITY_FEATURE_COMPONENTS.COMMUNITIES_INACTIVE_LIKE,
                    ACCESSIBILITY_IDENTIFIERS.COMMUNITIES
                  )}
                  className="communities-normal-icon common-cursor-pointer"
                />
              </div>
            )}
            {item.like_count > 0 ? (
              <div
                className="ml-2 communities-count common-cursor-pointer"
                onClick={() => {
                  history.push(NAVIGATION_ROUTES.LIKED_PEOPLE, {
                    referId: item.post_id,
                    parentType: FEATURE_TYPE.WALL,
                  });
                }}>
                {item.like_count}
              </div>
            ) : (
              <div className="ml-2 communities-count common-cursor-pointer"> 0 </div>
            )}
          </>
        )}
        {showCommentButton && (
          <>
            <div
              className={
                showLikeButton
                  ? 'communities-flex ml-3 mr-3 communities-width common-cursor-pointer'
                  : 'communities-flex mr-3 communities-width common-cursor-pointer'
              }
              onClick={() => onPressCommentButton(item)}>
              <img
                src={getFeatureComponentUrl(
                  accessibilityData,
                  ACCESSIBILITY_FEATURE_COMPONENTS.COMMUNITIES_POST_COMMENT,
                  ACCESSIBILITY_IDENTIFIERS.COMMUNITIES
                )}
                className="communities-normal-icon mt-1"
              />
              <div className="ml-2 mt-1 communities-count">
                {item.comment_count > 0 ? item.comment_count : '0'}
              </div>
            </div>
          </>
        )}
      </div>
    );
  };

  const renderTaggedView = () => {
    return (
      <div>
        {item.tags.length > 0 && (
          <div
            className="d-flex align-items-center common-cursor-pointer"
            onClick={() => {
              logEvent(
                ANALYTICS_EVENT_TYPES.THE_WALL_VIEW_POST_TAGGED_PEOPLE_CLICK,
                item.post_id.toString(),
                ANALYTICS_ITEM_NAMES.THE_WALL
              );
              history.push(NAVIGATION_ROUTES.THE_WALL_TAGGED_PEOPLE, {
                employees: item.tags,
              });
            }}>
            <div className="d-flex align-items-center">
              {item.tags.length >= 3 ? (
                <div className="d-flex align-items-center">
                  <div className="communities-image-first position-relative ">
                    {item.tags.length >= 1 && (
                      <UserProfilePhoto
                        imageBaseUrl={item.tags[0].profile_picture.base_url}
                        imagePath={item.tags[0].profile_picture.image_path}
                        imageClass="common-user-profile-photo-xs"
                        employeeId={item.tags[0].employee_id}
                      />
                    )}
                  </div>
                  <div className="communities-image-second position-relative ">
                    {item.tags.length >= 2 && (
                      <UserProfilePhoto
                        imageBaseUrl={item.tags[1].profile_picture.base_url}
                        imagePath={item.tags[1].profile_picture.image_path}
                        imageClass="common-user-profile-photo-xs"
                        employeeId={item.tags[1].employee_id}
                      />
                    )}
                  </div>
                  {item.tags.length >= 3 && (
                    <UserProfilePhoto
                      imageBaseUrl={item.tags[2].profile_picture.base_url}
                      imagePath={item.tags[2].profile_picture.image_path}
                      imageClass="common-user-profile-photo-xs"
                      employeeId={item.tags[2].employee_id}
                    />
                  )}
                </div>
              ) : item.tags.length >= 2 ? (
                <div className="d-flex align-items-center">
                  <div className="communities-image-first position-relative ">
                    {item.tags.length >= 1 && (
                      <UserProfilePhoto
                        imageBaseUrl={item.tags[0].profile_picture.base_url}
                        imagePath={item.tags[0].profile_picture.image_path}
                        imageClass="common-user-profile-photo-xs"
                        employeeId={item.tags[0].employee_id}
                      />
                    )}
                  </div>
                  <div>
                    {item.tags.length >= 2 && (
                      <UserProfilePhoto
                        imageBaseUrl={item.tags[1].profile_picture.base_url}
                        imagePath={item.tags[1].profile_picture.image_path}
                        imageClass="common-user-profile-photo-xs"
                        employeeId={item.tags[1].employee_id}
                      />
                    )}
                  </div>
                </div>
              ) : (
                <div>
                  {item.tags.length >= 1 && (
                    <UserProfilePhoto
                      imageBaseUrl={item.tags[0].profile_picture.base_url}
                      imagePath={item.tags[0].profile_picture.image_path}
                      imageClass="common-user-profile-photo-xs"
                      employeeId={item.tags[0].employee_id}
                    />
                  )}
                </div>
              )}
            </div>
            {item.tags.length > 3 && (
              <div className="communities-tag-count-text ml-2">{`+${item.tags.length - 3}`}</div>
            )}
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="mb-3 border p-3">
      <div
        onClick={(e) => {
          e.stopPropagation();
          history.push({
            pathname: `${NAVIGATION_ROUTES.THE_WALL_POST_DETAIL}/${item.post_id}`,
            state: { showLikeButton: showLikeButton, showCommentButton: showCommentButton },
          });
        }}>
        {renderNameImageView()}
        {renderImages()}
        {renderVideoLinks()}
        {renderLinks()}
      </div>
      <Divider style="mt-2" />
      <div className="mt-2 communities-flex justify-content-between">
        {renderLikeCommentView()}
        {renderTaggedView()}
      </div>
    </div>
  );
};

export default TheWallPostListItem;
