import { Modal } from '@mui/material';
import React from 'react';
import HtmlView from '../html_view/HTMLView';

const CustomModal = ({
  visible,
  onClose = () => {},
  title,
  description,
  onClickSecondaryButton,
  onClickPrimaryButton,
  secondaryButtonTitle,
  primaryButtonTitle,
  onClick = () => {},
}) => {
  return (
    <Modal onClose={onClose} open={visible} className="common-custom-modal-body">
      <div className="common-custom-modal-container" tabIndex="0">
        <div className="common-custom-modal-title">{title}</div>
        <div className="common-custom-modal-description">
          {/* {description.split('\n').map((text, index) => (
            <div key={index}>{text}</div>
          ))} */}
          <HtmlView onClick={onClick} html={description} htmlStyle="estore-regular-text" />
        </div>
        <div className="common-custom-modal-buttons-block">
          {secondaryButtonTitle && (
            <div className="common-custom-modal-secondary-button" onClick={onClickSecondaryButton}>
              <div className="common-custom-modal-secondary-button-title">
                {secondaryButtonTitle}
              </div>
            </div>
          )}
          <div className="common-custom-modal-primary-button" onClick={onClickPrimaryButton}>
            <div className="common-custom-modal-primary-button-title">{primaryButtonTitle}</div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CustomModal;
