import React, { useEffect, useRef, useState } from 'react';
import { Carousel, Image } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  ANALYTICS_SCREEN_NAMES,
  NAVIGATION_ROUTES,
} from '../../common/constants/AppConstants';
import BackButton from '../../common/ui/common_back_button/BackButton';
import SectionHeader from '../../common/ui/section_header/SectionHeader';
import { selectEmployeeData } from '../../state/EmployeeData';
import { logEvent2, trackScreen } from '../../utils/FirebaseAnalyticsUtils';
import { LinkedinIcon, FacebookIcon, EmailIcon, TwitterIcon } from 'react-share';
import { getFeatureComponentUrl } from '../../utils/accessibility';
import { selectAccessibilityData } from '../../state/AccessibilityData';
import { shareImage } from '../../utils/common';
import { saveAs } from 'file-saver';
import ShareEmailModal from '../../common/components/shareEmailModal';

const WallpaperContentScreen = ({ location }) => {
  const { cardIndex, cards } = location.state;
  const [index, setIndex] = useState(cardIndex);
  const employeeData = useSelector(selectEmployeeData);
  const accessibilityData = useSelector(selectAccessibilityData);
  const [isPortraitMode, setIsPortraitMode] = useState(true);
  const shareEmailImageUrl = useRef('');
  const [showEmailModal, setShowEmailModal] = useState(false);

  useEffect(() => {
    trackScreen(
      ANALYTICS_SCREEN_NAMES.WALLPAPER_CONTENT_SCREEN,
      NAVIGATION_ROUTES.WALLPAPER_CONTENT_SCREEN
    );
  }, []);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  const shareImageUrlOverEmail = (imageUrl) => {
    shareEmailImageUrl.current = imageUrl;
    setShowEmailModal(true);
  };

  const shareWallpaper = (platform, url) => {
    logEvent2({
      eventType: ANALYTICS_EVENT_TYPES.WALLPAPERS_SHARE,
      itemId: '',
      itemName: '',
      categoryId: ANALYTICS_ITEM_NAMES.ANNIVERSARY,
      categoryName: ANALYTICS_ITEM_NAMES.ANNIVERSARY,
    });
    shareImage(platform, url);
  };

  const downloadImage = async (item) => {
    logEvent2({
      eventType: ANALYTICS_EVENT_TYPES.WALLPAPERS_DOWNLOAD,
      itemId: '',
      itemName: '',
      categoryId: ANALYTICS_ITEM_NAMES.ANNIVERSARY,
      categoryName: ANALYTICS_ITEM_NAMES.ANNIVERSARY,
    });
    var leafname = item.split('\\').pop().split('/').pop();

    saveAs(item, leafname);
  };

  return (
    <div className="container">
      <SectionHeader identifier={ACCESSIBILITY_IDENTIFIERS.ANNIVERSARY_DAY} />
      <BackButton />
      <div className="anniversary-gallery-full-container">
        <Carousel
          nextIcon={
            cards.length > 1 && <span aria-hidden="true" className="carousel-control-next-icon" />
          }
          prevIcon={
            cards.length > 1 && <span aria-hidden="true" className="carousel-control-prev-icon" />
          }
          activeIndex={index}
          onSelect={handleSelect}>
          {cards.map((item, index) => {
            return (
              <Carousel.Item key={`${index}`}>
                <div className="anniversary-gallery-full-image">
                  <div className="anniversary-wallpaper-view-content">
                    <img src={item} className="anniversary-wallpaper-full-image" />
                    <div className="anniversary-bg-view">
                      <div>
                        <div className="anniversary-name-text text-center">{`${employeeData.first_name} ${employeeData.last_name}`}</div>
                        <div className="anniversary-name-text text-center mt-1">
                          {`+${employeeData.mobile_number.isd_code} ${employeeData.mobile_number.number}`}
                        </div>
                        <div className="anniversary-name-text text-center mt-1">
                          {employeeData.email}
                        </div>
                      </div>
                    </div>
                    <div
                      className={`${
                        isPortraitMode === true
                          ? 'ecards-social-icons mb-5'
                          : 'ecards-social-icons-landscape mr-1 mb-4 align-items-center w-100 d-flex justify-content-end flex-row position-absolute'
                      }`}>
                      <LinkedinIcon
                        size={32}
                        round={true}
                        onClick={(e) => {
                          e.stopPropagation();
                          shareWallpaper('Linkedin', item);
                        }}
                        className={`${isPortraitMode === true ? 'mb-1' : 'mr-1'}`}
                      />
                      <FacebookIcon
                        size={32}
                        round={true}
                        onClick={(e) => {
                          e.stopPropagation();
                          shareWallpaper('Facebook', item);
                        }}
                        className={`${isPortraitMode === true ? 'mb-1' : 'mr-1'}`}
                      />

                      <TwitterIcon
                        size={32}
                        round={true}
                        onClick={(e) => {
                          e.stopPropagation();
                          shareWallpaper('Twitter', item);
                        }}
                        className={`${isPortraitMode === true ? 'mb-1' : 'mr-1'}`}
                      />
                      <EmailIcon
                        size={32}
                        round={true}
                        onClick={(e) => {
                          e.stopPropagation();
                          shareImageUrlOverEmail(item);
                        }}
                        className={`${isPortraitMode === true ? 'mb-1' : 'mr-1'}`}
                      />
                      <div
                        onClick={(e) => {
                          e.stopPropagation();
                          downloadImage(item);
                        }}>
                        <img
                          src={getFeatureComponentUrl(
                            accessibilityData,
                            ACCESSIBILITY_FEATURE_COMPONENTS.ECARDS_DOWNLOAD_CARD,
                            ACCESSIBILITY_IDENTIFIERS.ECARDS
                          )}
                          className="ecards-download-icon"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </Carousel.Item>
            );
          })}
        </Carousel>
      </div>
      <ShareEmailModal
        showModal={showEmailModal}
        imageUrl={shareEmailImageUrl.current}
        hideModal={() => setShowEmailModal(false)}
        logEvent={() => {
          logEvent2({
            eventType: ANALYTICS_EVENT_TYPES.WALLPAPERS_SHARE,
            itemId: '',
            itemName: '',
            categoryId: ANALYTICS_ITEM_NAMES.ANNIVERSARY,
            categoryName: ANALYTICS_ITEM_NAMES.ANNIVERSARY,
          });
        }}
      />
    </div>
  );
};

export default WallpaperContentScreen;
