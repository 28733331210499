import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  LOADING_MESSAGES,
  REQUEST_TYPE,
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ACCESSIBILITY_HEADER_COMPONENTS,
  NAVIGATION_ROUTES,
  ANALYTICS_SCREEN_NAMES,
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  SCREEN_DENSITY_QUALIFIERS,
} from '../../common/constants/AppConstants';
import { getFeatureComponentUrl, getHeaderComponentUrl } from '../../utils/accessibility';
import { selectAccessibilityData } from '../../state/AccessibilityData';
import { apiRequest, handleError } from '../../services/Service';
import { COMMENT_BY_ID, CUSTOMERS_BY_ID } from '../../services/ApiUrls';
import { saveProgressLoadingState, saveSearchText } from '../../state/UIState';
import {
  formatDateDDMonthYYYY,
  getDeviceScreenDensityQualifierAsPath,
  getUrlWithSpecificRendition,
} from '../../utils/common';
import HtmlView from '../../common/ui/html_view/HTMLView';
import UserProfilePhoto from '../../common/ui/user_profile_photo/UserProfilePhoto';
import { logEvent, trackScreen } from '../../utils/FirebaseAnalyticsUtils';
import { useHistory } from 'react-router-dom';

const AllKnowTab = ({ apiType, customerId, dataType, likeType, type }) => {
  const history = useHistory();
  const [customer, setCustomer] = useState();
  const dispatch = useDispatch();
  const [liked, setLiked] = useState();
  const [likeCount, setLikeCount] = useState();
  const [commentCount, setCommentCount] = useState();
  const accessibilityData = useSelector(selectAccessibilityData);

  useEffect(() => {
    trackScreen(
      ANALYTICS_SCREEN_NAMES.OUR_CUSTOMER_CONTENT,
      NAVIGATION_ROUTES.OUR_CUSTOMER_CONTENT
    );
  }, []);

  const getDefaultProfile = () => {
    const defaultProfileUrl = getFeatureComponentUrl(
      accessibilityData,
      ACCESSIBILITY_FEATURE_COMPONENTS.COMMON_DEFAULT_PROFILE,
      ACCESSIBILITY_IDENTIFIERS.COMMON
    );
    return defaultProfileUrl;
  };

  const callCustomerData = useCallback(async () => {
    dispatch(
      saveProgressLoadingState({
        isProgressLoading: true,
      })
    );
    try {
      const apiResponse = await apiRequest(
        CUSTOMERS_BY_ID + apiType + '/' + customerId,
        REQUEST_TYPE.GET,
        {}
      );
      if (Object.keys(apiResponse).length > 0) {
        if (apiResponse.response.status === true) {
          if (apiResponse.response.data[dataType]) {
            const data = apiResponse.response.data[dataType];
            setCustomer(data);
            setLiked(data.liked);
            setLikeCount(data.like_count);
            setCommentCount(data.comment_count);
          }
        }
      }
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: false,
        })
      );
    } catch (err) {
      handleError(
        err,
        {},
        CUSTOMERS_BY_ID + apiType + '/' + customerId,
        NAVIGATION_ROUTES.OUR_CUSTOMER
      );
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: false,
        })
      );
    }
  }, [apiType, customerId, dataType, dispatch]);

  useEffect(() => {
    callCustomerData();
  }, [callCustomerData]);

  const postLike = async () => {
    logEvent(ANALYTICS_EVENT_TYPES.OUR_CUSTOMERS_LIKE, customer.id, ANALYTICS_ITEM_NAMES.LIKE);
    dispatch(
      saveProgressLoadingState({
        isProgressLoading: true,
        progressMessage: LOADING_MESSAGES.LIKE_UPDATE,
      })
    );
    try {
      const formData = new URLSearchParams();
      formData.append('type', likeType);
      const apiResponse = await apiRequest(
        COMMENT_BY_ID + customer.id + '/like',
        REQUEST_TYPE.POST,
        formData
      );
      if (Object.keys(apiResponse).length > 0) {
        if (apiResponse.response.status === true) {
          if (apiResponse.response.data) {
            const data = apiResponse.response.data;
            setLikeCount(data.like_count);
            setLiked(data.liked);
          }
        }
      }
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: false,
        })
      );
    } catch (err) {
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: false,
        })
      );
    }
  };

  const onPressMore = (pictures, baseUrl) => {
    history.push(NAVIGATION_ROUTES.GALLERY_COMMON, {
      baseUrl: baseUrl,
      pictures: pictures.pictures,
    });
  };

  const handleHtmlClicks = (e) => {
    const target = e.target.getAttribute('href');
    if (target) {
      if (target.startsWith('/') || target.startsWith('?')) {
        if (target.includes(NAVIGATION_ROUTES.EMPLOYEE_PROFILE_DETAIL)) {
          logEvent(
            ANALYTICS_EVENT_TYPES.USER_MENTION_CLICK,
            e?.target?.innerText,
            ANALYTICS_ITEM_NAMES.USER_MENTION
          );
          e.stopPropagation();
          e.preventDefault();
          history.push(target);
        } else {
          logEvent(
            ANALYTICS_EVENT_TYPES.HASHTAG_CLICK,
            e?.target?.innerText,
            ANALYTICS_ITEM_NAMES.HASHTAG
          );
          dispatch(saveSearchText(''));
          e.preventDefault();
          history.push(NAVIGATION_ROUTES.OUR_CUSTOMER_KNOW_SEARCH_SCREEN);
          history.push(target, { type: type });
        }
      } else {
        e.stopPropagation();
        e.preventDefault();
        window.open(target, '_blank');
      }
    }
  };

  const renderHeader = () => {
    return (
      <div className="broadcast-header-row justify-content-end">
        <div className="broadcast-icon-row mr-3" onClick={postLike}>
          <img
            src={getHeaderComponentUrl(
              accessibilityData,
              liked
                ? ACCESSIBILITY_HEADER_COMPONENTS.ACTIVE_LIKE
                : ACCESSIBILITY_HEADER_COMPONENTS.INACTIVE_LIKE
            )}
            className="header-icon"
          />
        </div>
        <div
          className="mr-3 ourcustomer-like-comment common-cursor-pointer"
          onClick={() => {
            history.push(NAVIGATION_ROUTES.LIKED_PEOPLE, {
              referId: customer.id,
              parentType: likeType,
            });
          }}>
          {likeCount ? likeCount : '0'}
        </div>
        <div
          className="broadcast-icon-row mr-3"
          onClick={() => {
            history.push(NAVIGATION_ROUTES.COMMENTS, {
              referId: customer.id,
              likeType: likeType,
              fromRoute: NAVIGATION_ROUTES.OUR_CUSTOMER_CONTENT,
            });
          }}>
          <img
            src={getHeaderComponentUrl(accessibilityData, ACCESSIBILITY_HEADER_COMPONENTS.COMMENT)}
            className="header-icon"
          />
        </div>
        <div
          className="mr-3 ourcustomer-like-comment common-cursor-pointer"
          onClick={() => {
            logEvent(
              ANALYTICS_EVENT_TYPES.OUR_CUSTOMER_COMMENT,
              customer.id,
              ANALYTICS_ITEM_NAMES.COMMENT
            );
            history.push(NAVIGATION_ROUTES.COMMENTS, {
              referId: customer.id,
              likeType: likeType,
              fromRoute: NAVIGATION_ROUTES.OUR_CUSTOMER_CONTENT,
            });
          }}>
          {commentCount ? commentCount : '0'}
        </div>
      </div>
    );
  };

  const renderOurCustomer = () => {
    return (
      <div>
        <div className="ourcustomers-main">
          <img
            src={getUrlWithSpecificRendition(
              customer.base_url,
              SCREEN_DENSITY_QUALIFIERS.XXXHDPI,
              customer.promo_image
            )}
            className="embed-responsive embed-responsive-16by9"
          />
          {customer.gallary && (
            <div
              onClick={() => onPressMore(customer.gallary, customer.base_url)}
              className="ourcustomers-bg-view-detail">
              <div className="ourcustomers-count-left-text">{`+ ${customer.gallary.pictures.length} photos`}</div>
            </div>
          )}
        </div>
        <div className="pt-1 pb-1 pl-3 pr-3">
          {customer.date && (
            <div>
              <div className="ourcustomers-flex mt-1">
                <img
                  src={getFeatureComponentUrl(
                    accessibilityData,
                    ACCESSIBILITY_FEATURE_COMPONENTS.MICROGIVE_CALENDER,
                    ACCESSIBILITY_IDENTIFIERS.MICROGIVE
                  )}
                  className="ourcustomers-calender-style"
                />
                <div className="ourcustomers-event-date">
                  {formatDateDDMonthYYYY(customer.date)}
                </div>
              </div>
              {customer.title && (
                <div className="ourcustomers-title-text mt-1">{customer.title}</div>
              )}
            </div>
          )}
          {customer.name && <div className="ourcustomers-title-text mt-1">{customer.name}</div>}
          {customer.location && <div className="ourcustomer-location-text">{customer.region}</div>}
          <HtmlView html={customer.description} onClick={handleHtmlClicks} htmlStyle="mt-1" />
          {customer?.deal_year && (
            <div className="our-customer-deal-won-year-text">
              Customer since: {customer?.deal_year}
            </div>
          )}
          {customer.account_owner && (
            <div className="ourcustomers-item row ml-1 mr-1">
              <UserProfilePhoto
                imageBaseUrl={customer.account_owner.profile_picture.base_url}
                imagePath={customer.account_owner.profile_picture.image_path}
                employeeId={customer.account_owner.employee_id}
                imageClass="common-user-profile-photo-md"
              />
              <div className="ml-3 ourcustomers-cross-axis-view">
                <div>
                  <div className="mb-1 ourcustomers-account-ownername">
                    {customer.account_owner.first_name +
                      ' ' +
                      customer.account_owner.middle_name +
                      ' ' +
                      customer.account_owner.last_name}
                  </div>
                  <div className="mt-1 ourcustomer-owner-text">Account Owner</div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <div>
      <div className="main-content-container-padding">
        <div>{type !== 'accolades' && customer && renderOurCustomer()}</div>
      </div>
    </div>
  );
};

export default AllKnowTab;
