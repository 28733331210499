import React, { useEffect, useState, useCallback, useRef, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  LOADING_MESSAGES,
  REQUEST_TYPE,
  FEATURE_TYPE,
  NAVIGATION_ROUTES,
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_SCREEN_NAMES,
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  ACCESSIBILITY_HEADER_COMPONENTS,
  ERROR_MESSAGES,
  ACCESSIBILITY_FEATURE_COMPONENTS,
  SEARCH_DEBOUNCE_WAIT_TIME,
} from '../../common/constants/AppConstants';
import { showToast } from '../../utils/common';
import { apiRequest, handleError } from '../../services/Service';
import { COMMENT_BY_ID, GET_WALL } from '../../services/ApiUrls';
import {
  saveProgressLoadingState,
  saveWallSearchText,
  selectBaseUrl,
  selectProgressLoadingState,
  selectWallSearchText,
} from '../../state/UIState';
import { logEvent, trackScreen } from '../../utils/FirebaseAnalyticsUtils';
import LoadMoreBtn from '../../common/ui/load_more_btn/LoadMoreBtn';
import CustomButton from '../../common/ui/custom_button/CustomButton';
import SectionHeader from '../../common/ui/section_header/SectionHeader';
import TheWallPostListItem from './components/TheWallPostListItem';
import TheWallNewPostDialog from './components/TheWallNewPostDialog';
import { getFeatureComponentUrl, getHeaderComponentUrl } from '../../utils/accessibility';
import { selectAccessibilityData } from '../../state/AccessibilityData';
import DefaultContentView from '../../common/ui/default_content_view/DefaultContentView';
import BackButton from '../../common/ui/common_back_button/BackButton';
import queryString from 'query-string';
import debounce from 'lodash.debounce';
import { selectAccessData } from '../../state/UserAccessData';
import ActivityLoader from '../../common/ui/activity_loader/ActivityLoader';

const TheWallMainScreen = ({ history, location }) => {
  const dispatch = useDispatch();
  const accessibilityData = useSelector(selectAccessibilityData);
  const progressLoadingState = useSelector(selectProgressLoadingState);
  const searchData = useSelector(selectWallSearchText);
  const baseUrl = useSelector(selectBaseUrl);
  const pageIdRef = useRef(1);
  const loadMoreFlagRef = useRef(true);
  const textInputRef = useRef();
  const inputSearchRef = useRef();
  const searchTextRef = useRef(location?.hash ?? '');
  const [isSearchEnabled, setIsSearchEnabled] = useState(
    location?.hash ? true : searchData ? true : false
  );
  const [wallsData, setWallsData] = useState([]);
  const [dataLength, setDataLength] = useState(0);
  const [isTheWallNewPostDialogVisible, setIsTheWallNewPostDialogVisible] = useState(false);
  const query = useMemo(
    () => queryString.parse(location.search, { parseBooleans: true }),
    [location.search]
  );
  const [searchString, setSearchString] = useState('');
  const [showEmptyView, setShowEmptyView] = useState(false);
  const accessData = useSelector(selectAccessData);
  const [showLikeButton, setShowLikeButton] = useState(true);
  const [showCommentButton, setShowCommentButton] = useState(true);
  const [showCreatePostButton, setShowCreatePostButton] = useState(true);
  const wallAccessData = accessData?.data?.find((item) => item.feature_key === '_theWall');

  useEffect(() => {
    if (wallAccessData) {
      const actions = wallAccessData?.actions;
      setShowLikeButton(actions?.includes('_like'));
      setShowCommentButton(actions?.includes('_comment'));
      setShowCreatePostButton(actions?.includes('_post'));
    }
  }, [wallAccessData]);
  const [quickSearchLoading, setQuickSearchLoading] = useState(false);

  useEffect(() => {
    if (pageIdRef.current !== 1) {
      loadMoreFlagRef.current = false;
    }
  }, [dataLength]);

  useEffect(() => {
    if (inputSearchRef.current && location?.hash) {
      inputSearchRef.current.value = location?.hash;
      logEvent(ANALYTICS_EVENT_TYPES.HASHTAG_QUERY, location?.hash, ANALYTICS_ITEM_NAMES.HASHTAG);
    }
  }, [inputSearchRef.current, location]);

  useEffect(() => {
    setSearchString(query.search ?? '');
    pageIdRef.current = 1;
    loadMoreFlagRef.current = true;
  }, [query]);

  useEffect(() => {
    if (inputSearchRef.current) {
      setSearchString(searchData ?? '');
      pageIdRef.current = 1;
      inputSearchRef.current.value = searchData;
      loadMoreFlagRef.current = true;
    }
  }, [searchData]);

  const callWallsApi = useCallback(
    async (text = '', isQuickSearch = false) => {
      if (isQuickSearch) {
        setQuickSearchLoading(true);
      } else {
        dispatch(
          saveProgressLoadingState({
            isProgressLoading: true,
          })
        );
      }
      let params = { page_id: pageIdRef.current };
      if (text) {
        params = { ...params, ...{ query: text } };
      } else if (searchTextRef.current) {
        params = { ...params, ...{ query: searchTextRef.current } };
      } else if (query.search) {
        params = { ...params, ...{ query: query.search } };
      } else if (searchData) {
        params = { ...params, ...{ query: searchData } };
      }
      try {
        setShowEmptyView(false);
        const response = await apiRequest(GET_WALL, REQUEST_TYPE.GET, params);
        if (Object.keys(response).length > 0) {
          if (response.response.status === true) {
            if (response.response.data.wallposts) {
              const data = response.response.data.wallposts;
              if (data?.length === 0) {
                setShowEmptyView(true);
              }
              if (data.length < 10) {
                loadMoreFlagRef.current = false;
              }
              setDataLength(data.length);
              if (pageIdRef.current === 1) {
                setWallsData(data);
              } else {
                setWallsData((prev) => [...prev, ...data]);
              }
            }
          }
        } else {
          setShowEmptyView(true);
        }
        setQuickSearchLoading(false);
        dispatch(
          saveProgressLoadingState({
            isProgressLoading: false,
          })
        );
      } catch (err) {
        handleError(err);
        setShowEmptyView(true);
        setQuickSearchLoading(false);
        dispatch(
          saveProgressLoadingState({
            isProgressLoading: false,
          })
        );
      }
    },
    [dispatch, query.search, searchData]
  );

  const debouncedCallback = (event) => {
    searchTextRef.current = event.target.value;
    if (event && event.target.value.length > 2) {
      pageIdRef.current = 1;
      loadMoreFlagRef.current = true;
      dispatch(saveWallSearchText(event.target.value));
      setSearchString(event.target.value);
      callWallsApi(event.target.value, true);
    }
    if (event && event.target.value === '') {
      pageIdRef.current = 1;
      loadMoreFlagRef.current = true;
      setDataLength(0);
      callWallsApi('');
    }
  };

  const debounceInputHandler = useCallback(
    debounce(debouncedCallback, SEARCH_DEBOUNCE_WAIT_TIME),
    []
  );

  const renderScreen = useCallback(async () => {
    callWallsApi('');
  }, [callWallsApi]);

  useEffect(() => {
    if (baseUrl) {
      renderScreen();
    }
  }, [baseUrl, renderScreen]);

  useEffect(() => {
    if (location && location.state && location.state.refresh) {
      renderScreen();
    }
  }, [location, renderScreen]);

  const callLikeApi = async (referId) => {
    dispatch(
      saveProgressLoadingState({
        isProgressLoading: true,
        progressMessage: LOADING_MESSAGES.LIKE_UPDATE,
      })
    );
    const params = new URLSearchParams();
    params.append('type', FEATURE_TYPE.WALL);
    try {
      const apiResponse = await apiRequest(
        COMMENT_BY_ID + referId + '/like',
        REQUEST_TYPE.POST,
        params
      );
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: false,
        })
      );
      if (Object.keys(apiResponse).length > 0) {
        if (apiResponse.response.status === true) {
          if (apiResponse.response.data) {
            const data = apiResponse.response.data;
            const message = apiResponse.response.message;
            if (message) {
              showToast(message);
              let element = wallsData.find((item) => item.post_id === referId);
              element.liked = data.liked;
              element.like_count = data.like_count;
              setWallsData(wallsData.map((item) => (referId === item.post_id ? element : item)));
            }
          }
        }
      }
    } catch (err) {
      handleError(err, {}, COMMENT_BY_ID + referId + '/like', NAVIGATION_ROUTES.THE_WALL);
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: false,
        })
      );
    }
  };

  const onPressLikeButton = (item) => {
    logEvent(
      ANALYTICS_EVENT_TYPES.THE_WALL_LIKE,
      item.post_id.toString(),
      ANALYTICS_ITEM_NAMES.LIKE
    );
    callLikeApi(item.post_id);
  };

  const onPressCommentButton = (item) => {
    history.push(NAVIGATION_ROUTES.COMMENTS, {
      referId: item.post_id,
      likeType: FEATURE_TYPE.WALL,
      fromRoute: NAVIGATION_ROUTES.THE_WALL,
    });
  };

  const renderWallPost = (item, index) => {
    return (
      <TheWallPostListItem
        key={index.toString()}
        item={item}
        onPressLikeButton={onPressLikeButton}
        onPressCommentButton={onPressCommentButton}
        showLikeButton={showLikeButton}
        showCommentButton={showCommentButton}
      />
    );
  };
  const loadMoreHandler = () => {
    if (loadMoreFlagRef.current) {
      pageIdRef.current = pageIdRef.current + 1;
      callWallsApi(searchString);
    }
  };

  const onSearchSubmitHandler = (event) => {
    event.preventDefault();
    if (searchTextRef.current.length < 3) {
      logEvent(ANALYTICS_EVENT_TYPES.DIRECTORY_SEARCH_QUERY, '', searchTextRef.current);
      pageIdRef.current = 1;
      loadMoreFlagRef.current = true;
      setDataLength(0);
      callWallsApi(searchString);
    }
  };

  const resetSearchHandler = useCallback(() => {
    if (location.hash) {
      history.replace(NAVIGATION_ROUTES.THE_WALL);
      return;
    }
    dispatch(saveWallSearchText(''));
    textInputRef.current.reset();
    searchTextRef.current = '';
    pageIdRef.current = 1;
    loadMoreFlagRef.current = true;
    setDataLength(0);
    callWallsApi('');
    setIsSearchEnabled(false);
  }, [callWallsApi]);

  useEffect(() => {
    trackScreen(ANALYTICS_SCREEN_NAMES.THE_WALL, NAVIGATION_ROUTES.THE_WALL);
  }, []);

  const onClickSearchHandler = () => {
    logEvent(ANALYTICS_EVENT_TYPES.THE_WALL_SEARCH_CLICKED, '', ANALYTICS_ITEM_NAMES.THE_WALL);
    setIsSearchEnabled(true);
  };

  const onClickButton = () => {
    logEvent(
      ANALYTICS_EVENT_TYPES.THE_WALL_CREATE_NEW_POST_CLICK,
      '',
      ANALYTICS_ITEM_NAMES.THE_WALL
    );
    history.push(NAVIGATION_ROUTES.THE_WALL_CREATE_POST);
  };

  return (
    <div className="container">
      <div className="gcdo-header-row header-view-absolute justify-content-end float-right">
        <div
          className={
            isSearchEnabled
              ? 'search-bar-with-icon search-bar-border mr-3'
              : 'search-bar-with-icon mr-3'
          }>
          {isSearchEnabled ? (
            <form
              className="directory-search-bar"
              onSubmit={onSearchSubmitHandler}
              ref={textInputRef}>
              <input
                autoFocus
                className="ml-2 directory-search-bar pr-3"
                type="text"
                ref={inputSearchRef}
                placeholder="Search.."
                maxLength={50}
                onChange={debounceInputHandler}
              />
            </form>
          ) : (
            <div className="directory-search-bar" />
          )}

          {isSearchEnabled ? (
            <div className="gcdo-icon-row" onClick={resetSearchHandler}>
              <img
                src={getHeaderComponentUrl(
                  accessibilityData,
                  ACCESSIBILITY_HEADER_COMPONENTS.SEARCH_CANCEL
                )}
                className="header-icon mr-2"
              />
            </div>
          ) : (
            <div className="gcdo-icon-row" onClick={onClickSearchHandler}>
              <img
                src={getHeaderComponentUrl(
                  accessibilityData,
                  ACCESSIBILITY_HEADER_COMPONENTS.SEARCH
                )}
                className="header-icon mr-2"
              />
            </div>
          )}
        </div>
        {showCreatePostButton && (
          <div className="gcdo-icon-row">
            <CustomButton buttonMainContainer="text-nowrap" onClick={onClickButton}>
              {'Share a Thought or an Idea'}
            </CustomButton>
          </div>
        )}
      </div>
      <SectionHeader identifier={ACCESSIBILITY_IDENTIFIERS.THE_WALL} />

      {searchString !== '' && <BackButton isFullRow={true} />}
      {isTheWallNewPostDialogVisible && (
        <TheWallNewPostDialog
          isTheWallNewPostDialogVisible={isTheWallNewPostDialogVisible}
          setIsTheWallNewPostDialogVisible={setIsTheWallNewPostDialogVisible}
        />
      )}
      <div className="py-3 common-container-md mx-auto">
        <ActivityLoader visible={quickSearchLoading} />
        <div>
          {wallsData && wallsData.length > 0
            ? wallsData.map((item, index) => renderWallPost(item, index))
            : showEmptyView &&
              wallsData?.length === 0 && (
                <DefaultContentView
                  style="mt-3"
                  message={ERROR_MESSAGES.SEARCH_NO_RESULTS}
                  iconUri={getFeatureComponentUrl(
                    accessibilityData,
                    ACCESSIBILITY_FEATURE_COMPONENTS.CELEBRATIONS_SEARCH_FAILED,
                    ACCESSIBILITY_IDENTIFIERS.CELEBRATIONS
                  )}
                />
              )}
        </div>
        {loadMoreFlagRef.current && !!wallsData.length && (
          <div className="mb-4 mt-4">
            <LoadMoreBtn
              onClick={loadMoreHandler}
              isLoading={progressLoadingState.isProgressLoading}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default TheWallMainScreen;
