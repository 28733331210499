import React from 'react';
import { getFeatureComponentUrl } from '../../../utils/accessibility';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  MESSAGES,
} from '../../../common/constants/AppConstants';
import { useSelector } from 'react-redux';
import { selectAccessibilityData } from '../../../state/AccessibilityData';

const DeliveryNote = () => {
  const accessibilityData = useSelector(selectAccessibilityData);
  return (
    <div className="delivery-note-container">
      <img
        src={getFeatureComponentUrl(
          accessibilityData,
          ACCESSIBILITY_FEATURE_COMPONENTS.DELIVERY_NOTE,
          ACCESSIBILITY_IDENTIFIERS.MICRO_STORE
        )}
        className="delivery-note-icon"
      />
      <div className="delivery-note-text">{MESSAGES.ESTORE_DELIVERY_NOTE}</div>
    </div>
  );
};

export default DeliveryNote;
