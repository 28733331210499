import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectAccessibilityData } from '../../../state/AccessibilityData';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  REQUEST_TYPE,
} from '../../constants/AppConstants';
import { logEvent } from '../../../utils/FirebaseAnalyticsUtils';
import { saveProgressLoadingState, setShowAttendanceModal } from '../../../state/UIState';
import { apiRequest } from '../../../services/Service';
import { GET_STATUS, LOGIN, LOGOUT } from '../../../services/ApiUrls';
import { handleAttendance } from '../../../utils/featureNavigation';
import { getEmployeeData } from '../../../utils/ReduxState';
import { Modal, Spinner } from 'react-bootstrap';
import { getFeatureComponentUrl } from '../../../utils/accessibility';
import { getDDMonthYYYYdddd, getddddDDMonthYYYYhhmmA } from '../../../utils/common';
import CustomButton from '../custom_button/CustomButton';
import colors from '../../themes/Colors';
import CustomCheckbox from './CustomCheckBox';

const AttendanceModal = () => {
  const dispatch = useDispatch();
  const accessibilityData = useSelector(selectAccessibilityData);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [loginLogoutTime, setLoginLogoutTime] = useState('');
  const [isAttendanceLoading, setIsAttendanceLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [autoLogout, setAutoLogOut] = useState(0);

  const getStatus = useCallback(async () => {
    dispatch(
      saveProgressLoadingState({
        isProgressLoading: true,
      })
    );
    const apiResponse = await apiRequest(GET_STATUS, REQUEST_TYPE.GET);
    dispatch(
      saveProgressLoadingState({
        isProgressLoading: false,
      })
    );
    setIsLoggedIn(apiResponse.response.data?.isLoggedIn);
    setLoginLogoutTime(apiResponse.response.data?.dateTime);
    setShowModal(true);
  }, [dispatch]);

  useEffect(() => {
    getStatus();
  }, [getStatus]);

  const handleSignInSignOut = async () => {
    logEvent(
      isLoggedIn
        ? ANALYTICS_EVENT_TYPES.ATTENDANCE_SIGN_OUT
        : ANALYTICS_EVENT_TYPES.ATTENDANCE_SIGN_IN,
      '',
      ANALYTICS_ITEM_NAMES.ATTENDANCE
    );
    if (!isAttendanceLoading) {
      if (isLoggedIn) {
        setIsAttendanceLoading(true);
        const newR = await apiRequest(isLoggedIn ? LOGOUT : LOGIN, REQUEST_TYPE.GET);
        const apiResponse = await apiRequest(GET_STATUS, REQUEST_TYPE.GET);
        setIsLoggedIn(apiResponse.response.data?.isLoggedIn);
        setLoginLogoutTime(apiResponse.response.data?.dateTime);
        setIsAttendanceLoading(false);
      } else {
        setIsAttendanceLoading(true);
        await apiRequest(isLoggedIn ? LOGOUT : LOGIN, REQUEST_TYPE.POST, {
          auto_logout: autoLogout,
        });
        const apiResponse = await apiRequest(GET_STATUS, REQUEST_TYPE.GET);
        setIsLoggedIn(apiResponse.response.data?.isLoggedIn);
        setLoginLogoutTime(apiResponse.response.data?.dateTime);
        setIsAttendanceLoading(false);
      }
    }
  };

  const handleRegularise = () => {
    logEvent(
      ANALYTICS_EVENT_TYPES.ATTENDANCE_REGULARISE_BUTTON_CLICK,
      '',
      ANALYTICS_ITEM_NAMES.ATTENDANCE
    );
    dispatch(setShowAttendanceModal(false));
    setShowModal(false);
    handleAttendance(getEmployeeData());
  };
  const handleAutoSignOutChange = (event) => {
    event.target.checked ? setAutoLogOut(1) : setAutoLogOut(0);
  };
  const renderModal = () => {
    return (
      <div>
        <div className="attendance-modal-title-close-icon">
          <div className="attendance-modal-title">ATTENDANCE</div>
          <div className="attendance-modal-close-icon">
            <img
              onClick={() => {
                setShowModal(false);
                dispatch(setShowAttendanceModal(false));
              }}
              src={getFeatureComponentUrl(
                accessibilityData,
                ACCESSIBILITY_FEATURE_COMPONENTS.SF_CLOSE_ICON,
                ACCESSIBILITY_IDENTIFIERS.CONTINUOUS_FEEDBACK
              )}
              className="attendance-close-icon"
            />
          </div>
        </div>
        <div className="attendance-info-container">
          <div>
            <div className="d-flex flex-row">
              <img
                src={getFeatureComponentUrl(
                  accessibilityData,
                  ACCESSIBILITY_FEATURE_COMPONENTS.PROFILE_CALENDER_BLUE,
                  ACCESSIBILITY_IDENTIFIERS.PROFILE
                )}
                className="common-comment-item-action-item-icon mr-1"
              />
              <div className="attendance-title">Attendance</div>
            </div>
            <div className="attendance-date-subtext">{getDDMonthYYYYdddd()}</div>
          </div>
          <div className="d-flex flex-row align-items-center">
            {!isLoggedIn && (
              <div className="pr-2">
                <div className="ml-3 d-flex flex-row align-items-center">
                  <CustomCheckbox
                    onChange={handleAutoSignOutChange}
                    checked={autoLogout == 0 ? false : true}
                    disabled={isLoggedIn}></CustomCheckbox>
                  <div className="auto-login-text">Auto sign-out in 9.5 hours?</div>
                </div>
              </div>
            )}
            <CustomButton
              buttonStyle="attendance-signin-signout-button"
              onClick={handleSignInSignOut}>
              {isLoggedIn ? 'Sign Out' : 'Sign In'}
              {isAttendanceLoading && (
                <Spinner
                  animation="border"
                  className="ml-1"
                  style={{ color: colors.white }}
                  size={'sm'}
                />
              )}
            </CustomButton>
          </div>
        </div>
        {loginLogoutTime && (
          <div className="attendance-last-seen-container">
            {isLoggedIn ? (
              <div className="attendance-green-ring mr-2">
                <div className="attendance-white-ring">
                  <div className="attendance-green-dot" />
                </div>
              </div>
            ) : (
              <div className="attendance-red-ring mr-2">
                <div className="attendance-white-ring">
                  <div className="attendance-red-dot" />
                </div>
              </div>
            )}
            <div className="attendance-last-update-status">
              Last status change: {getddddDDMonthYYYYhhmmA(loginLogoutTime)}
            </div>
          </div>
        )}
        <div className="attendance-regularise-node">
          To review or regularize your attendance. please visit MiHR by clicking the button below:
        </div>
        <div className="attendance-regularise-node"></div>
        <div className="attendance-regularise-node">
          <span className="noteFont">Note :</span> You can adjust your attendance for past dates,
          but adjustments for today or the current day are not allowed.
        </div>
        <CustomButton buttonStyle="attendance-regularise-button" onClick={handleRegularise}>
          REGULARIZE ATTENDANCE
        </CustomButton>
      </div>
    );
  };

  return (
    <div>
      <Modal show={showModal} aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Body>{renderModal()}</Modal.Body>
      </Modal>
    </div>
  );
};
export default AttendanceModal;
