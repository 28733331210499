import React, { useState, useEffect, useCallback } from 'react';
import {
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_SCREEN_NAMES,
  FILTER_IDENTIFIERS,
  NAVIGATION_ROUTES,
} from '../../common/constants/AppConstants';
import { useDispatch, useSelector } from 'react-redux';
import { getDateInYYYYMMDD } from '../../utils/common';
import { selectAccessibilityData } from '../../state/AccessibilityData';
import { selectGalleryCategories } from '../../state/MasterData';
import GalleryTab from './tabs/GalleryTab';
import { saveSearchText, selectBaseUrl } from '../../state/UIState';
import CalendarDialog from '../../common/ui/calender_dialog/CalendarDialog';
import { Tab } from 'react-bootstrap';
import { saveGalleryTabData, selectGalleryTabData } from '../../state/TabData';
import Filter from '../../common/ui/filter/Filter';
import SectionHeader from '../../common/ui/section_header/SectionHeader';
import { trackScreen } from '../../utils/FirebaseAnalyticsUtils';
import NavTabs from '../../common/ui/tabs/NavTabs';
import SearchComponent from '../../common/ui/search/SearchComponent';
import { selectAccessData } from '../../state/UserAccessData';

const GalleryMainScreen = ({ history, location }) => {
  const dispatch = useDispatch();
  const baseUrl = useSelector(selectBaseUrl);
  const galleryCategories = useSelector(selectGalleryCategories);
  const galleryTabData = useSelector(selectGalleryTabData);
  const [tabKey] = useState(
    location && location.state && location.state.tabKey && location.state.tabKey
  );
  const [filterShown, setFilterShown] = useState(false);
  const [showCalender, setShowCalender] = useState(false);
  const [appliedFilters, setAppliedFilters] = useState({});
  const [calenderDate, setCalenderDate] = useState({});
  const [calenderFilterApplied, setCalenderFilterApplied] = useState({});
  const [selectedDate, setSelectedDate] = useState('');
  const [galleryTabs, setGalleryTabs] = useState([]);
  const accessData = useSelector(selectAccessData);
  const [showFilterButton, setShowFilterButton] = useState(true);
  const galleyAccessData = accessData?.data?.find((item) => item.feature_key === '_gallery');

  useEffect(() => {
    if (galleyAccessData) {
      const actions = galleyAccessData?.actions;
      setShowFilterButton(actions?.includes('_filter'));
    }
  }, [galleyAccessData]);

  const accessibilityData = useSelector(selectAccessibilityData);

  useEffect(() => {
    trackScreen(ANALYTICS_SCREEN_NAMES.GALLERY, NAVIGATION_ROUTES.GALLERY);
  }, []);

  useEffect(() => {
    let tempDates = {};
    let filterApplied = {};
    if (galleryCategories.length) {
      galleryCategories.forEach((element) => {
        tempDates[element.feature] = new Date();
        filterApplied[element.feature] = false;
      });
      setCalenderDate({ ...tempDates });
      setCalenderFilterApplied({ ...filterApplied });
    }
  }, [galleryCategories]);

  useEffect(() => {
    if (galleryCategories.length && galleryTabData === '' && tabKey) {
      dispatch(saveGalleryTabData(tabKey));
    } else if (galleryCategories.length && galleryTabData === '') {
      dispatch(saveGalleryTabData(galleryCategories[0].feature));
    }
  }, [dispatch, galleryCategories, galleryTabData, tabKey]);

  useEffect(() => {
    if (galleryCategories.length && galleryTabData !== '') {
      let index = galleryCategories.findIndex((item) => {
        return item.calender_filter === true && item.feature === galleryTabData;
      });
      index > -1 ? setShowCalender(true) : setShowCalender(false);
    }
  }, [galleryCategories, galleryTabData]);

  useEffect(() => {
    if (galleryCategories.length && galleryTabData !== '') {
      let index = galleryCategories.findIndex((item) => {
        return item.geo_filter === true && item.feature === galleryTabData;
      });
      index > -1 ? setFilterShown(true) : setFilterShown(false);
    }
  }, [galleryCategories, galleryTabData]);

  const renderTabs = (tab) => {
    return (
      <GalleryTab
        filterShown={filterShown}
        appliedFilters={appliedFilters}
        tabKey={tab.feature}
        tab={tab}
        date={selectedDate}
      />
    );
  };

  const onDateSelected = (date) => {
    let formattedDate = getDateInYYYYMMDD(date);
    let cData = { ...calenderDate };
    cData[galleryTabData] = date;
    setCalenderDate(cData);
    let cFilterData = { ...calenderFilterApplied };
    cFilterData[galleryTabData] = true;
    setCalenderFilterApplied(cFilterData);
    setSelectedDate(formattedDate);
  };

  const filterData = useCallback((selectedFilters) => {
    let tempFilterObject = {};
    if (selectedFilters && Object.keys(selectedFilters).length > 0) {
      Object.keys(selectedFilters).map((filterKey) => {
        if (selectedFilters[filterKey].length) {
          tempFilterObject[filterKey] = selectedFilters[filterKey].join();
        }
      });
    }
    setAppliedFilters(tempFilterObject);
  }, []);

  const handleTabs = useCallback(() => {
    let list = galleryCategories.filter((item) => item?.is_active === true);
    setGalleryTabs(list);
  }, [galleryCategories]);

  useEffect(() => {
    handleTabs();
  }, [galleryCategories, handleTabs]);

  const handleSubmitFunction = (searchText) => {
    dispatch(saveSearchText(searchText));
    history.push(NAVIGATION_ROUTES.GALLERY_SEARCH, { searchQuery: searchText });
  };

  return (
    <div className="container">
      <div className="gcdo-header-row header-view-absolute justify-content-end float-right w-50">
        <SearchComponent handleSubmitFunction={handleSubmitFunction} />
        {showCalender && (
          <div className="gcdo-icon-row">
            <CalendarDialog
              markedDates={[]}
              onDateSelected={onDateSelected}
              selectedDate={
                calenderDate && Object.keys(calenderDate).length ? calenderDate[galleryTabData] : ''
              }
              popperPosition="bottom-end"
            />
          </div>
        )}
      </div>
      <SectionHeader identifier={ACCESSIBILITY_IDENTIFIERS.GALLERY} />
      {filterShown && showFilterButton && (
        <div className="pt-3">
          <Filter
            topFilter={FILTER_IDENTIFIERS.GEOGRAPHIES}
            geographies={true}
            locations={true}
            levels={true}
            layers={true}
            onClick={filterData}
          />
        </div>
      )}
      {baseUrl !== '' && (
        <NavTabs
          activeKey={galleryTabData}
          onSelect={(k) => {
            dispatch(saveGalleryTabData(k));
            if (Object.keys(appliedFilters).length > 0) {
              filterData({});
            }
            if (calenderFilterApplied[k] === true) {
              setSelectedDate(getDateInYYYYMMDD(calenderDate[k]));
            } else {
              setSelectedDate('');
            }
          }}
          unmountOnExit>
          {galleryTabs.length > 0
            ? galleryTabs.map((tab, index) => {
                return (
                  <Tab key={`${index}`} eventKey={tab.feature} title={tab.title}>
                    {renderTabs(tab, index)}
                  </Tab>
                );
              })
            : galleryCategories.map((tab, index) => {
                return (
                  <Tab key={`${index}`} eventKey={tab.feature} title={tab.title}>
                    {renderTabs(tab, index)}
                  </Tab>
                );
              })}
        </NavTabs>
      )}
    </div>
  );
};

export default GalleryMainScreen;
