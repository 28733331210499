import React, { useCallback, useState, useEffect } from 'react';
import LoadMoreBtn from '../../../common/ui/load_more_btn/LoadMoreBtn';
import { Modal } from 'react-bootstrap';
import Select from 'react-select';
import { saveProgressLoadingState } from '../../../state/UIState';
import { apiRequest } from '../../../services/Service';
import {
  CHILD_CARE_APPLY,
  CHILD_CARE_WITHDRAW,
  GET_MI_CHILD_CARE_CENTRES,
} from '../../../services/ApiUrls';
import {
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  ERROR_MESSAGES,
  REQUEST_TYPE,
} from '../../../common/constants/AppConstants';
import { useDispatch, useSelector } from 'react-redux';
import {
  getDateInMMMMYYYY,
  getDateInMMMYYYY,
  getDateInYYYYMMDD,
  showToast,
} from '../../../utils/common';
import { logEvent } from '../../../utils/FirebaseAnalyticsUtils';
import { selectMiCareConfig } from '../../../state/MasterData';
import { selectEmployeeData } from '../../../state/EmployeeData';

export default function RequestsTab({ data, callMiChildCareApi }) {
  const [showDayCareRequestDialogue, setShowDayCareRequestDialogue] = useState(false);
  const [showDayCareRequestSuccessDialogue, setShowDayCareRequestSuccessDialogue] = useState(false);
  const [showWithdrawConfirmation, setShowWithdrawConfirmation] = useState(false);
  const [showAcknowladgment, setShowAcknowladgment] = useState(false);
  const dispatch = useDispatch();
  const [centres, setCentres] = useState([]);
  const [currentItem, setCurrentItem] = useState(null);

  const [childAgeYear, setChildageYear] = useState(null);
  const [childAgeMonth, setChildAgeMonth] = useState(null);
  const employeeData = useSelector(selectEmployeeData);

  // child name
  const [childname, setChildName] = useState('');

  // start Month
  const [selectedStartMonth, setSelectedStartMonth] = useState(null);

  // end month
  const [selectedEndMonth, setSelectedEndMonth] = useState(null);
  const [endDate, setEndDate] = useState('');
  const acknowledgementPeragraphs = useSelector(selectMiCareConfig);

  // clay centre
  const [selectedClayCentre, setSelectedClayCentre] = useState(null);

  // Request response
  const [requestResp, setRequestResp] = useState(null);

  const [ageYears] = useState([
    { label: 0, value: 0 },
    { label: 1, value: 1 },
    { label: 2, value: 2 },
    { label: 3, value: 3 },
    { label: 4, value: 4 },
    { label: 5, value: 5 },
    { label: 6, value: 6 },
  ]);

  const [ageMonths] = useState([
    { label: 0, value: 0 },
    { label: 1, value: 1 },
    { label: 2, value: 2 },
    { label: 3, value: 3 },
    { label: 4, value: 4 },
    { label: 5, value: 5 },
    { label: 6, value: 6 },
    { label: 7, value: 7 },
    { label: 8, value: 8 },
    { label: 9, value: 9 },
    { label: 10, value: 10 },
    { label: 11, value: 11 },
  ]);

  const MonthNames = {
    0: 'JAN',
    1: 'FEB',
    2: 'MAR',
    3: 'APR',
    4: 'MAY',
    5: 'JUN',
    6: 'JUL',
    7: 'AUG',
    8: 'SEP',
    9: 'OCT',
    10: 'NOV',
    11: 'DEC',
  };

  const generateNext12Months = () => {
    const today = new Date();
    const months = [];

    for (let i = 0; i < 12; i++) {
      const month = (today.getMonth() + i) % 12;
      const year = today.getFullYear() + Math.floor((today.getMonth() + i) / 12);
      const monthName = `${MonthNames[month]} ${year}`;
      months.push({ label: monthName, value: monthName });
    }

    return months;
  };

  const [upcomingMonthsArray] = useState(generateNext12Months());
  const tableColumnHeadings = [
    'Name of child',
    'Age',
    'Start Month',
    'End Month',
    'Preferred Klay Centre',
    'Status',
    'Actions',
  ];

  function getFirstDayOfMonth(monthYear) {
    // Split the input string into month and year
    const [month, year] = monthYear.toUpperCase().split(' ');

    // Convert month name to its numeric representation
    const monthMap = {
      JAN: 0,
      FEB: 1,
      MAR: 2,
      APR: 3,
      MAY: 4,
      JUN: 5,
      JUL: 6,
      AUG: 7,
      SEP: 8,
      OCT: 9,
      NOV: 10,
      DEC: 11,
    };

    // Get the numeric representation of the month
    const monthIndex = monthMap[month];

    // Construct a Date object with the first day of the specified month and year
    const firstDayOfMonth = new Date(year, monthIndex, 1);

    // Format the date as YYYY-MM-DD
    const formattedDate = `${firstDayOfMonth.getFullYear()}-${(firstDayOfMonth.getMonth() + 1)
      .toString()
      .padStart(2, '0')}-01`;

    return formattedDate;
  }

  useEffect(() => {
    if (selectedStartMonth) {
      const date = getFirstDayOfMonth(selectedStartMonth?.value);
      const dd = new Date(date);
      const newDate = new Date(dd.setMonth(dd.getMonth() + 3));
      const lastDay = new Date(newDate.getFullYear(), newDate.getMonth(), 0);
      let lastDateFormatted = getDateInYYYYMMDD(lastDay);
      setEndDate(lastDateFormatted);

      // Format the date as MMM YYYY e.g. MAY 2024
      const formattedDate = getDateInMMMYYYY(lastDay).toUpperCase();
      let newDateItem = { label: formattedDate, value: formattedDate };
      setSelectedEndMonth(newDateItem);
    }
  }, [selectedStartMonth]);

  const callCentresListApi = useCallback(async () => {
    try {
      dispatch(saveProgressLoadingState({ isProgressLoading: true }));
      logEvent(
        ANALYTICS_EVENT_TYPES.MI_CHILD_CARE_FETCH_CENTRES,
        '',
        ANALYTICS_ITEM_NAMES.MI_CHILD_CARE
      );
      const apiResponse = await apiRequest(GET_MI_CHILD_CARE_CENTRES, REQUEST_TYPE.GET, {});
      if (Object.keys(apiResponse).length > 0) {
        if (apiResponse.response.status === true) {
          if (apiResponse.response) {
            const data = apiResponse?.response?.data?.feeds || [];
            let list = data.map((x) => {
              let newObj = { ...x, label: x?.name, value: x?.center_id };
              return newObj;
            });
            setCentres(list);
          }
        }
      }
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
    } catch (err) {
      // handleError(err, {}, ARTICLE_BY_ID + articleId, NAVIGATION_ROUTES.MICROBIZ_DETAIL);
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
    }
  }, [dispatch]);

  const callRegisterApi = useCallback(async () => {
    let acknowledgementText = '';
    acknowledgementPeragraphs.map((peragraph) => (acknowledgementText += `${peragraph}\n`));
    acknowledgementText += `Employee Signature: ${employeeData.first_name} ${employeeData.middle_name} ${employeeData.last_name}`;

    const ageInMonths = (childAgeYear?.value || 0) * 12 + (childAgeMonth?.value || 0);
    if (
      childname === '' ||
      selectedClayCentre === null ||
      selectedStartMonth === null ||
      selectedEndMonth === null ||
      ageMonths < 1
    ) {
      showToast(ERROR_MESSAGES.ENTER_ALL_FIELDS);
      return;
    }
    try {
      dispatch(saveProgressLoadingState({ isProgressLoading: true }));
      logEvent(
        ANALYTICS_EVENT_TYPES.MI_CHILD_CARE_REGISTRATION,
        '',
        ANALYTICS_ITEM_NAMES.MI_CHILD_CARE
      );
      const startDate = getFirstDayOfMonth(selectedStartMonth?.value) || '';
      const formData = new URLSearchParams();
      formData.append('child_name', childname);
      formData.append('center_id', selectedClayCentre?.center_id);
      formData.append('start_date', startDate);
      formData.append('end_date', endDate);
      formData.append('age_in_month', ageInMonths);
      formData.append('acknowledged_text', acknowledgementText);
      const apiResponse = await apiRequest(CHILD_CARE_APPLY, REQUEST_TYPE.POST, formData);
      if (Object.keys(apiResponse).length > 0) {
        if (apiResponse.response.status === true) {
          if (apiResponse.response) {
            setRequestResp(apiResponse?.response || null);
            setShowDayCareRequestDialogue(false);
            setShowDayCareRequestSuccessDialogue(true);
          }
        }
      }
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
    } catch (err) {
      // handleError(err, {}, ARTICLE_BY_ID + articleId, NAVIGATION_ROUTES.MICROBIZ_DETAIL);
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
    }
  }, [
    acknowledgementPeragraphs,
    ageMonths,
    childAgeMonth?.value,
    childAgeYear?.value,
    childname,
    dispatch,
    employeeData.first_name,
    employeeData.last_name,
    employeeData.middle_name,
    endDate,
    selectedClayCentre,
    selectedEndMonth,
    selectedStartMonth,
  ]);

  const callWithdrawApi = useCallback(async () => {
    try {
      dispatch(saveProgressLoadingState({ isProgressLoading: true }));
      logEvent(
        ANALYTICS_EVENT_TYPES.MI_CHILD_CARE_WITHDRAW,
        currentItem?.request_id || '',
        ANALYTICS_ITEM_NAMES.MI_CHILD_CARE
      );
      const formData = new URLSearchParams();
      formData.append('request_id', currentItem?.request_id || '');
      const apiResponse = await apiRequest(CHILD_CARE_WITHDRAW, REQUEST_TYPE.POST, formData);
      if (Object.keys(apiResponse).length > 0) {
        if (apiResponse.response.status === true) {
          if (apiResponse.response) {
            showToast(apiResponse?.response?.message || 'Application withdrawn successfully.');
            setCurrentItem(null);
            callMiChildCareApi();
          }
        }
      }
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
    } catch (err) {
      // handleError(err, {}, ARTICLE_BY_ID + articleId, NAVIGATION_ROUTES.MICROBIZ_DETAIL);

      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
    }
  }, [callMiChildCareApi, currentItem?.request_id, dispatch]);

  useEffect(() => {
    callCentresListApi();
  }, [callCentresListApi]);

  const onChangeText = (event) => {
    let name = event?.target?.value;
    setChildName(name?.trim());
  };

  const requestCareModal = () => (
    <Modal
      show={showDayCareRequestDialogue}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={() => setShowDayCareRequestDialogue(false)}>
      <Modal.Body>
        <Modal.Title>Request for the day care facility</Modal.Title>
        <hr />
        <div>
          In case you are interested in enrolling your little one, please fill out the details below
        </div>
        <div className="mb-3">
          <div className="mt-2">Name of child</div>
          <input
            maxLength={30}
            type="text"
            className="michildcare-name w-75 pl-2"
            onChange={onChangeText}
          />
          <div className="mt-2 ">Age</div>
          <div className="w-75 michildcare-age-container">
            <div className="michildcare-age-items">
              <Select
                value={childAgeYear}
                placeholder="Years"
                onChange={(ch) => {
                  setChildageYear(ch);
                }}
                options={ageYears}
                isSearchable={false}
              />
            </div>
            <div className="ml-3" />
            <div className="michildcare-age-items">
              <Select
                value={childAgeMonth}
                placeholder="Months"
                onChange={(ch) => {
                  setChildAgeMonth(ch);
                }}
                options={ageMonths}
                defaultValue={0}
                isSearchable={false}
              />
            </div>
          </div>

          <div className="w-75 michildcare-age-container">
            <div className="michildcare-age-items">
              <div className="mt-2">Start Month</div>
              <Select
                value={selectedStartMonth}
                placeholder="Select"
                onChange={(ch) => {
                  setSelectedStartMonth(ch);
                }}
                options={upcomingMonthsArray}
                defaultValue={0}
                isSearchable={false}
              />
            </div>
            <div className="ml-3" />
            <div className="michildcare-age-items">
              <div className="mt-2">End Month</div>
              <Select
                isDisabled={true}
                value={selectedEndMonth}
                placeholder="Auto select"
                options={upcomingMonthsArray}
                isSearchable={false}
              />
            </div>
          </div>
          <div>
            <div className="mt-2">Preferred day care facility</div>
            <Select
              value={selectedClayCentre}
              placeholder="Select"
              onChange={(ch) => setSelectedClayCentre(ch)}
              options={centres}
              className="w-75"
              defaultValue={0}
              isSearchable={false}
            />
          </div>
        </div>
        <hr />
        <div className="michildcare-footer-btn-container">
          <button
            className="michildcare-modal-buttons"
            onClick={() => {
              setShowDayCareRequestDialogue(false);
              setShowAcknowladgment(true);
            }}>
            SUBMIT
          </button>
          <div className="ml-3" />
          <button
            onClick={() => {
              setShowDayCareRequestDialogue(false);
              setChildName('');
              setSelectedStartMonth(null);
              setSelectedEndMonth(null);
              setChildAgeMonth(null);
              setChildageYear(null);
              setEndDate('');
              setSelectedClayCentre(null);
            }}
            className="michildcare-modal-buttons-no">
            CANCEL
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );

  const requestSuccessModal = () => (
    <Modal
      show={showDayCareRequestSuccessDialogue}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      size="sm"
      onHide={() => {
        setShowDayCareRequestSuccessDialogue(false);
        callMiChildCareApi();
      }}>
      <Modal.Body>
        <div className="michildcare-text-centre">
          <div className="michildcare-modal-title">Submitted successfully!</div>
          <div>{requestResp?.message || 'Your request is sent for an approval.'} </div>
        </div>
        <div className="michildcare-confirmation-modal-btn-container">
          <LoadMoreBtn
            btnText="OK"
            onClick={() => {
              setRequestResp(null);
              setShowDayCareRequestSuccessDialogue(false);
              callMiChildCareApi();
            }}
            buttonStyle="michildcare-modal-buttons"
          />
        </div>
      </Modal.Body>
    </Modal>
  );

  const acknowladgmentModal = () => (
    <Modal
      show={showAcknowladgment}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      size="lg"
      onHide={() => {
        setShowAcknowladgment(false);
      }}>
      <Modal.Body className="p-0">
        <div>
          <div className="michildcare-modal-title pl-3 pt-3 pr-3">REQUEST FOR DAY CARE</div>
          <div className="acknowledgment-borders mb-2">
            <div className="michildcare-acknowledgement-title">ACKNOWLEDGEMENT</div>
          </div>
          <div className="p-3">
            {acknowledgementPeragraphs.map((peragraph, index) => (
              <div key={index} className="estore-regular-text mb-3">
                {peragraph}
              </div>
            ))}
            <div className="estore-regular-text">
              {`Employee Signature: ${employeeData.first_name} ${employeeData.middle_name} ${employeeData.last_name}`}
            </div>
          </div>
        </div>
        <div className="michildcare-confirmation-modal-btn-container pb-3">
          <LoadMoreBtn
            btnText="I AGREE"
            onClick={() => {
              setRequestResp(null);
              setShowAcknowladgment(false);
              callRegisterApi();
            }}
            buttonStyle="michildcare-modal-buttons"
          />
        </div>
      </Modal.Body>
    </Modal>
  );

  const withdrawConfirmationModal = () => (
    <Modal
      show={showWithdrawConfirmation}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      size="sm"
      onHide={() => setShowWithdrawConfirmation(false)}>
      <Modal.Body>
        <div className="michildcare-text-centre">
          <div className="michildcare-modal-title">Confirmation</div>
          <div>{`Are you sure?`} </div>
          <div>{`You would like to withdraw this request.`} </div>
        </div>
        <div className="michildcare-confirmation-modal-btn-container">
          <button
            onClick={() => {
              setShowWithdrawConfirmation(false);
            }}
            className="michildcare-modal-buttons-no">
            NO
          </button>
          <div className="ml-3" />
          <button
            onClick={() => {
              setShowWithdrawConfirmation(false);
              callWithdrawApi();
            }}
            className="michildcare-modal-buttons">
            YES
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );

  const formatAge = (months) => {
    if (months === 0) {
      return '0';
    }
    if (months < 12 && months > 0) {
      // 1 st if block
      if (months === 1) {
        return `1 month`;
      } else {
        return `${months} months`;
      }
    } else {
      // 2nd block
      if (months >= 12) {
        if (months === 12) {
          return '1 year';
        } else {
          return `${parseInt(months / 12)} years ${months % 12} months`;
        }
      }
    }
  };

  const getStatusTextWithColors = (status) => {
    switch (status) {
      case '0':
        return 'Waiting for admin approval';
      case '1':
        return 'Approved';
      case '2':
        return 'Rejected';
      case '3':
        return 'Withdrawn';
      default:
        return '';
    }
  };

  const getStatusColors = (status) => {
    switch (status) {
      case '0':
        return 'red';
      case '1':
        return 'green';
      case '2':
        return 'black';
      case '3':
        return 'black';
      default:
        return '';
    }
  };

  return (
    <div>
      <p>
        In case you are interested in enrolling your little one, please click on the button below:
      </p>
      <button
        className="michildcare-modal-buttons"
        onClick={() => setShowDayCareRequestDialogue(true)}>
        REQUEST FOR DAY CARE
      </button>

      <hr />
      <p className="michildcare-yourrequests-text">YOUR REQUESTS</p>

      <table className="michildcare-table" cellPadding={2} border={1}>
        <tbody>
          <tr className="michildcare-tr michildcare-bg">
            {tableColumnHeadings?.map((columnHead) => (
              <td key={columnHead} className="michildcare-column-titles michildcare-td">
                {columnHead}
              </td>
            ))}
          </tr>
          {data?.length == 0 && (
            <tr className="michildcare-no-data">
              <td colSpan={7} className="michildcare-td">
                No request exists.
              </td>
            </tr>
          )}
          {data?.map((data_) => {
            return (
              <tr key={data_?.request_id} className="michildcare-tr">
                <td className="michildcare-td">{data_?.child_name || ''}</td>
                <td className="michildcare-td">{formatAge(data_?.age_in_month || '')}</td>
                <td className="michildcare-td">{getDateInMMMMYYYY(data_?.start_date) || ''}</td>
                <td className="michildcare-td">{getDateInMMMMYYYY(data_?.end_date) || ''}</td>
                <td className="michildcare-td">{data_?.center_name || ''}</td>
                <td
                  className="michildcare-td"
                  style={{ color: getStatusColors(data_?.status) || 'black' }}>
                  {getStatusTextWithColors(data_?.status) || ''}
                </td>
                <td>
                  {data_?.status == 0 && (
                    <button
                      onClick={() => {
                        setCurrentItem(data_);
                        setShowWithdrawConfirmation(true);
                      }}
                      className="michildcare-modal-buttons m-1">
                      WITHDRAW
                    </button>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>

      {requestCareModal()}
      {requestSuccessModal()}
      {acknowladgmentModal()}
      {withdrawConfirmationModal()}
    </div>
  );
}
