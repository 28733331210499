import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { OverlayTrigger, Popover, Modal, Carousel } from 'react-bootstrap';

import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  ANALYTICS_SCREEN_NAMES,
  FEATURE_TYPE,
  LOADING_MESSAGES,
  MESSAGES,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
  SCREEN_DENSITY_QUALIFIERS,
} from '../../common/constants/AppConstants';
import {
  formatDateDDMonthYYYY,
  formatTimeAMPM,
  getSecuredPdfUrl,
  getUrlWithSpecificRendition,
  isToday,
  showToast,
} from '../../utils/common';
import { saveProgressLoadingState, selectProgressLoadingState } from '../../state/UIState';
import { apiRequest, handleError } from '../../services/Service';
import { COMMENT_BY_ID, GET_WALL_BY_ID } from '../../services/ApiUrls';
import { selectAccessibilityData } from '../../state/AccessibilityData';
import { getFeatureComponentUrl, getHeaderComponentUrl } from '../../utils/accessibility';
import { logEvent, trackScreen } from '../../utils/FirebaseAnalyticsUtils';
import { selectEmployeeData } from '../../state/EmployeeData';
import SectionHeader from '../../common/ui/section_header/SectionHeader';
import BackButton from '../../common/ui/common_back_button/BackButton';
import UserProfilePhoto from '../../common/ui/user_profile_photo/UserProfilePhoto';
import Divider from '../../common/ui/divider/Divider';
import CustomButton from '../../common/ui/custom_button/CustomButton';
import TheWallNewPostDialog from './components/TheWallNewPostDialog';
import HtmlView from '../../common/ui/html_view/HTMLView';
import { selectWebBaseUrl } from '../../state/MasterData';
import { selectAccessData } from '../../state/UserAccessData';

const TheWallPostDetailScreen = ({ history, location }) => {
  const { postId } = useParams();
  const dispatch = useDispatch();
  const accessibilityData = useSelector(selectAccessibilityData);
  const [post, setPost] = useState({});
  const [isTheWallNewPostDialogVisible, setIsTheWallNewPostDialogVisible] = useState(false);
  const [currentActiveSlideIndex, setCurrentActiveSlideIndex] = useState(0);
  const [showReportDialog, setShowReportDialog] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const webUrl = useSelector(selectWebBaseUrl);
  const accessData = useSelector(selectAccessData);
  const [showLikeButton, setShowLikeButton] = useState(true);
  const [showCommentButton, setShowCommentButton] = useState(true);
  const wallAccessData = accessData?.data?.find((item) => item.feature_key === '_theWall');

  useEffect(() => {
    if (wallAccessData) {
      const actions = wallAccessData?.actions;
      setShowLikeButton(actions?.includes('_like'));
      setShowCommentButton(actions?.includes('_comment'));
    }
  }, [wallAccessData]);
  const [products] = useState([
    {
      id: 1,
      name: 'Inappropriate content',
    },
    {
      id: 2,
      name: 'Individual being targetted',
    },
    {
      id: 3,
      name: 'Posting id fake/unreliable',
    },
    {
      id: 4,
      name: 'Spam',
    },
  ]);
  const [radioSelected, setRadioSelected] = useState(products[0]);
  const progressLoadingState = useSelector(selectProgressLoadingState);
  const employeeData = useSelector(selectEmployeeData);
  const [htmlComponent, setHtmlComponent] = useState(null);

  useEffect(() => {
    trackScreen(
      ANALYTICS_SCREEN_NAMES.THE_WALL_POST_DETAIL,
      NAVIGATION_ROUTES.THE_WALL_POST_DETAIL
    );
  }, []);

  const callPostDetailApi = useCallback(async () => {
    dispatch(
      saveProgressLoadingState({
        isProgressLoading: true,
      })
    );
    try {
      const apiResponse = await apiRequest(GET_WALL_BY_ID + postId, REQUEST_TYPE.GET, {});
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: false,
        })
      );
      if (Object.keys(apiResponse).length > 0) {
        if (apiResponse.response.status === true) {
          if (apiResponse.response.data) {
            if (apiResponse.response.data.wallpost) {
              const data = apiResponse.response.data;
              if (data) {
                setPost(data.wallpost);
              }
            }
          }
        }
      }
    } catch (err) {
      handleError(err, {}, GET_WALL_BY_ID + postId, NAVIGATION_ROUTES.THE_WALL_POST_DETAIL);
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: false,
        })
      );
    }
  }, [dispatch, postId]);

  useEffect(() => {
    trackScreen(ANALYTICS_SCREEN_NAMES.POST_DETAIL, NAVIGATION_ROUTES.POST_DETAIL);
  }, []);

  useEffect(() => {
    if (postId) {
      callPostDetailApi();
    }
  }, [callPostDetailApi, postId]);

  useEffect(() => {
    if (location && location.state && location.state.refresh) {
      callPostDetailApi();
    }
  }, [callPostDetailApi, location]);

  const reportPostApi = async (val) => {
    dispatch(
      saveProgressLoadingState({
        isProgressLoading: true,
        progressMessage: LOADING_MESSAGES.REPORTING_POST,
      })
    );
    const params = new URLSearchParams();
    params.append('type', FEATURE_TYPE.WALL);
    params.append('text', val);
    try {
      const apiResponse = await apiRequest(
        COMMENT_BY_ID + postId + '/report',
        REQUEST_TYPE.POST,
        params
      );
      if (Object.keys(apiResponse).length > 0) {
        if (apiResponse.response.status === true) {
          if (apiResponse.response.message) {
            showToast(apiResponse.response.message);
            setRadioSelected(products[0]);
            setShowReportDialog(false);
          }
        }
      }
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: false,
        })
      );
    } catch (err) {
      handleError(
        err,
        params,
        COMMENT_BY_ID + postId + '/report',
        NAVIGATION_ROUTES.THE_WALL_POST_DETAIL
      );
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: false,
        })
      );
    }
  };

  const callLikeApi = async (postId) => {
    const params = new URLSearchParams();
    params.append('type', FEATURE_TYPE.WALL);
    try {
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: true,
          progressMessage: LOADING_MESSAGES.LIKE_UPDATE,
        })
      );
      const apiResponse = await apiRequest(
        COMMENT_BY_ID + postId + '/like',
        REQUEST_TYPE.POST,
        params
      );
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
      if (Object.keys(apiResponse).length > 0) {
        if (apiResponse.response.status === true) {
          callPostDetailApi();
        }
      }
    } catch (err) {
      handleError(
        err,
        params,
        COMMENT_BY_ID + postId + '/like',
        NAVIGATION_ROUTES.THE_WALL_POST_DETAIL
      );
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
    }
  };

  const onPressLikeButton = () => {
    logEvent(
      ANALYTICS_EVENT_TYPES.THE_WALL_LIKE,
      post.post_id.toString(),
      ANALYTICS_ITEM_NAMES.LIKE
    );
    callLikeApi(post.post_id);
  };

  const onPressReportHandler = () => {
    document.body.click();
    logEvent(
      ANALYTICS_EVENT_TYPES.THE_WALL_REPORT_POST_CLICK,
      post.post_id.toString(),
      ANALYTICS_ITEM_NAMES.THE_WALL
    );
    setShowReportDialog(true);
  };

  const onPressEditHandler = () => {
    document.body.click();
    logEvent(
      ANALYTICS_EVENT_TYPES.THE_WALL_EDIT_POST_CLICK,
      post.post_id.toString(),
      ANALYTICS_ITEM_NAMES.THE_WALL
    );
    if (post?.post_id) {
      history.push(`${NAVIGATION_ROUTES.THE_WALL}/post/${post?.post_id}/edit`, {
        post: post,
      });
    } else {
      return;
    }
  };

  const onPressDeleteHandler = () => {
    document.body.click();
    logEvent(
      ANALYTICS_EVENT_TYPES.THE_WALL_DELETE_POST_CLICK,
      post.post_id.toString(),
      ANALYTICS_ITEM_NAMES.THE_WALL
    );
    setDeleteDialog(true);
  };

  const handleHtmlClicks = (e) => {
    const target = e.target.getAttribute('href');
    if (target && target.startsWith('/')) {
      e.preventDefault();
      if (target.includes(NAVIGATION_ROUTES.EMPLOYEE_PROFILE_DETAIL)) {
        logEvent(
          ANALYTICS_EVENT_TYPES.USER_MENTION_CLICK,
          e.target.innerText,
          ANALYTICS_ITEM_NAMES.USER_MENTION
        );
        history.push(target);
      } else {
        logEvent(
          ANALYTICS_EVENT_TYPES.HASHTAG_CLICK,
          e.target.innerText,
          ANALYTICS_ITEM_NAMES.HASHTAG
        );
        history.push(target);
      }
    }
  };

  const postActionsPopover = () => (
    <Popover>
      {post.employee.employee_id !== employeeData.employee_id && (
        <>
          <Popover.Content>
            <div className="common-cursor-pointer" onClick={onPressReportHandler}>
              Report
            </div>
          </Popover.Content>
        </>
      )}
      {post.employee.employee_id === employeeData.employee_id && (
        <>
          <Popover.Content>
            <div className="common-cursor-pointer" onClick={onPressEditHandler}>
              Edit
            </div>
          </Popover.Content>
          <Divider />
        </>
      )}
      {post.employee.employee_id === employeeData.employee_id && (
        <>
          <Popover.Content>
            <div className="common-cursor-pointer" onClick={onPressDeleteHandler}>
              Delete
            </div>
          </Popover.Content>
        </>
      )}
    </Popover>
  );

  const reportDialog = () => {
    return (
      <div>
        {products.map((item, index) => {
          return (
            <div key={`${index}`}>
              <div key={item.id} className="form-check ml-1 mt-1">
                <input
                  type="radio"
                  className="form-check-input common-cursor-pointer"
                  checked={radioSelected && item.id === radioSelected.id ? true : false}
                  onChange={() => {
                    setRadioSelected(item);
                  }}
                />
                <label className="form-check-label communities-report">{item.name}</label>
              </div>
            </div>
          );
        })}
        <CustomButton
          buttonStyle="communities-button-style mt-3 mr-0"
          onClick={() => reportPostApi(radioSelected.name)}
          showLoader={progressLoadingState.isProgressLoading}>
          SUBMIT
        </CustomButton>
      </div>
    );
  };

  const callDeleteApi = useCallback(async () => {
    try {
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: true,
        })
      );
      const apiResponse = await apiRequest(
        GET_WALL_BY_ID + post.post_id + '/deletepost',
        REQUEST_TYPE.POST,
        {}
      );
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: false,
        })
      );
      if (Object.keys(apiResponse).length > 0) {
        if (apiResponse.response.status === true) {
          const message = apiResponse.response.data.message;
          if (message) {
            setDeleteDialog(false);
            showToast(message);
            history.goBack();
          }
        }
      }
    } catch (err) {
      handleError(
        err,
        {},
        GET_WALL_BY_ID + post.post_id + '/deletepost',
        NAVIGATION_ROUTES.THE_WALL_POST_DETAIL
      );
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: false,
        })
      );
    }
  }, [dispatch, history, post.post_id]);

  const renderDeletePost = () => {
    return (
      <div>
        <div className="text-center communities-dialog-title">OOPS!</div>
        <div className="text-center communities-dialog-subtitle mt-3">{MESSAGES.DELETE_POST}</div>
        <div className="d-flex justify-content-center mt-3">
          <div
            className="d-flex justify-content-center align-items-center mr-4"
            onClick={() => setDeleteDialog(false)}>
            <div className="common-cursor-pointer communities-cancel-community-button">CANCEL</div>
          </div>
          <CustomButton
            buttonStyle="common-custom-button-quaternary ml-4"
            showLoader={progressLoadingState}
            onClick={callDeleteApi}>
            YES, DELETE
          </CustomButton>
        </div>
      </div>
    );
  };

  const renderPdfView = () => {
    return post.encoded_url.map((pdfItem, index) => {
      return (
        <div onClick={(e) => e.stopPropagation()} key={index}>
          <a href={getSecuredPdfUrl(webUrl, pdfItem?.pdf_url)} target="_blank" rel="noreferrer">
            <div className="d-flex align-items-center mt-2">
              <img
                src={getFeatureComponentUrl(
                  accessibilityData,
                  ACCESSIBILITY_FEATURE_COMPONENTS.NEW_JOINEES_PDF_ATTACHMENT,
                  ACCESSIBILITY_IDENTIFIERS.NEW_JOINEES
                )}
                className="communities-pdf-icon"
              />
              <div className="communities-pdf-item-title ml-1">{pdfItem.label}</div>
            </div>
          </a>
        </div>
      );
    });
  };

  const renderNameImageView = () => {
    return (
      <div>
        <div className="communities-flex justify-content-between align-items-center mt-3">
          <div className="d-flex align-items-center">
            <UserProfilePhoto
              imageBaseUrl={post.employee.profile_picture.base_url}
              imagePath={post.employee.profile_picture.image_path}
              imageClass="common-user-profile-photo-xs"
              employeeId={post.employee.employee_id}
            />
            <div className="d-flex align-items-center common-cursor-pointer">
              <p className="communities-post-employee-name ml-3 mb-0">
                {post.employee.first_name + ' ' + post.employee.last_name}
              </p>
              {!!post.employee.pronouns && (
                <p className="communities-post-employee-pronoun ml-1 mb-0">
                  {'(' + post.employee.pronouns + ')'}
                </p>
              )}
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-center">
            <div className="communities-date-text">
              {isToday(post.published_on)
                ? formatTimeAMPM(post.published_on)
                : formatDateDDMonthYYYY(post.published_on)}
            </div>
            <div className="d-flex ml-3">
              <OverlayTrigger
                rootClose
                trigger="click"
                placement="bottom"
                overlay={postActionsPopover()}>
                <img
                  src={getFeatureComponentUrl(
                    accessibilityData,
                    ACCESSIBILITY_FEATURE_COMPONENTS.COMMUNITIES_MORE_OPTIONS,
                    ACCESSIBILITY_IDENTIFIERS.COMMUNITIES
                  )}
                  className="header-icon mr-0"
                />
              </OverlayTrigger>
            </div>
          </div>
        </div>
        {post.title && <div className="mt-2 communities-title">{post.title}</div>}
        <HtmlView
          onClick={handleHtmlClicks}
          htmlStyle="mt-2 communities-description text-break"
          html={post.description}
        />
        {renderPdfView()}
      </div>
    );
  };

  const renderImages = () => {
    return (
      <Carousel
        className="communities-post-carousel-container mt-2"
        activeIndex={currentActiveSlideIndex}
        nextIcon={
          post.images.length > 1 && (
            <span aria-hidden="true" className="carousel-control-next-icon" />
          )
        }
        prevIcon={
          post.images.length > 1 && (
            <span aria-hidden="true" className="carousel-control-prev-icon" />
          )
        }
        onSelect={(selectedIndex) => setCurrentActiveSlideIndex(selectedIndex)}>
        {post.images.map((imageItem, index) => {
          return (
            <Carousel.Item key={`${index}`}>
              <div className="d-flex justify-content-center">
                <img
                  className="communities-post-carousel-image"
                  src={getUrlWithSpecificRendition(
                    imageItem.base_url,
                    SCREEN_DENSITY_QUALIFIERS.XXXHDPI,
                    imageItem.filename
                  )}
                />
              </div>
            </Carousel.Item>
          );
        })}
      </Carousel>
    );
  };

  const renderVideoLinks = () => {
    return (
      <div>
        {post.videos && post.videos.length > 0 && (
          <div className="communities-url mt-2">Video URL:</div>
        )}
        {post.videos &&
          post.videos.length > 0 &&
          post.videos.map((item, index) => {
            return (
              <div key={`${index}`}>
                <a
                  className="community-link-text"
                  target="_blank"
                  rel="noreferrer"
                  href={item.url}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}>
                  {item.url}
                </a>
              </div>
            );
          })}
      </div>
    );
  };

  const renderLinks = () => {
    return (
      <div>
        {post.links && post.links.length > 0 && (
          <div className="communities-url mt-2">Website URL:</div>
        )}
        {post.links &&
          post.links.length > 0 &&
          post.links.map((item, index) => {
            return (
              <div className="text-truncate" key={`${index}`}>
                <a
                  className="community-link-text"
                  target="_blank"
                  rel="noreferrer"
                  href={item.url}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}>
                  {item.url}
                </a>
              </div>
            );
          })}
      </div>
    );
  };

  const renderLikeCommentView = () => {
    return (
      <div className="d-flex align-items-center mt-1">
        {showLikeButton && (
          <>
            {post.liked ? (
              <div onClick={() => onPressLikeButton()}>
                <img
                  src={getHeaderComponentUrl(
                    accessibilityData,
                    ACCESSIBILITY_FEATURE_COMPONENTS.COMMUNITIES_ACTIVE_LIKE,
                    ACCESSIBILITY_IDENTIFIERS.COMMUNITIES
                  )}
                  className="communities-normal-icon"
                />
              </div>
            ) : (
              <div onClick={() => onPressLikeButton()}>
                <img
                  src={getHeaderComponentUrl(
                    accessibilityData,
                    ACCESSIBILITY_FEATURE_COMPONENTS.COMMUNITIES_INACTIVE_LIKE,
                    ACCESSIBILITY_IDENTIFIERS.COMMUNITIES
                  )}
                  className="communities-normal-icon common-cursor-pointer"
                />
              </div>
            )}
            {post.like_count > 0 ? (
              <div
                className="ml-2 communities-count common-cursor-pointer"
                onClick={() => {
                  history.push(NAVIGATION_ROUTES.LIKED_PEOPLE, {
                    referId: post.post_id,
                    parentType: FEATURE_TYPE.WALL,
                  });
                }}>
                {post.like_count}
              </div>
            ) : (
              <div className="ml-2 communities-count common-cursor-pointer">0</div>
            )}
          </>
        )}
        {showCommentButton && (
          <>
            <div
              className={
                showLikeButton
                  ? 'communities-flex ml-3 mr-3 communities-width common-cursor-pointer'
                  : 'communities-flex mr-3 communities-width common-cursor-pointer'
              }
              onClick={() => {
                history.push(NAVIGATION_ROUTES.COMMENTS, {
                  referId: post.post_id,
                  likeType: FEATURE_TYPE.WALL,
                  fromRoute: NAVIGATION_ROUTES.THE_WALL_POST_DETAIL,
                });
              }}>
              <img
                src={getFeatureComponentUrl(
                  accessibilityData,
                  ACCESSIBILITY_FEATURE_COMPONENTS.COMMUNITIES_POST_COMMENT,
                  ACCESSIBILITY_IDENTIFIERS.COMMUNITIES
                )}
                className="communities-normal-icon mt-1"
              />
              <div className="ml-2 mt-1 communities-count">
                {post.comment_count > 0 ? post.comment_count : '0'}
              </div>
            </div>
          </>
        )}
      </div>
    );
  };

  const renderTaggedView = () => {
    return (
      <div>
        {post.tags.length > 0 && (
          <div
            className="common-cursor-pointer d-flex align-items-center"
            onClick={() => {
              logEvent(
                ANALYTICS_EVENT_TYPES.THE_WALL_VIEW_POST_TAGGED_PEOPLE_CLICK,
                post.post_id.toString(),
                ANALYTICS_ITEM_NAMES.THE_WALL
              );
              history.push(NAVIGATION_ROUTES.THE_WALL_TAGGED_PEOPLE, {
                employees: post.tags,
              });
            }}>
            <div className="d-flex align-items-center">
              {post.tags.length >= 3 ? (
                <div className="d-flex align-items-center">
                  <div className="communities-image-first position-relative ">
                    {post.tags.length >= 1 && (
                      <UserProfilePhoto
                        imageBaseUrl={post.tags[0].profile_picture.base_url}
                        imagePath={post.tags[0].profile_picture.image_path}
                        imageClass="common-user-profile-photo-xs"
                        employeeId={post.tags[0].employee_id}
                      />
                    )}
                  </div>
                  <div className="communities-image-second position-relative ">
                    {post.tags.length >= 2 && (
                      <UserProfilePhoto
                        imageBaseUrl={post.tags[1].profile_picture.base_url}
                        imagePath={post.tags[1].profile_picture.image_path}
                        imageClass="common-user-profile-photo-xs"
                        employeeId={post.tags[1].employee_id}
                      />
                    )}
                  </div>
                  {post.tags.length >= 3 && (
                    <UserProfilePhoto
                      imageBaseUrl={post.tags[2].profile_picture.base_url}
                      imagePath={post.tags[2].profile_picture.image_path}
                      imageClass="common-user-profile-photo-xs"
                      employeeId={post.tags[2].employee_id}
                    />
                  )}
                </div>
              ) : post.tags.length >= 2 ? (
                <div className="d-flex align-items-center">
                  <div className="communities-image-first position-relative ">
                    {post.tags.length >= 1 && (
                      <UserProfilePhoto
                        imageBaseUrl={post.tags[0].profile_picture.base_url}
                        imagePath={post.tags[0].profile_picture.image_path}
                        imageClass="common-user-profile-photo-xs"
                        employeeId={post.tags[0].employee_id}
                      />
                    )}
                  </div>
                  <div>
                    {post.tags.length >= 2 && (
                      <UserProfilePhoto
                        imageBaseUrl={post.tags[1].profile_picture.base_url}
                        imagePath={post.tags[1].profile_picture.image_path}
                        imageClass="common-user-profile-photo-xs"
                        employeeId={post.tags[1].employee_id}
                      />
                    )}
                  </div>
                </div>
              ) : (
                <div>
                  {post.tags.length >= 1 && (
                    <UserProfilePhoto
                      imageBaseUrl={post.tags[0].profile_picture.base_url}
                      imagePath={post.tags[0].profile_picture.image_path}
                      imageClass="common-user-profile-photo-xs"
                      employeeId={post.tags[0].employee_id}
                    />
                  )}
                </div>
              )}
            </div>
            {post.tags.length > 3 && (
              <div className="communities-tag-count-text ml-2">{`+${post.tags.length - 3}`}</div>
            )}
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="container">
      <SectionHeader identifier={ACCESSIBILITY_IDENTIFIERS.THE_WALL} />
      <BackButton />
      <Modal
        show={showReportDialog}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => setShowReportDialog(false)}>
        <Modal.Body>{reportDialog()}</Modal.Body>
      </Modal>
      <Modal
        show={deleteDialog}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => setDeleteDialog(false)}>
        <Modal.Body>{renderDeletePost()}</Modal.Body>
      </Modal>
      {post && Object.keys(post).length > 0 && (
        <div className="py-3 common-container-md mx-auto">
          <div className="border p-3">
            <div>
              {isTheWallNewPostDialogVisible && (
                <TheWallNewPostDialog
                  post={post}
                  isTheWallNewPostDialogVisible={isTheWallNewPostDialogVisible}
                  setIsTheWallNewPostDialogVisible={setIsTheWallNewPostDialogVisible}
                />
              )}
              {renderNameImageView()}
              {renderImages()}
              {renderVideoLinks()}
              {renderLinks()}
              <Divider style="mt-2" />
              <div className="mt-2 communities-flex justify-content-between">
                {renderLikeCommentView()}
                {renderTaggedView()}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TheWallPostDetailScreen;
