import React, { useCallback, useEffect, useState } from 'react';
import UserProfilePhoto from '../../common/ui/user_profile_photo/UserProfilePhoto';
import { getHeaderComponentUrl } from '../../utils/accessibility';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  FEATURE_TYPE,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
} from '../../common/constants/AppConstants';
import Divider from '../../common/ui/divider/Divider';
import HtmlView from '../../common/ui/html_view/HTMLView';
import { formatDateDDMonthYYYY, getAwardIcon } from '../../utils/common';
import { useDispatch, useSelector } from 'react-redux';
import { saveProgressLoadingState } from '../../state/UIState';
import { apiRequest, handleError } from '../../services/Service';
import { GET_CHEERS_DETAIL_BY_ID, SUBMIT_LIKE_COMMENT_CHEERS } from '../../services/ApiUrls';
import { logEvent } from '../../utils/FirebaseAnalyticsUtils';
import { selectAccessibilityData } from '../../state/AccessibilityData';
import { useHistory } from 'react-router';
import SectionHeader from '../../common/ui/section_header/SectionHeader';
import BackButton from '../../common/ui/common_back_button/BackButton';

const CheersDetailScreen = ({ location }) => {
  const history = useHistory();
  const [item, setItem] = useState({});
  const dispatch = useDispatch();
  const accessibilityData = useSelector(selectAccessibilityData);
  const [iconName, setIconName] = useState();
  const [error, setError] = useState(false);
  const param = location.state;

  const callCheersCentralApi = useCallback(async () => {
    setError(false);
    try {
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: true,
        })
      );
      const apiResponse = await apiRequest(
        GET_CHEERS_DETAIL_BY_ID + param.id + '/award',
        REQUEST_TYPE.GET
      );
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
      if (Object.keys(apiResponse).length > 0) {
        if (apiResponse.response.status === true) {
          const data = apiResponse.response.data.feeds;
          setItem(data);
        }
      }
    } catch (err) {
      handleError(
        err,
        {},
        GET_CHEERS_DETAIL_BY_ID + param.id + '/award',
        NAVIGATION_ROUTES.CHEERS_DETAIL
      );
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
      setError(true);
    }
  }, [dispatch, param.id]);

  const callCheersLeaderboardApi = useCallback(async () => {
    setError(false);
    const get_params = {
      type: param.apiType,
    };
    try {
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: true,
        })
      );
      const apiResponse = await apiRequest(
        GET_CHEERS_DETAIL_BY_ID + param.id + '/leaderboard',
        REQUEST_TYPE.GET,
        get_params
      );
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
      if (Object.keys(apiResponse).length > 0) {
        if (apiResponse.response.status === true) {
          const data = apiResponse.response.data.leaderboard;
          setItem(data);
        }
      }
    } catch (err) {
      handleError(
        err,
        get_params,
        GET_CHEERS_DETAIL_BY_ID + param.id + '/leaderboard',
        NAVIGATION_ROUTES.CHEERS_DETAIL
      );
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
      setError(true);
    }
  }, [dispatch, param]);

  useEffect(() => {
    if (param.type === 'reward') {
      callCheersCentralApi();
    } else {
      callCheersLeaderboardApi();
    }
  }, [callCheersCentralApi, callCheersLeaderboardApi, param.type]);

  const callLikeApi = async (cheersEmployeeAwardId) => {
    const params = new URLSearchParams();
    params.append('type', FEATURE_TYPE.CHEERS);
    try {
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: true,
          progressMessage: 'Updating Like Count...',
        })
      );
      const apiResponse = await apiRequest(
        SUBMIT_LIKE_COMMENT_CHEERS + cheersEmployeeAwardId + '/like',
        REQUEST_TYPE.POST,
        params
      );
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
      if (Object.keys(apiResponse).length > 0) {
        if (apiResponse.response.status === true) {
          updateLikeCount(cheersEmployeeAwardId);
        }
      }
    } catch (err) {
      handleError(
        err,
        params,
        SUBMIT_LIKE_COMMENT_CHEERS + cheersEmployeeAwardId + '/like',
        NAVIGATION_ROUTES.CHEERS_DETAIL
      );
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
    }
  };

  useEffect(() => {
    if (item.type === 'reward') {
      setIconName(getAwardIcon(item.award_details.icon_name));
    }
  }, [item]);

  const updateLikeCount = () => {
    setItem((prevItem) => {
      const newItem = { ...prevItem };
      const oldCount = newItem.likes.count;
      const isLiked = newItem.likes.is_liked;
      newItem.likes.count = isLiked ? oldCount - 1 : oldCount + 1;
      newItem.likes.is_liked = !isLiked;
      return newItem;
    });
  };

  const updateLikeCountRN = () => {
    const newItem = { ...item };
    const oldCount = newItem?.likes.count;
    const isLiked = newItem?.likes.is_liked;
    newItem.likes.count = isLiked ? oldCount - 1 : oldCount + 1;
    newItem.likes.is_liked = !isLiked;
    setItem(newItem);
  };

  const callLikeApiRN = async (topperId, type) => {
    const params = new URLSearchParams();
    params.append('type', type);
    try {
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: true,
          progressMessage: 'Updating Like Count...',
        })
      );
      const apiResponse = await apiRequest(
        SUBMIT_LIKE_COMMENT_CHEERS + topperId + '/like',
        REQUEST_TYPE.POST,
        params
      );
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
      if (Object.keys(apiResponse).length > 0) {
        if (apiResponse.response.status === true) {
          updateLikeCountRN(topperId);
        }
      }
    } catch (err) {
      handleError(
        err,
        params,
        SUBMIT_LIKE_COMMENT_CHEERS + topperId + '/like',
        NAVIGATION_ROUTES.CHEERS_DETAIL
      );
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
    }
  };

  const onPressLike = (item) => {
    logEvent(
      ANALYTICS_EVENT_TYPES.NOMINATIONS_RECIPIENT_LIKE,
      item.topper_id,
      ANALYTICS_ITEM_NAMES.LIKE
    );
    item.type === 'recipient'
      ? callLikeApiRN(item.topper_id, FEATURE_TYPE.CHEERS_RECIPIENT)
      : callLikeApiRN(item.topper_id, FEATURE_TYPE.CHEERS_NOMINATORS);
  };

  const onPressLikeButton = (item) => {
    logEvent(
      ANALYTICS_EVENT_TYPES.REWARD_CENTRAL_LIKE,
      item.cheer_employee_award_id,
      ANALYTICS_ITEM_NAMES.LIKE
    );
    callLikeApi(item.cheer_employee_award_id);
  };

  const onPressCommentButton = (item) => {
    logEvent(
      ANALYTICS_EVENT_TYPES.REWARD_CENTRAL_COMMENTS,
      item.cheer_employee_award_id,
      ANALYTICS_ITEM_NAMES.COMMENT
    );
    history.push(NAVIGATION_ROUTES.COMMENTS, {
      referId: item.cheer_employee_award_id,
      likeType: FEATURE_TYPE.CHEERS,
      fromRoute: NAVIGATION_ROUTES.CHEERS_SCREEN,
      type: 'reward',
    });
  };

  const onPressComment = (item) => {
    logEvent(
      ANALYTICS_EVENT_TYPES.NOMINATIONS_RECIPIENT_COMMENT,
      item.topper_id,
      ANALYTICS_ITEM_NAMES.COMMENT
    );
    history.push(NAVIGATION_ROUTES.COMMENTS, {
      referId: item.topper_id,
      likeType:
        item.type === 'recipient' ? FEATURE_TYPE.CHEERS_RECIPIENT : FEATURE_TYPE.CHEERS_NOMINATORS,
      fromRoute: NAVIGATION_ROUTES.CHEERS_SCREEN,
      type: 'nominatorRecipient',
    });
  };

  const onPressOthers = (nominationsList) => {
    history.push(NAVIGATION_ROUTES.TAGGED_PEOPLE_SCREEN, {
      nominationsList: nominationsList,
      from: 'rewardCentral',
      title: 'Nominated by',
    });
  };

  const renderReward = () => {
    return (
      Object.keys(item).length > 0 && (
        <div className="material-card p-3 mb-3">
          <div className="cheers-date-view-style">
            <UserProfilePhoto
              imageBaseUrl={item.employee.profile_picture.base_url}
              imagePath={item.employee.profile_picture.image_path}
              imageClass="common-user-profile-photo-lg"
              employeeId={item.employee.employee_id}
            />
            <div className="ml-3 cheers-outer-view cheers-center-view">
              <div>
                <div className="cheers-emp-name">{`${item.employee.first_name} ${item.employee.last_name}`}</div>
                <div className="cheers-emp-position">{item.employee.position_title}</div>
                <div className="cheers-desc-text">{item.award_details.description}</div>
              </div>
            </div>
            <div className="d-block">
              <div className="cheers-like-comment">
                {item.likes.is_liked ? (
                  <div onClick={() => onPressLikeButton(item)}>
                    <img
                      src={getHeaderComponentUrl(
                        accessibilityData,
                        ACCESSIBILITY_FEATURE_COMPONENTS.CHEERS_ACTIVE_LIKE,
                        ACCESSIBILITY_IDENTIFIERS.CHEERS
                      )}
                      className="cheers-normal-icon ml-3 common-cursor-pointer"
                    />
                  </div>
                ) : (
                  <div onClick={() => onPressLikeButton(item)}>
                    <img
                      src={getHeaderComponentUrl(
                        accessibilityData,
                        ACCESSIBILITY_FEATURE_COMPONENTS.CHEERS_INACTIVE_LIKE,
                        ACCESSIBILITY_IDENTIFIERS.CHEERS
                      )}
                      className="cheers-normal-icon ml-3 common-cursor-pointer"
                    />
                  </div>
                )}
                <div
                  className="ml-3 likesCountText common-cursor-pointer"
                  onClick={() => {
                    history.push(NAVIGATION_ROUTES.LIKED_PEOPLE, {
                      referId: item.cheer_employee_award_id,
                      parentType: FEATURE_TYPE.CHEERS,
                    });
                  }}>
                  {item.likes.count}
                </div>
                <div onClick={() => onPressCommentButton(item)}>
                  <img
                    src={getHeaderComponentUrl(
                      accessibilityData,
                      ACCESSIBILITY_FEATURE_COMPONENTS.CHEERS_POST_COMMENT,
                      ACCESSIBILITY_IDENTIFIERS.CHEERS
                    )}
                    className="cheers-normal-icon ml-3 common-cursor-pointer"
                  />
                </div>
                <div className="ml-3 commentCountText">{item.comment_count}</div>
              </div>
            </div>
          </div>
          <Divider style="mt-2" />
          <div>
            <div className="cheers-select-option-text cheers-select-padding">
              Why is this award for?
            </div>
            <div className="mt-2 mb-2 cheers-citation-text">
              <HtmlView html={item.citation} />
            </div>
            {item.citation_text && <div className="cheers-select-option-text">Citation</div>}
            {item.citation_text && (
              <p className="mt-2 mb-2 cheers-citation-text">
                <HtmlView html={item.citation_text} />
              </p>
            )}
            <Divider />
            <div className="cheers-date-view-style mt-2 mb-2">
              <div className="cheers-icon-text-view cheers-outer-view">
                <img
                  src={getHeaderComponentUrl(
                    accessibilityData,
                    iconName,
                    ACCESSIBILITY_IDENTIFIERS.CHEERS
                  )}
                  className="cheers-normal-icon"
                />
                <div className="ml-2 cheers-award-title-text">{item.award_details.title}</div>
              </div>
            </div>
            {item.award_details.award_type === 'Monetary' && (
              <div className="mb-2 cheers-reward-points-second-text">{`(${item.points} Points)`}</div>
            )}
            <div className="cheers-date-view-style justify-content-between">
              <div className="cheers-date-view-style">
                {item.nominations.length > 0 && (
                  <div
                    onClick={() => {
                      onPressOthers(item.nominations);
                    }}
                    className="cheers-nom-user-text common-cursor-pointer">
                    {`${item.nominations[0].nominated_by.first_name} ${item.nominations[0].nominated_by.last_name}`}
                  </div>
                )}
                {item.nominations.length > 1 && (
                  <div
                    onClick={() => {
                      onPressOthers(item.nominations);
                    }}
                    className="cheers-nom-user-text common-cursor-pointer">
                    {item.nominations.length === 2
                      ? ` + (${item.nominations.length - 1}) Other`
                      : ` + (${item.nominations.length - 1}) Others`}
                  </div>
                )}
                {item.nominations.length === 0 && (
                  <div className="cheers-nom-user-text">{'Admin'}</div>
                )}
                <div className="cheers-nom-user-text">{`, ${formatDateDDMonthYYYY(
                  item.created_on
                )}`}</div>
              </div>
            </div>
          </div>
        </div>
      )
    );
  };

  const renderRecipientNominators = () => {
    return (
      Object.keys(item).length > 0 && (
        <div className="material-card p-3 mb-3 cheers-nom-rec-detail-view">
          <div className="cheers-date-view-style">
            <UserProfilePhoto
              imageBaseUrl={item.employee.profile_picture.base_url}
              imagePath={item.employee.profile_picture.image_path}
              imageClass="common-user-profile-photo-lg"
              employeeId={item.employee.employee_id}
            />
            <div className="ml-3 cheers-center-view">
              <div className="cheers-emp-name">{`${item.employee.first_name} ${item.employee.last_name}`}</div>
              <div className="cheers-emp-position">{item.employee.position_title}</div>
              <div className="cheers-desc-text">
                {item.type === 'nominator'
                  ? `${item.nomination_count} Nominations`
                  : `${item.total_points} Points`}
              </div>
              <div className="cheers-date-view-style mt-2 cheers-center-view d-flex flex-row align-items-end justify-content-end">
                <div className="cheers-date-view-style">
                  {item.likes.is_liked ? (
                    <div onClick={() => onPressLike(item)}>
                      <img
                        src={getHeaderComponentUrl(
                          accessibilityData,
                          ACCESSIBILITY_FEATURE_COMPONENTS.CHEERS_ACTIVE_LIKE,
                          ACCESSIBILITY_IDENTIFIERS.CHEERS
                        )}
                        className="cheers-normal-icon ml-3 common-cursor-pointer"
                      />
                    </div>
                  ) : (
                    <div onClick={() => onPressLike(item)}>
                      <img
                        src={getHeaderComponentUrl(
                          accessibilityData,
                          ACCESSIBILITY_FEATURE_COMPONENTS.CHEERS_INACTIVE_LIKE,
                          ACCESSIBILITY_IDENTIFIERS.CHEERS
                        )}
                        className="cheers-normal-icon ml-3 common-cursor-pointer"
                      />
                    </div>
                  )}
                  <div
                    className="ml-3 likesCountText common-cursor-pointer"
                    onClick={() => {
                      history.push(NAVIGATION_ROUTES.LIKED_PEOPLE, {
                        referId: item.topper_id,
                        parentType:
                          item.type === 'recipient'
                            ? FEATURE_TYPE.CHEERS_RECIPIENT
                            : FEATURE_TYPE.CHEERS_NOMINATORS,
                      });
                    }}>
                    {item.likes.count}
                  </div>
                  <div onClick={() => onPressComment(item)}>
                    <img
                      src={getHeaderComponentUrl(
                        accessibilityData,
                        ACCESSIBILITY_FEATURE_COMPONENTS.CHEERS_POST_COMMENT,
                        ACCESSIBILITY_IDENTIFIERS.CHEERS
                      )}
                      className="cheers-normal-icon ml-3 common-cursor-pointer"
                    />
                  </div>
                  <div className="ml-3 commentCountText">{item.comment_count}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    );
  };

  return (
    <div className="container">
      <SectionHeader identifier={ACCESSIBILITY_IDENTIFIERS.CHEERS} />
      <BackButton />
      <div className="main-content-container">
        {param.type === 'reward' ? renderReward() : renderRecipientNominators()}
      </div>
    </div>
  );
};

export default CheersDetailScreen;
