import React, { useCallback, useEffect, useState } from 'react';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  ANALYTICS_SCREEN_NAMES,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
} from '../../common/constants/AppConstants';
import { useDispatch, useSelector } from 'react-redux';
import SectionHeader from '../../common/ui/section_header/SectionHeader';
import { apiRequestUser } from '../../services/Service';
import { saveProgressLoadingState } from '../../state/UIState';
import BackButtonStore from '../../common/ui/common_back_button/BackButtonStore';
import { useHistory } from 'react-router';
import CustomButton from '../../common/ui/custom_button/CustomButton';
import { getFeatureComponentUrl } from '../../utils/accessibility';
import { selectAccessibilityData } from '../../state/AccessibilityData';
import { saveCartItemsCount, selectTempAddress } from '../../state/EStoreData';
import { selectEStoreConfig } from '../../state/MasterData';
import PriceInformationTable from './components/PriceInformationTable';
import DeliveryNote from './components/DeliveryNote';
import { logEvent, trackScreen } from '../../utils/FirebaseAnalyticsUtils';
import { CUSTOMERS_ME } from '../../services/ApiUrls';
import { getOrderId } from '../../utils/common';

const OrderSuccessScreen = ({ location }) => {
  const dispatch = useDispatch();
  const [defaultAddress, setDefaultAddress] = useState({});
  const accessibilityData = useSelector(selectAccessibilityData);
  const estoreConfig = useSelector(selectEStoreConfig);
  const tempAddress = useSelector(selectTempAddress);
  const history = useHistory();
  const { orderId, carrierCode } = location.state;

  useEffect(() => {
    trackScreen(
      ANALYTICS_SCREEN_NAMES.MICRO_STORE_ORDER_SUCCESS_SCREEN,
      NAVIGATION_ROUTES.MI_STORE_ORDER_SUCCESS
    );
  }, []);

  const fetchContent = useCallback(async () => {
    dispatch(
      saveProgressLoadingState({
        isProgressLoading: true,
      })
    );
    try {
      const apiResponse = await apiRequestUser(CUSTOMERS_ME, REQUEST_TYPE.GET);
      if (Object.keys(apiResponse.response).length) {
        apiResponse.response?.addresses.map((address) => {
          if (address.default_shipping) {
            setDefaultAddress(address);
          }
        });
      }
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: false,
        })
      );
    } catch (err) {
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: false,
        })
      );
    }
  }, [dispatch]);

  const handleContinue = () => {
    logEvent(
      ANALYTICS_EVENT_TYPES.ESTORE_ORDER_SUCCESS_SCREEN_CONTINUE,
      '',
      ANALYTICS_ITEM_NAMES.ORDER_SUCCESS_CONTUNUE
    );
    dispatch(saveCartItemsCount(0));
    history.replace(NAVIGATION_ROUTES.MICRO_STORE_MAIN_SCREEN);
  };

  useEffect(() => {
    fetchContent();
  }, [fetchContent]);

  const getAddressView = (address) => {
    return (
      <div className="estore-order-success-address-block">
        <div className="estore-bold-text">{`${address.firstname} ${address.lastname}`}</div>
        <div className="estore-regular-text">{address.street}</div>
        <div className="estore-regular-text">{`${address.city}-${address.postcode}`}</div>
        <div className="estore-regular-text">{address.region?.region}</div>
        <div className="d-flex estore-regular-text">
          Mobile:
          <div className="ml-1 estore-bold-text">{address.telephone}</div>
        </div>
      </div>
    );
  };

  return (
    <div className="container">
      <SectionHeader identifier={ACCESSIBILITY_IDENTIFIERS.MICRO_STORE} />
      <BackButtonStore
        backTitle={'ORDER CONFIRMATION'}
        navigateBackFuncHandler={() => {
          dispatch(saveCartItemsCount(0));
          history.goBack();
          history.goBack();
        }}
      />
      <div className="e-store-cart-block">
        <div className="estore-items-container mb-2">
          <div className="pb-2 mb-1 d-flex flex-row">
            <img
              src={getFeatureComponentUrl(
                accessibilityData,
                ACCESSIBILITY_FEATURE_COMPONENTS.STORE_ORDER_SUCCESS,
                ACCESSIBILITY_IDENTIFIERS.MICRO_STORE
              )}
              className="estore-order-success-icon"
            />
            <div className="store-order-success-message">Order placed, thank you!</div>
          </div>
          <div className="estore-regular-text">Confirmation will be sent to your email.</div>
        </div>
        <div className="estore-confirm-address-note-block">
          <div className="estore-confirm-address-container">
            <div className="estore-order-success-header pb-2">
              <div className="estore-cart-item-price-text-bold">SHIPPING ADDRESS</div>
              <div className="estore-cart-item-price-text-bold">{`ORDER ID: ${getOrderId(
                orderId
              )}`}</div>
            </div>
            {getAddressView(Object.keys(tempAddress).length > 0 ? tempAddress : defaultAddress)}
            <div className="estore-order-success-address-block pt-1 pb-2">
              <div className="estore-bold-text">DELIVERY ESTIMATES</div>
            </div>
            <div className="estore-regular-text mt-2">
              {carrierCode === 'tablerate'
                ? estoreConfig.estimated_delivery_banglore
                : estoreConfig.estimated_delivery}
            </div>
          </div>
          {carrierCode === 'tablerate' && <DeliveryNote />}
        </div>
        <PriceInformationTable />
        <div className="estore-confirm-address-button">
          <div className="estore-cart-place-order-button w-50">
            <CustomButton buttonStyle="estore-add-to-cart-button" onClick={handleContinue}>
              KEEP SHOPPING
            </CustomButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderSuccessScreen;
