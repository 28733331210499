import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  ANALYTICS_SCREEN_NAMES,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
} from '../../common/constants/AppConstants';
import { apiRequest, handleError } from '../../services/Service';
import { GET_POLICY_BY_ID } from '../../services/ApiUrls';
import { saveProgressLoadingState, saveSearchText } from '../../state/UIState';
import HtmlView from '../../common/ui/html_view/HTMLView';
import CustomButton from '../../common/ui/custom_button/CustomButton';
import { useParams } from 'react-router-dom';
import { selectConfigurations, selectWebBaseUrl } from '../../state/MasterData';
import SectionHeader from '../../common/ui/section_header/SectionHeader';
import { logEvent, trackScreen } from '../../utils/FirebaseAnalyticsUtils';
import BackButton from '../../common/ui/common_back_button/BackButton';
import { generatev4UniqueID, getSecuredPdfUrl } from '../../utils/common';
import { selectEmployeeData } from '../../state/EmployeeData';
import AskMlChatWidget from '../ask_ml/AskMlChatWidget';
import { getFeatureComponentUrl } from '../../utils/accessibility';
import { selectAccessibilityData } from '../../state/AccessibilityData';
import { selectAccessData } from '../../state/UserAccessData';

const PolicyDetailScreen = ({ history }) => {
  const dispatch = useDispatch();
  const { policyId } = useParams();
  const configurations = useSelector(selectConfigurations);
  const webUrl = useSelector(selectWebBaseUrl);
  const [policyData, setPolicyData] = useState({});
  const [showWidget, setShowWidget] = useState(false);
  const accessibilityData = useSelector(selectAccessibilityData);
  const employeeConfig = useSelector(selectEmployeeData);
  const accessData = useSelector(selectAccessData);
  const [showChatBot, setShowChatBot] = useState(true);
  const [showAskOrg, setShowAskOrg] = useState(true);
  const policiesAccessData = accessData?.data?.find((item) => item.feature_key === '_policies');
  const [uniqueUUID, setUniqueUUID] = useState('');

  useEffect(() => {
    const actions = policiesAccessData?.actions;
    setShowChatBot(actions?.includes('_chatbot'));
    setShowAskOrg(actions?.includes('_askorg'));
  }, [policiesAccessData?.actions]);

  useEffect(() => {
    trackScreen(
      ANALYTICS_SCREEN_NAMES.POLICY_DETAIL_SCREEN,
      NAVIGATION_ROUTES.POLICY_DETAIL_SCREEN
    );
  }, []);

  const toggleWidget = () => {
    setShowWidget(!showWidget);
  };

  const callPolicyApi = useCallback(async () => {
    try {
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: true,
          progressMessage: 'Fetching policy...',
        })
      );
      const apiResponse = await apiRequest(GET_POLICY_BY_ID + policyId, REQUEST_TYPE.GET, {});
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
      if (Object.keys(apiResponse).length > 0) {
        if (apiResponse?.response?.status === true) {
          setPolicyData(apiResponse?.response?.data?.policy ?? {});
        }
      }
    } catch (err) {
      handleError(err, {}, GET_POLICY_BY_ID + policyId, NAVIGATION_ROUTES.POLICY_DETAIL_SCREEN);
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
    }
  }, [policyId, dispatch]);

  useEffect(() => {
    if (policyId) {
      callPolicyApi();
    }
  }, [callPolicyApi, policyId]);

  const onPressAskMicroland = () => {
    const targetUrl = configurations._helpdesk_url;
    window.open(targetUrl, '_blank').focus();
  };

  const handleHtmlClicks = (e) => {
    const target = e.target.getAttribute('href');
    if (target) {
      if (target.startsWith('/') || target.startsWith('?')) {
        if (target.includes(NAVIGATION_ROUTES.EMPLOYEE_PROFILE_DETAIL)) {
          logEvent(
            ANALYTICS_EVENT_TYPES.USER_MENTION_CLICK,
            e?.target?.innerText,
            ANALYTICS_ITEM_NAMES.USER_MENTION
          );
          e.stopPropagation();
          e.preventDefault();
          history.push(target);
        } else {
          logEvent(
            ANALYTICS_EVENT_TYPES.HASHTAG_CLICK,
            e?.target?.innerText,
            ANALYTICS_ITEM_NAMES.HASHTAG
          );
          dispatch(saveSearchText(''));
          e.preventDefault();
          history.push(NAVIGATION_ROUTES.POLICY_SEARCH_SCREEN);
          history.push(target);
        }
      } else {
        e.stopPropagation();
        e.preventDefault();
        window.open(target, '_blank');
      }
    }
  };

  return (
    <div className="container">
      {employeeConfig?.gpt_visible == 1 && showChatBot && (
        <div
          className={
            !showWidget
              ? 'd-flex common-cursor-pointer chat-bot-floating-icon p-2 align-items-center justify-content-center'
              : 'd-flex common-cursor-pointer chat-bot-floating-close-icon p-2 align-items-center justify-content-center'
          }>
          <div
            className="position-relative flex-grow-1 d-flex justify-content-center align-items-center"
            onClick={() => {
              toggleWidget();
              if (!showWidget) {
                const id = generatev4UniqueID();
                setUniqueUUID(id);
              }
            }}>
            <img
              className={!showWidget ? 'ask-org-chat-bot-float-icon' : 'ask-org-close-float-icon'}
              src={
                !showWidget
                  ? getFeatureComponentUrl(
                      accessibilityData,
                      ACCESSIBILITY_FEATURE_COMPONENTS.CHAT_BOT,
                      ACCESSIBILITY_IDENTIFIERS.ASK_ORG
                    )
                  : 'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTYuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjUxMnB4IiBoZWlnaHQ9IjUxMnB4IiB2aWV3Qm94PSIwIDAgMzU3IDM1NyIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMzU3IDM1NzsiIHhtbDpzcGFjZT0icHJlc2VydmUiPgo8Zz4KCTxnIGlkPSJjbGVhciI+CgkJPHBvbHlnb24gcG9pbnRzPSIzNTcsMzUuNyAzMjEuMywwIDE3OC41LDE0Mi44IDM1LjcsMCAwLDM1LjcgMTQyLjgsMTc4LjUgMCwzMjEuMyAzNS43LDM1NyAxNzguNSwyMTQuMiAzMjEuMywzNTcgMzU3LDMyMS4zICAgICAyMTQuMiwxNzguNSAgICIgZmlsbD0iI0ZGRkZGRiIvPgoJPC9nPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+Cjwvc3ZnPgo='
              }
            />
          </div>
          {showWidget ? <AskMlChatWidget uniqueUUID={uniqueUUID} /> : null}
        </div>
      )}
      <SectionHeader identifier={ACCESSIBILITY_IDENTIFIERS.POLICIES} />
      <div>
        <div className="header-view justify-content-between">
          <BackButton isFullRow={false} />
          {showAskOrg && (
            <CustomButton buttonStyle="common-custom-button-primary" onClick={onPressAskMicroland}>
              Ask Microland
            </CustomButton>
          )}
        </div>
        <div className="policy-date-view-style mt-3">
          <div className="policy-category">{policyData?.category?.title ?? ''}</div>
        </div>
        <div className="policy-date-view-style mt-2">
          <div className="policy-details-title">{policyData?.title ?? ''}</div>
        </div>
        {policyData?.description && policyData?.content_load_type == 0 && (
          <HtmlView
            html={policyData?.description}
            onClick={handleHtmlClicks}
            htmlStyle="mt-3 policy-html"
          />
        )}
      </div>
      <div>
        {policyData?.content_load_type == 2 && policyData?.encoded_url && (
          <div className="iframe-container mt-2">
            <iframe src={getSecuredPdfUrl(webUrl, policyData?.encoded_url)} allowFullScreen />
          </div>
        )}
      </div>
    </div>
  );
};

export default PolicyDetailScreen;
