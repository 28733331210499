import React, { useEffect, useState, useCallback, useRef } from 'react';
import {
  ACCESSIBILITY_HEADER_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  ERROR_MESSAGES,
  NAVIGATION_ROUTES,
  OUR_CUSTOMERS_API_TYPES,
  OUR_CUSTOMERS_DATA_TYPES,
  OUR_CUSTOMERS_IDENTIFIERS,
  OUR_CUSTOMERS_LIKE_TYPES,
  REQUEST_TYPE,
  SEARCH_DEBOUNCE_WAIT_TIME,
} from '../../../common/constants/AppConstants';
import { apiRequest, handleError } from '../../../services/Service';
import { CUSTOMERS_BY_ID } from '../../../services/ApiUrls';
import LoadMoreBtn from '../../../common/ui/load_more_btn/LoadMoreBtn';
import OurCustomerListItem from '../components/OurCustomerListItem';
import { useHistory } from 'react-router';
import { logEvent } from '../../../utils/FirebaseAnalyticsUtils';
import ActivityLoader from '../../../common/ui/activity_loader/ActivityLoader';
import DefaultContentView from '../../../common/ui/default_content_view/DefaultContentView';
import SectionHeader from '../../../common/ui/section_header/SectionHeader';
import BackButton from '../../../common/ui/common_back_button/BackButton';
import { getHeaderComponentUrl } from '../../../utils/accessibility';
import { useDispatch, useSelector } from 'react-redux';
import { selectAccessibilityData } from '../../../state/AccessibilityData';
import AccoladesListItem from '../components/AccoladesListItem';
import { saveSearchText, selectSearchText } from '../../../state/UIState';
import debounce from 'lodash.debounce';

const OurCustomerKnowSearchScreen = ({ location }) => {
  const { type = '' } = location?.state || {};
  const title = type.toString().toUpperCase();
  const history = useHistory();
  const [customers, setCustomers] = useState([]);
  const [loadMoreFlag, setLoadMoreFlag] = useState(true);
  const [loading, setLoading] = useState(false);
  const searchData = useSelector(selectSearchText);
  const [searchText, setSearchText] = useState(searchData ?? '');
  const accessibilityData = useSelector(selectAccessibilityData);
  const [dataLength, setDataLength] = useState(0);
  const loadMoreFlagRef = useRef(true);
  const pageIdRef = useRef(1);
  const [isSearchEnabled, setIsSearchEnabled] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    logEvent(
      ANALYTICS_EVENT_TYPES.OUR_CUSTOMERS_TAB_VISIT,
      type,
      ANALYTICS_ITEM_NAMES.OUR_CUSTOMER
    );
  }, [type]);

  useEffect(() => {
    if (pageIdRef.current !== 1) {
      loadMoreFlagRef.current = false;
    }
  }, [dataLength]);

  const callOurCustomerApi = useCallback(
    async (query, pageId) => {
      const params = {
        page_id: pageId,
        query: query,
      };
      try {
        setLoading(true);
        const apiResponse = await apiRequest(CUSTOMERS_BY_ID + type, REQUEST_TYPE.GET, params);
        setLoading(false);
        if (Object.keys(apiResponse).length > 0) {
          if (apiResponse.response.status === true) {
            if (apiResponse.response.data) {
              if (pageIdRef.current === 1) {
                setCustomers([]);
              }
              const data = apiResponse.response.data;
              setDataLength(data.length);
              if (data.feeds.length > 0) {
                setCustomers((oldArray) => [...oldArray, ...data.feeds]);
                setLoadMoreFlag(true);
              } else {
                setLoadMoreFlag(false);
              }
            }
          }
        }
      } catch (err) {
        handleError(
          err,
          params,
          CUSTOMERS_BY_ID + type,
          NAVIGATION_ROUTES.OUR_CUSTOMER_KNOW_SEARCH_SCREEN
        );
        setLoading(false);
      }
    },
    [type]
  );

  const debouncedCallback = (event) => {
    if (event && event.target.value.length > 2) {
      dispatch(saveSearchText(event.target.value));
      pageIdRef.current = 1;
      loadMoreFlagRef.current = true;
      callOurCustomerApi(event.target.value, 1);
    }
    setSearchText(event.target.value);
  };

  const debounceInputHandler = useCallback(
    debounce(debouncedCallback, SEARCH_DEBOUNCE_WAIT_TIME),
    []
  );
  const onInputChange = (event) => {
    setSearchText(event.target.value);
    debounceInputHandler(event);
  };

  const onPressLoadMore = () => {
    pageIdRef.current = pageIdRef.current + 1;
    callOurCustomerApi(searchText, pageIdRef.current);
  };

  const onPressMore = (pictures, baseUrl) => {
    history.push(NAVIGATION_ROUTES.GALLERY_COMMON, {
      baseUrl: baseUrl,
      pictures: pictures.pictures,
    });
  };

  const onPress = (item) => {
    OUR_CUSTOMERS_DATA_TYPES[type] === 'research'
      ? history.push(NAVIGATION_ROUTES.OUR_CUSTOMER_RESEARCHERS_CONTENT, {
          customerId: item.id,
          dataType: OUR_CUSTOMERS_DATA_TYPES[type],
          parentName: title,
          apiType: OUR_CUSTOMERS_API_TYPES[type],
          type: type,
          likeType: OUR_CUSTOMERS_LIKE_TYPES[type],
        })
      : OUR_CUSTOMERS_DATA_TYPES[type] !== 'know'
      ? history.push(NAVIGATION_ROUTES.OUR_CUSTOMER_CONTENT, {
          customerId: item.id,
          dataType: OUR_CUSTOMERS_DATA_TYPES[type],
          parentName: title,
          apiType: OUR_CUSTOMERS_API_TYPES[type],
          type: type,
          likeType: OUR_CUSTOMERS_LIKE_TYPES[type],
        })
      : history.push(NAVIGATION_ROUTES.OUR_CUSTOMER_ALL_SCREEN, {
          customerId: item.id,
          dataType: OUR_CUSTOMERS_DATA_TYPES[type],
          parentName: title,
          apiType: OUR_CUSTOMERS_API_TYPES[type],
          type: type,
          likeType: OUR_CUSTOMERS_LIKE_TYPES[type],
          headerName: item?.name,
          item: item,
        });
  };
  const renderItem = (item, index) => {
    switch (type) {
      case OUR_CUSTOMERS_IDENTIFIERS.ACCOLADES:
        return <AccoladesListItem item={item} key={`${index}`} onPress={onPress} />;
      default:
        return (
          <OurCustomerListItem
            item={item}
            key={`${index}`}
            onPressMore={onPressMore}
            onPress={onPress}
          />
        );
    }
  };
  const callSubmitFunction = (event) => {
    event?.preventDefault();
    if (searchText !== '') {
      dispatch(saveSearchText(searchText));
      logEvent(
        ANALYTICS_EVENT_TYPES.MICROGIVE_PROJECT_SEARCH,
        searchText,
        ANALYTICS_ITEM_NAMES.SEARCH
      );
      setCustomers([]);
      callOurCustomerApi(searchText, 1);
    }
  };

  const onTextChange = (event) => {
    setSearchText(event.target.value);
  };

  const resetValue = () => {
    history.goBack();
  };

  const onClickSearchHandler = () => {
    setIsSearchEnabled(true);
  };

  useEffect(() => {
    if (searchText !== '') {
      callOurCustomerApi(searchText, 1);
    }
  }, [callOurCustomerApi, searchText]);

  useEffect(() => {
    if (location?.hash) {
      let hashtag = location?.hash || '';
      setSearchText(hashtag);
      callOurCustomerApi(hashtag, 1);
    }
  }, [callOurCustomerApi, location]);

  return (
    <div className="container">
      <div className="gcdo-header-row header-view-absolute justify-content-end float-right w-50">
        <div
          className={
            isSearchEnabled ? 'search-bar-with-icon search-bar-border' : 'search-bar-with-icon'
          }>
          {isSearchEnabled ? (
            <form className="directory-search-bar" onSubmit={callSubmitFunction}>
              <input
                autoFocus
                className="ml-2 directory-search-bar"
                type="text"
                placeholder="Search.."
                onChange={onInputChange}
                value={searchText}
              />
            </form>
          ) : (
            <div className="directory-search-bar" />
          )}
          {isSearchEnabled ? (
            <div className="gcdo-icon-row mr-2" onClick={resetValue}>
              <img
                src={getHeaderComponentUrl(
                  accessibilityData,
                  ACCESSIBILITY_HEADER_COMPONENTS.SEARCH_CANCEL
                )}
                className="header-icon"
              />
            </div>
          ) : (
            <div className="gcdo-icon-row mr-2" onClick={onClickSearchHandler}>
              <img
                src={getHeaderComponentUrl(
                  accessibilityData,
                  ACCESSIBILITY_HEADER_COMPONENTS.SEARCH
                )}
                className="header-icon"
              />
            </div>
          )}
        </div>
      </div>
      <SectionHeader identifier={ACCESSIBILITY_IDENTIFIERS.OUR_CUSTOMERS} />
      <BackButton />
      <div>
        {customers ? (
          <>
            {customers?.length ? (
              <div className="row">{customers?.map((item, index) => renderItem(item, index))}</div>
            ) : loading ? (
              <ActivityLoader visible={loading} />
            ) : (
              <DefaultContentView message={ERROR_MESSAGES.NO_DATA_FOUND} />
            )}
            {loadMoreFlag && !!customers?.length > 0 && (
              <LoadMoreBtn onClick={onPressLoadMore} isLoading={loading} />
            )}
          </>
        ) : (
          <ActivityLoader visible={loading} />
        )}
      </div>
    </div>
  );
};

export default OurCustomerKnowSearchScreen;
