import React from 'react';
import { useSelector } from 'react-redux';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
} from '../../../common/constants/AppConstants';
import Divider from '../../../common/ui/divider/Divider';
import { selectAccessibilityData } from '../../../state/AccessibilityData';
import { getHeaderComponentUrl } from '../../../utils/accessibility';
import HtmlView from '../../../common/ui/html_view/HTMLView';

const UpdatesTabItem = ({ item, onPressCommentButton, onPressLikeButton, onPressLikedBy }) => {
  const accessibilityData = useSelector(selectAccessibilityData);

  return (
    <div className="mb-3">
      <HtmlView htmlStyle="breakouts-update-summary-text" html={item.summary} />
      <div className="row mt-2">
        {item.likes.is_liked ? (
          <div>
            <img
              src={getHeaderComponentUrl(
                accessibilityData,
                ACCESSIBILITY_FEATURE_COMPONENTS.BREAKOUTS_ACTIVE_LIKE,
                ACCESSIBILITY_IDENTIFIERS.BREAKOUTS
              )}
              className="breakouts-normal-icon ml-3"
            />
          </div>
        ) : (
          <div onClick={() => onPressLikeButton(item)}>
            <img
              src={getHeaderComponentUrl(
                accessibilityData,
                ACCESSIBILITY_FEATURE_COMPONENTS.BREAKOUTS_INACTIVE_LIKE,
                ACCESSIBILITY_IDENTIFIERS.BREAKOUTS
              )}
              className="breakouts-normal-icon ml-3 common-cursor-pointer"
            />
          </div>
        )}
        <div
          className="ml-3 breakouts-likes-count-text common-cursor-pointer align-self-center"
          onClick={() => onPressLikedBy(item)}>
          {item.likes.count}
        </div>
        <div
          className="d-flex common-cursor-pointer align-items-center"
          onClick={() => onPressCommentButton(item)}>
          <img
            src={getHeaderComponentUrl(
              accessibilityData,
              ACCESSIBILITY_FEATURE_COMPONENTS.BREAKOUTS_POST_COMMENT,
              ACCESSIBILITY_IDENTIFIERS.BREAKOUTS
            )}
            className="breakouts-normal-icon ml-3 mb-0"
          />
          {item.comment_count !== 0 && (
            <div className="ml-3 breakouts-comment-count-text align-self-center">
              {item.comment_count}
            </div>
          )}
        </div>
      </div>
      <Divider style="mt-2" />
    </div>
  );
};

export default UpdatesTabItem;
