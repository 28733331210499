import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  ERROR_MESSAGES,
  FOREIGN_APP_CONSTANTS,
  GEOGRAPHY_ABBREVIATIONS,
  GEOGRAPHY_TITLE,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
  WARNING_MESSAGES,
} from '../common/constants/AppConstants';
import { store } from '../config/store';
import { historyRef } from '../navigation/Navigation';
import { GET_TOKEN } from '../services/ApiUrls';
import { apiRequest } from '../services/Service';
import { setShowAttendanceModal } from '../state/UIState';
import { getFeatureComponent } from './accessibility';
import { showToast } from './common';
import { logEvent } from './FirebaseAnalyticsUtils';
import {
  dispatchResetAllTabs,
  dispatchResetMiHRTabData,
  dispatchSaveCommunityData,
  dispatchSaveTravelRequestData,
  getAccessibilityData,
  getAttendanceInfo,
  getConfigurations,
  getEmployeeData,
  getGreythTargetUrl,
  getThirdPartyApps,
} from './ReduxState';

const getBaseUrlForForeignApps = (type, thirdPartyApps) => {
  let url = '';
  thirdPartyApps.forEach((element) => {
    if (element.app_identifier === type) {
      url = element.signin_url;
    }
  });
  return url;
};

const getToken = async (type, thirdPartyApps, identifier) => {
  try {
    const params = {
      app_identifier: type,
    };
    const apiResponse = await apiRequest(GET_TOKEN, REQUEST_TYPE.GET, params);
    if (Object.keys(apiResponse).length > 0) {
      if (apiResponse.response.status === true) {
        let url = getBaseUrlForForeignApps(type, thirdPartyApps) + apiResponse.response.jwt;
        let targetUrl = getGreythTargetUrl();
        if (identifier === ACCESSIBILITY_IDENTIFIERS.LEAVE) {
          let leaveTargeturl = targetUrl.leave;
          url = url + leaveTargeturl;
        } else if (identifier === ACCESSIBILITY_IDENTIFIERS.SALARY) {
          let salaryTargeturl = targetUrl.salary;
          url = url + salaryTargeturl;
        } else if (identifier === ACCESSIBILITY_IDENTIFIERS.ATTENDANCE) {
          let attendanceTargeturl = targetUrl.attendance;
          url = url + attendanceTargeturl;
        } else if (identifier === ACCESSIBILITY_IDENTIFIERS.MICROSELECT) {
          // note : removed the tokens from url in sprint 45 for micro select / recruiter portal
          let url = getBaseUrlForForeignApps(type, thirdPartyApps);
          window.open(url, '_blank').focus();
          return;
        }
        window.open(url, '_blank').focus();
      }
    }
  } catch (err) {
    //err
  }
};

const handleLeave = (employeeData, thirdPartyApps, identifier) => {
  if (
    employeeData.geography.abbreviation === GEOGRAPHY_ABBREVIATIONS.IND ||
    employeeData.geography.abbreviation === '' ||
    employeeData.geography.abbreviation === GEOGRAPHY_ABBREVIATIONS.SAU ||
    employeeData.geography.abbreviation === GEOGRAPHY_ABBREVIATIONS.UK
  ) {
    const targetUrl = getConfigurations()._leave_config || '';
    window.open(targetUrl, '_blank').focus();
  } else if (employeeData.geography.abbreviation === GEOGRAPHY_ABBREVIATIONS.USA) {
    window
      .open(getBaseUrlForForeignApps(FOREIGN_APP_CONSTANTS.TRINET, thirdPartyApps), '_blank')
      .focus();
  } else {
    showToast(WARNING_MESSAGES.FEATURE_NOT_AVAILABLE);
  }
};

const handleSalary = (employeeData, thirdPartyApps, identifier) => {
  if (
    employeeData.geography.abbreviation === GEOGRAPHY_ABBREVIATIONS.IND ||
    employeeData.geography.abbreviation === ''
  ) {
    // getToken(FOREIGN_APP_CONSTANTS.GREYT_HR, thirdPartyApps, identifier);
    window
      .open(getBaseUrlForForeignApps(FOREIGN_APP_CONSTANTS.GREYT_HR_WEB, thirdPartyApps), '_blank')
      .focus();
  } else if (employeeData.geography.abbreviation === GEOGRAPHY_ABBREVIATIONS.USA) {
    window
      .open(getBaseUrlForForeignApps(FOREIGN_APP_CONSTANTS.TRINET, thirdPartyApps), '_blank')
      .focus();
  } else if (
    employeeData.geography.abbreviation === GEOGRAPHY_ABBREVIATIONS.SAU ||
    employeeData.geography.abbreviation === GEOGRAPHY_ABBREVIATIONS.UK
  ) {
    showToast(WARNING_MESSAGES.FEATURE_NOT_AVAILABLE);
  } else {
    showToast(WARNING_MESSAGES.FEATURE_NOT_AVAILABLE);
  }
};

export const handleAttendance = (employeeData) => {
  if (employeeData.geography.title.toLowerCase() === GEOGRAPHY_TITLE.INDIA) {
    const targetUrl = getConfigurations()._attendance_config || '';
    window.open(targetUrl, '_blank').focus();
  } else {
    showToast(WARNING_MESSAGES.FEATURE_NOT_AVAILABLE);
  }
};

const handleExpenses = (employeeData, thirdPartyApps) => {
  if (employeeData.geography.abbreviation === GEOGRAPHY_ABBREVIATIONS.USA) {
    window
      .open(
        getBaseUrlForForeignApps(FOREIGN_APP_CONSTANTS.CONCUR_SOLUTIONS, thirdPartyApps),
        '_blank'
      )
      .focus();
  } else if (employeeData.geography.abbreviation === GEOGRAPHY_ABBREVIATIONS.UK) {
    window
      .open(
        getBaseUrlForForeignApps(FOREIGN_APP_CONSTANTS.UK_EXPENSE_LINK, thirdPartyApps),
        '_blank'
      )
      .focus();
  } else {
    window
      .open(getBaseUrlForForeignApps(FOREIGN_APP_CONSTANTS.MICROWORKS, thirdPartyApps), '_blank')
      .focus();
  }
};

const handleLoans = (employeeData, thirdPartyApps) => {
  if (employeeData.geography.title.toLowerCase() === GEOGRAPHY_TITLE.INDIA) {
    window
      .open(getBaseUrlForForeignApps(FOREIGN_APP_CONSTANTS.LOANS, thirdPartyApps), '_blank')
      .focus();
  } else {
    showToast(WARNING_MESSAGES.FEATURE_NOT_AVAILABLE);
  }
};
const handleMyIncentives = (employeeData, thirdPartyApps, identifier) => {
  if (employeeData.geography.title.toLowerCase() === GEOGRAPHY_TITLE.INDIA) {
    window
      .open(
        getBaseUrlForForeignApps(FOREIGN_APP_CONSTANTS.MYINCENTIVES, thirdPartyApps, identifier),
        '_blank'
      )
      .focus();
  } else {
    showToast(WARNING_MESSAGES.FEATURE_NOT_AVAILABLE);
  }
};

const handleInsurance = (employeeData, thirdPartyApps, identifier) => {
  window
    .open(
      getBaseUrlForForeignApps(FOREIGN_APP_CONSTANTS.INSURANCE, thirdPartyApps, identifier),
      '_blank'
    )
    .focus();
};

const handleNewJoinees = async () => {
  historyRef.current.push(NAVIGATION_ROUTES.NEW_JOINEES_LOWER_LEVELS);
};

const handlePasswordReset = () => {
  const targetUrl =
    getEmployeeData().domain === 'MLCORP'
      ? getConfigurations()._mlcorp_password_reset_url_web
      : getConfigurations()._delivery_password_reset_url_web;
  // const targetUrl = getConfigurations()._password_reset_url;
  window.open(targetUrl, '_blank').focus();
};

const handleAnalyticsDashboard = () => {
  const targetUrl = getConfigurations()._analytics_web_url;
  window.open(targetUrl, '_blank').focus();
};

const handleMicroUniv = () => {
  const targetUrl = JSON.parse(getConfigurations()._microuniv_config).url;
  window.open(targetUrl, '_blank').focus();
};

const handleMicroVax = (thirdPartyApps, identifier) => {
  getToken(FOREIGN_APP_CONSTANTS.MICROVAX, thirdPartyApps, identifier);
};

const handleMicroSelect = (thirdPartyApps, identifier) => {
  getToken(FOREIGN_APP_CONSTANTS.MICROSELECT, thirdPartyApps, identifier);
};

const handleOurJourney = () => {
  const targetUrl = getConfigurations()._our_journey;
  window.open(targetUrl, '_blank').focus();
};

const handleTimesheet = () => {
  const targetUrl = getConfigurations()._timesheet_url;
  window.open(targetUrl, '_blank').focus();
};

const handleTravelDomestic = () => {
  const targetUrl = getConfigurations()._travel_domestic_url;
  window.open(targetUrl, '_blank').focus();
};

const handleContinuousFeedback = () => {
  const targetUrl = getConfigurations()._continuous_feedback;
  window.open(targetUrl, '_blank').focus();
};

const handleActivitiesAchievements = () => {
  const targetUrl = getConfigurations()._activities_achievements;
  window.open(targetUrl, '_blank').focus();
};

const handleOnboardingDashboard = () => {
  const targetUrl = getConfigurations()._onboarding_dashboard;
  window.open(targetUrl, '_blank').focus();
};

const handleMiHR = () => {
  const targetUrl = getConfigurations()._mihr;
  window.open(targetUrl, '_blank').focus();
};

const handleMicroUnivSF = () => {
  const targetUrl = getConfigurations()._microuniv_config_sf;
  window.open(targetUrl, '_blank').focus();
};

export const navigatePage = (feature) => {
  const route =
    '/' +
    getFeatureComponent(
      getAccessibilityData(),
      ACCESSIBILITY_FEATURE_COMPONENTS.ROUTE,
      feature.identifier
    );

  const identifier = feature.identifier;
  switch (route) {
    case NAVIGATION_ROUTES.WEBVIEWER:
      if (identifier === ACCESSIBILITY_IDENTIFIERS.LEAVE) {
        handleLeave(getEmployeeData(), getThirdPartyApps(), identifier);
      } else if (identifier === ACCESSIBILITY_IDENTIFIERS.SALARY) {
        handleSalary(getEmployeeData(), getThirdPartyApps(), identifier);
      } else if (identifier === ACCESSIBILITY_IDENTIFIERS.ATTENDANCE) {
        handleAttendance(getEmployeeData());
      } else if (identifier === ACCESSIBILITY_IDENTIFIERS.EXPENSES) {
        handleExpenses(getEmployeeData(), getThirdPartyApps());
      } else if (identifier === ACCESSIBILITY_IDENTIFIERS.LOANS) {
        handleLoans(getEmployeeData(), getThirdPartyApps());
      } else if (identifier === ACCESSIBILITY_IDENTIFIERS.MYINCENTIVES) {
        handleMyIncentives(getEmployeeData(), getThirdPartyApps(), identifier);
      } else if (identifier === ACCESSIBILITY_IDENTIFIERS.INSURANCE) {
        handleInsurance(getEmployeeData(), getThirdPartyApps(), identifier);
      } else if (identifier === ACCESSIBILITY_IDENTIFIERS.MICROVAX) {
        handleMicroVax(getThirdPartyApps(), identifier);
      } else if (identifier === ACCESSIBILITY_IDENTIFIERS.MICROSELECT) {
        handleMicroSelect(getThirdPartyApps(), identifier);
      } else if (identifier === ACCESSIBILITY_IDENTIFIERS.ANALYTICS_DASHBOARD) {
        handleAnalyticsDashboard();
      } else if (identifier === ACCESSIBILITY_IDENTIFIERS.OUR_JOURNEY) {
        handleOurJourney(route);
      } else if (identifier === ACCESSIBILITY_IDENTIFIERS.TIMESHEET) {
        handleTimesheet(route);
      } else if (identifier === ACCESSIBILITY_IDENTIFIERS.TRAVEL_DOMESTIC) {
        handleTravelDomestic(route);
      } else if (identifier === ACCESSIBILITY_IDENTIFIERS.CONTINUOUS_FEEDBACK) {
        handleContinuousFeedback(route);
      } else if (identifier === ACCESSIBILITY_IDENTIFIERS.ACTIVITIES_ACHIVEMENNTS) {
        handleActivitiesAchievements(route);
      } else if (identifier === ACCESSIBILITY_IDENTIFIERS.ONBOARDING_DASHBOARD) {
        handleOnboardingDashboard(route);
      } else if (identifier === ACCESSIBILITY_IDENTIFIERS.MI_HR) {
        handleMiHR(route);
      } else if (identifier === ACCESSIBILITY_IDENTIFIERS.MICRO_UNIV) {
        handleMicroUnivSF(route);
      } else {
        showToast(ERROR_MESSAGES.DEFAULT);
        return;
      }
      break;
    case NAVIGATION_ROUTES.NEW_JOINEES_LOWER_LEVELS:
      dispatchResetAllTabs();
      handleNewJoinees();
      break;
    case NAVIGATION_ROUTES.PASSWORD_RESET:
      handlePasswordReset();
      break;
    case NAVIGATION_ROUTES.MICRO_UNIV:
      handleMicroUniv();
      break;
    case NAVIGATION_ROUTES.ATTENDANCE:
      if (getAttendanceInfo().is_webview) {
        handleAttendance(getEmployeeData());
      } else {
        store.dispatch(setShowAttendanceModal(true));
      }
      break;
    default:
      dispatchResetAllTabs();
      dispatchResetMiHRTabData();
      dispatchSaveTravelRequestData({});
      dispatchSaveCommunityData({});
      historyRef.current.push(route);
      return false;
  }
};

export const handleML35 = async (isHome = false) => {
  logEvent(
    isHome ? ANALYTICS_EVENT_TYPES.MLDay_35_HOME : ANALYTICS_EVENT_TYPES.ML_35_CLICK,
    '',
    ANALYTICS_ITEM_NAMES.ANNIVERSARY
  );
  const targetUrl = getBaseUrlForForeignApps(FOREIGN_APP_CONSTANTS.ML_35, getThirdPartyApps());
  window.open(targetUrl, '_blank').focus();
};
