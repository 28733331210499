import React, { useEffect, useState } from 'react';
import { firebaseDataPayload } from '../../../common/constants/AppConstants';
import { formatDateDDMonthYYYY } from '../../../utils/common';

const NotificationItem = ({ onPressItem, item }) => {
  const [isActive, setIsActive] = useState();

  useEffect(() => {
    if (Object.keys(item.data).length > 0) {
      if (!item.data.action || item.data.action === null || item.data.action === '') {
        switch (item.data.reference) {
          case firebaseDataPayload.NOTIFICATION:
            setIsActive(false);
            break;
          case firebaseDataPayload.PASSWORD_RESET_SUCCESS:
            setIsActive(false);
            break;
          case firebaseDataPayload.ASKML:
            setIsActive(false);
            break;
          case firebaseDataPayload.NEW_JOINEES:
            setIsActive(true);
            break;
          case firebaseDataPayload.STARS:
            setIsActive(true);
            break;
          case firebaseDataPayload.CELEBRATIONS:
            setIsActive(true);
            break;
          case firebaseDataPayload.COMMUNITIES:
            setIsActive(true);
            break;
          case firebaseDataPayload.COMMUNITIES_POST:
            setIsActive(true);
            break;
          case firebaseDataPayload.CONTEST:
            setIsActive(true);
            break;
          case firebaseDataPayload.ARTICLES:
            setIsActive(true);
            break;
          case firebaseDataPayload.EMPLOYEES:
            setIsActive(true);
            break;
          case firebaseDataPayload.EVENT:
            setIsActive(true);
            break;
          case firebaseDataPayload.DOWNLOADABLES:
            setIsActive(true);
            break;
          case firebaseDataPayload.FACILITIES:
            setIsActive(true);
            break;
          case firebaseDataPayload.WALLPOSTS:
            setIsActive(true);
            break;
          case firebaseDataPayload.IJPS:
            setIsActive(true);
            break;
          case firebaseDataPayload.NEWS:
            setIsActive(true);
            break;
          case firebaseDataPayload.ANNOUCNEMENTS_LIST:
            setIsActive(true);
            break;
          case firebaseDataPayload.GCDO_ANNOUCNEMENTS:
            setIsActive(true);
            break;
          case firebaseDataPayload.POLICIES:
            setIsActive(true);
            break;
          case firebaseDataPayload.GALLERY:
            setIsActive(true);
            break;
          case firebaseDataPayload.CMDCHAT:
            setIsActive(true);
            break;
          case firebaseDataPayload.CUSTOMER_ACCOLADES:
            setIsActive(true);
            break;
          case firebaseDataPayload.CUSTOMER_WINS:
            setIsActive(true);
            break;
          case firebaseDataPayload.CUSTOMER_KNOW:
            setIsActive(true);
            break;
          case firebaseDataPayload.CUSTOMER_TESTIMONIALS:
            setIsActive(true);
            break;
          case firebaseDataPayload.CUSTOMER_VISITS:
            setIsActive(true);
            break;
          case firebaseDataPayload.GALLERY_CLIENT_VISITS:
            setIsActive(true);
            break;
          case firebaseDataPayload.GALLERY_EVENTS:
            setIsActive(true);
            break;
          case firebaseDataPayload.GALLERY_STARS:
            setIsActive(true);
            break;
          case firebaseDataPayload.GALLERY_FACILITIES:
            setIsActive(true);
            break;
          case firebaseDataPayload.GALLERY_MICROGIVE:
            setIsActive(true);
            break;
          case firebaseDataPayload.GALLERY_COMMUNITIES:
            setIsActive(true);
            break;
          case firebaseDataPayload.GALLERY_CONTESTS:
            setIsActive(true);
            break;
          case firebaseDataPayload.GALLERY_LEARNING:
            setIsActive(true);
            break;
          case firebaseDataPayload.ML30:
            setIsActive(false);
            break;
          case firebaseDataPayload.DNI_ANNOUCNEMENTS:
            setIsActive(true);
            break;
          case firebaseDataPayload.DNI_PLEDGE:
            setIsActive(true);
            break;
          case firebaseDataPayload.MICROBIZ:
            setIsActive(true);
            break;
          case firebaseDataPayload.BTO:
            setIsActive(true);
            break;
          case firebaseDataPayload.COVID_SUPPORT:
            setIsActive(true);
            break;
          case firebaseDataPayload.TRAVEL:
            setIsActive(true);
            break;
          case firebaseDataPayload.PODCASTS:
            setIsActive(true);
            break;
          case firebaseDataPayload.DASHBOARD:
            setIsActive(true);
            break;
          case firebaseDataPayload.MONDAY_ANNOUNCEMENTS:
            setIsActive(true);
            break;
          case firebaseDataPayload.ML_DAY_33:
            setIsActive(true);
            break;
          case firebaseDataPayload.ML_DAY_34:
            setIsActive(true);
            break;
          case firebaseDataPayload.ML_DAY_35:
            setIsActive(true);
            break;
          case firebaseDataPayload.EMERGENCY_PLAYBOOKS:
            setIsActive(true);
            break;
          case firebaseDataPayload.MI_AUCTION:
            setIsActive(true);
            break;
          case firebaseDataPayload.CUSTOMERS_RESEARCHERS:
            setIsActive(true);
            break;
          case firebaseDataPayload.ESTORE:
            setIsActive(true);
            break;
          default:
            setIsActive(false);
            break;
        }
      } else {
        switch (item.data.action) {
          case firebaseDataPayload.TOPIC_NEWS:
            setIsActive(true);
            break;
          case firebaseDataPayload.MI_HR:
            setIsActive(true);
            break;
          case firebaseDataPayload.REQUEST:
            setIsActive(false);
            break;
          case firebaseDataPayload.PASSWORD_RESET:
            switch (item.data.reference) {
              case firebaseDataPayload.PASSWORD_EXPIRED:
                setIsActive(false);
                break;
              case firebaseDataPayload.PASSWORD_RESET_FAILED:
                setIsActive(false);
                break;
              case firebaseDataPayload.PASSWORD_REMAINDER:
                setIsActive(false);
                break;
            }
            break;
          case firebaseDataPayload.BROADCAST:
            setIsActive(true);
            break;
          case firebaseDataPayload.GCDO:
            setIsActive(true);
            break;
          case firebaseDataPayload.WOMENS_DAY:
            setIsActive(true);
            break;
          case firebaseDataPayload.ESG:
            setIsActive(true);
            break;
          case firebaseDataPayload.MLF:
            setIsActive(true);
            break;
          case firebaseDataPayload.MICROKNOW:
            setIsActive(true);
            break;
          case firebaseDataPayload.MICRO_LAW:
            setIsActive(true);
            break;
          case firebaseDataPayload.MICRO_LAW_ACTION:
            setIsActive(true);
            break;
          case firebaseDataPayload.STARS:
            setIsActive(true);
            break;
          case firebaseDataPayload.COMMENTS:
            setIsActive(true);
            break;
          case firebaseDataPayload.GALLERY_TAG:
            setIsActive(true);
            break;
          case firebaseDataPayload.BREAKOUT_SESSION:
            setIsActive(true);
            break;
          case firebaseDataPayload.BREAKOUT_UPDATE:
            setIsActive(true);
            break;
          case firebaseDataPayload.BREAKOUT_DISCUSSION:
            setIsActive(true);
            break;
          case firebaseDataPayload.GRAFFITI:
            setIsActive(true);
            break;
          case firebaseDataPayload.GRAFITTIS:
            setIsActive(true);
            break;
          case firebaseDataPayload.POLICY:
            setIsActive(true);
            break;
          case firebaseDataPayload.FACILITY:
            setIsActive(true);
            break;
          case firebaseDataPayload.ACCOLADES:
            setIsActive(true);
            break;
          case firebaseDataPayload.TESTIMONIALS:
            setIsActive(true);
            break;
          case firebaseDataPayload.KNOW:
            setIsActive(true);
            break;
          case firebaseDataPayload.WINS:
            setIsActive(true);
            break;
          case firebaseDataPayload.VISITS:
            setIsActive(true);
            break;
          case firebaseDataPayload.CHEER_CENTRAL:
            setIsActive(true);
            break;
          case firebaseDataPayload.CHEER_NOMINATOR:
            setIsActive(true);
            break;
          case firebaseDataPayload.CHEER_RECIPIENT:
            setIsActive(true);
            break;
          case firebaseDataPayload.WALLPOST:
            setIsActive(true);
            break;
          case firebaseDataPayload.TOPIC_ANNOUNCEMENTS:
            setIsActive(true);
            break;
          case firebaseDataPayload.EVENTS:
            setIsActive(true);
            break;
          case firebaseDataPayload.CONTESTS:
            setIsActive(true);
            break;
          case firebaseDataPayload.IJP:
            setIsActive(true);
            break;
          case firebaseDataPayload.BIRTHDAY:
            setIsActive(true);
            break;
          case firebaseDataPayload.PROFILEPIC:
            setIsActive(true);
            break;
          case firebaseDataPayload.ANNIVERSARY:
            setIsActive(true);
            break;
          case firebaseDataPayload.ADVANCEMENT:
            setIsActive(true);
            break;
          case firebaseDataPayload.TOPIC_COMMUNITIES:
            setIsActive(true);
            break;
          case firebaseDataPayload.TOPIC_COMMUNITIES_POST:
            setIsActive(true);
            break;
          case firebaseDataPayload.QUESTION_COMMENT:
            setIsActive(true);
            break;
          case firebaseDataPayload.MICROGIVE_FEEDBACK:
            setIsActive(true);
            break;
          case firebaseDataPayload.SURVEY:
            setIsActive(true);
            break;
          case firebaseDataPayload.POLL:
            setIsActive(true);
            break;
          case firebaseDataPayload.QUESTION:
            setIsActive(true);
            break;
          case firebaseDataPayload.DNI:
            setIsActive(true);
            break;
          case firebaseDataPayload.ERP_MICROBIZ:
            setIsActive(true);
            break;
          case firebaseDataPayload.BTO_ACTION:
            setIsActive(true);
            break;
          case firebaseDataPayload.COVID_ACTION:
            setIsActive(true);
            break;
          case firebaseDataPayload.TREQUEST:
            setIsActive(true);
            break;
          case firebaseDataPayload.PODCAST:
            setIsActive(true);
            break;
          case firebaseDataPayload.ML_MONDAY:
            setIsActive(true);
            break;
          case firebaseDataPayload.ML_DAY_33_ACTION:
            setIsActive(true);
            break;
          case firebaseDataPayload.ML_DAY_34_ACTION:
            setIsActive(true);
            break;
          case firebaseDataPayload.ML_DAY_35_ACTION:
            setIsActive(true);
            break;
          case firebaseDataPayload.BREAKOUT_ANSWERS:
            setIsActive(true);
            break;
          case firebaseDataPayload.COVID_SUPPORT:
            setIsActive(true);
            break;
          case firebaseDataPayload.POLLS:
            setIsActive(true);
            break;
          case firebaseDataPayload.SURVEYS:
            setIsActive(true);
            break;
          case firebaseDataPayload.PODCASTS:
            setIsActive(true);
            break;
          case firebaseDataPayload.EVENT_FEEDBACK:
            setIsActive(true);
            break;
          case firebaseDataPayload.CONTEST_FEEDBACK:
            setIsActive(true);
            break;
          case firebaseDataPayload.MI_AUCTION_ACTION:
            setIsActive(true);
            break;
          case firebaseDataPayload.RESEARCHERS:
            setIsActive(true);
            break;
          case firebaseDataPayload.ESTORE_HOME:
            setIsActive(true);
            break;
          default:
            setIsActive(false);
            break;
        }
      }
    }
  }, [item.data]);

  return (
    <div>
      {isActive ? (
        <div
          className="mb-3 common-cursor-pointer material-card p-3"
          onClick={() => onPressItem(item)}>
          <div className="notifications-cross-axis-view justify-content-between">
            <div className="notifications-title-active">{item.title}</div>
            <div className="notifications-timeStamp">{formatDateDDMonthYYYY(item.timestamp)}</div>
          </div>
          <div className="mt-1 notification-body">{item.body}</div>
        </div>
      ) : (
        <div className="mb-3 material-card p-3" onClick={() => onPressItem(item)}>
          <div className="notifications-cross-axis-view justify-content-between">
            <div className="notifications-title">{item.title}</div>
            <div className="notifications-timeStamp">{formatDateDDMonthYYYY(item.timestamp)}</div>
          </div>
          <div className="mt-1 notification-body">{item.body}</div>
        </div>
      )}
    </div>
  );
};

export default NotificationItem;
