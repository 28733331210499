import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import {
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  ERROR_MESSAGES,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
} from '../../../../common/constants/AppConstants';
import { apiRequest, handleError } from '../../../../services/Service';
import { GET_PROJECTS } from '../../../../services/ApiUrls';
import ProjectsListItem from '../../components/ProjectsListItem';
import LoadMoreBtn from '../../../../common/ui/load_more_btn/LoadMoreBtn';
import { useDispatch, useSelector } from 'react-redux';
import {
  saveProgressLoadingState,
  saveShowEmptyContentView,
  selectProgressLoadingState,
  selectShowEmptyContentView,
} from '../../../../state/UIState';
import DefaultContentView from '../../../../common/ui/default_content_view/DefaultContentView';
import { logEvent } from '../../../../utils/FirebaseAnalyticsUtils';

const ProjectsTab = ({ tabKey }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [projectsList, setProjectsList] = useState([]);
  const [pageId, setPageId] = useState(1);
  const [loadMoreFlag, setLoadMoreFlag] = useState(false);

  const showEmptyContentView = useSelector(selectShowEmptyContentView);
  const progressLoadingState = useSelector(selectProgressLoadingState);

  useEffect(() => {
    logEvent(ANALYTICS_EVENT_TYPES.PROJECTS_TAB_VISIT, tabKey, ANALYTICS_ITEM_NAMES.MICROGIVE);
  }, [tabKey]);

  const callProjectsApi = useCallback(
    async (id, loadMore) => {
      dispatch(saveShowEmptyContentView(true));
      if (loadMore) {
        const params = {
          page_id: id,
        };
        try {
          dispatch(saveProgressLoadingState({ isProgressLoading: true }));
          const apiResponse = await apiRequest(GET_PROJECTS, REQUEST_TYPE.GET, params);
          dispatch(saveProgressLoadingState({ isProgressLoading: false }));
          if (Object.keys(apiResponse).length > 0) {
            if (apiResponse.response.status === true) {
              if (apiResponse.response.data.projects.length > 0) {
                setProjectsList((oldArray) => [...oldArray, ...apiResponse.response.data.projects]);
                setPageId((prev) => prev + 1);
                setLoadMoreFlag(true);
              } else {
                setLoadMoreFlag(false);
              }
            }
          }
        } catch (err) {
          handleError(err, params, GET_PROJECTS, NAVIGATION_ROUTES.PROJECT_TABS_SCREEN);
          dispatch(saveProgressLoadingState({ isProgressLoading: false }));
        }
      }
    },
    [dispatch]
  );

  useEffect(() => {
    callProjectsApi(1, true);
  }, [callProjectsApi]);

  const onPress = (item) => {
    history.push(`${NAVIGATION_ROUTES.PROJECT_DETAIL_SCREEN}/${item.project_id}`);
  };

  const onPressLoadMore = () => {
    callProjectsApi(pageId, loadMoreFlag);
  };

  const emptyListView = () => {
    return <DefaultContentView message={ERROR_MESSAGES.NO_PROJECT_TO_SHOW} />;
  };

  return (
    <div className="main-content-container">
      {showEmptyContentView && !projectsList.length && !progressLoadingState.isProgressLoading ? (
        emptyListView()
      ) : (
        <div>
          <div className="row">
            {projectsList.map((item, index) => {
              return <ProjectsListItem item={item} onPress={onPress} key={`${index}`} />;
            })}
          </div>
          {loadMoreFlag && (
            <LoadMoreBtn
              onClick={onPressLoadMore}
              isLoading={progressLoadingState.isProgressLoading}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default ProjectsTab;
