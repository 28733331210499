import React, { useCallback, useEffect, useState } from 'react';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_HEADER_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  ANALYTICS_SCREEN_NAMES,
  FEATURE_TYPE,
  LOADING_MESSAGES,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
  SCREEN_DENSITY_QUALIFIERS,
} from '../../common/constants/AppConstants';
import { useDispatch, useSelector } from 'react-redux';
import {
  formatDateDDMonthYYYYTimeAMPM,
  formatSecondsToHHMMSS,
  getUrlWithSpecificRendition,
  getYoutubeIdFromUrl,
} from '../../utils/common';
import { apiRequest, handleError } from '../../services/Service';
import {
  COMMENT_BY_ID,
  PODCAST_EPISODE_BY_ID,
  PODCAST_UPDATE_PLAY_COUNT,
} from '../../services/ApiUrls';
import { saveProgressLoadingState, saveSearchText } from '../../state/UIState';
import { selectAccessibilityData } from '../../state/AccessibilityData';
import { useParams } from 'react-router-dom';
import SectionHeader from '../../common/ui/section_header/SectionHeader';
import Divider from '../../common/ui/divider/Divider';
import EpisodeParticipantsListItem from './components/EpisodeParticipantsListItem';
import { getFeatureComponentUrl, getHeaderComponentUrl } from '../../utils/accessibility';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { logEvent, trackScreen } from '../../utils/FirebaseAnalyticsUtils';
import BackButton from '../../common/ui/common_back_button/BackButton';
import HtmlView from '../../common/ui/html_view/HTMLView';
import CustomYoutubePlayer from '../../common/ui/youtube_video/CustomYoutubePlayer';
import PlayIconOverlay from '../../common/ui/play_icon_overlay/PlayIconOverlay';
import { selectAccessData } from '../../state/UserAccessData';

const PodcastEpisodeDetailScreen = ({ location, history }) => {
  const { episodeId } = useParams();
  const dispatch = useDispatch();
  const accessibilityData = useSelector(selectAccessibilityData);

  const [audio, setAudio] = useState();
  const [playing, setPlaying] = useState(false);

  const [episodeData, setEpisodeData] = useState({});
  const [participants, setParticipants] = useState([]);
  const [duration, setDuration] = useState('');
  const [totalDuration, setTotalDuration] = useState('');
  const [progressDuration, setProgressDuration] = useState('');
  const [progressPosition, setProgressPosition] = useState('');
  const [text, setText] = useState('See more');
  const [liked, setLiked] = useState(false);
  const [likeCount, setLikeCount] = useState(0);
  const [commentCount, setCommentCount] = useState(0);
  const [playCount, setPlayCount] = useState(0);
  const [mediaType, setMediaType] = useState('audio');
  const [video, setVideo] = useState('');
  const [showVideo, setShowVideo] = useState(false);
  const accessData = useSelector(selectAccessData);
  const [showLikeButton, setShowLikeButton] = useState(true);
  const [showCommentButton, setShowCommentButton] = useState(true);
  const podcastAccessData = accessData?.data?.find((item) => item.feature_key === '_podcasts');

  useEffect(() => {
    const actions = podcastAccessData?.actions;
    setShowLikeButton(actions?.includes('_like'));
    setShowCommentButton(actions?.includes('_comment'));
  }, [podcastAccessData?.actions]);

  useEffect(() => {
    trackScreen(
      ANALYTICS_SCREEN_NAMES.PODCASTS_EPISODE_DETAIL,
      NAVIGATION_ROUTES.PODCASTS_EPISODE_DETAIL
    );
  }, []);

  useEffect(() => {
    if (audio) {
      if (location && location.state && location.state.isPlay) {
        setPlaying(true);
      }
    }
  }, [audio, location]);

  useEffect(() => {
    if (audio) {
      playing ? audio.play() : audio.pause();
    }
  }, [audio, playing]);

  useEffect(() => {
    if (audio) {
      audio.addEventListener('ended', () => setPlaying(false));
      audio.addEventListener('loadedmetadata', (e) => {
        setProgressDuration(e.target.duration);
      });
    }
    return () => {
      if (audio) {
        audio.pause();
        audio.removeEventListener('ended', () => setPlaying(false));
      }
    };
  }, [audio]);

  const timeupdate = (e) => {
    setProgressPosition(e.target.currentTime);
  };

  React.useEffect(() => {
    if (audio) {
      audio.addEventListener('timeupdate', timeupdate);
    }
    return () => {
      if (audio) {
        audio.removeEventListener('timeupdate', timeupdate);
      }
    };
  });

  const onPressRead = () => {
    if (text === 'See more') {
      setText('See less');
    } else {
      setText('See more');
    }
  };

  useEffect(() => {
    if (episodeData && Object.keys(episodeData).length > 0) {
      let particpants = episodeData.participants;
      if (Object.keys(episodeData.host_profile).length > 0) {
        particpants.push({
          participant_id: 0,
          is_internal: true,
          employee_id: episodeData.host_profile.employee_id,
          first_name: episodeData.host_profile.first_name,
          middle_name: episodeData.host_profile.middle_name,
          last_name: episodeData.host_profile.last_name,
          linkedin_url: episodeData.host_profile.linkedin_url,
          position_title: episodeData.host_profile.position_title,
          profile_picture: episodeData.host_profile.profile_picture,
          pronouns: episodeData.host_profile.pronouns,
        });
      }
      setParticipants(particpants);
    }
  }, [episodeData]);

  useEffect(() => {
    if (episodeData && Object.keys(episodeData).length > 0) {
      let timer = episodeData.audio.duration;
      if (timer && timer !== '') {
        setTotalDuration(formatSecondsToHHMMSS(timer, 1));
        setDuration(formatSecondsToHHMMSS(timer, 2));
      }
    }
  }, [episodeData]);

  const callEpisodeApi = useCallback(async () => {
    const params = {
      episode_id: episodeId,
    };
    try {
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: true,
        })
      );
      const apiResponse = await apiRequest(PODCAST_EPISODE_BY_ID, REQUEST_TYPE.GET, params);
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: false,
        })
      );
      if (Object.keys(apiResponse).length > 0) {
        if (apiResponse.response.status === true) {
          setEpisodeData(apiResponse.response.data.episode);
          if (apiResponse.response.data.episode.media_type == 'audio') {
            setAudio(new Audio(apiResponse.response.data.episode?.audio?.audio_url));
          }
          if (apiResponse.response.data.episode.media_type == 'video') {
            setVideo(apiResponse.response.data.episode.video.video_url);
          }
          setMediaType(apiResponse.response.data.episode.media_type);
          setLiked(apiResponse.response.data.episode.liked);
          setCommentCount(apiResponse.response.data.episode.comment_count);
          setLikeCount(apiResponse.response.data.episode.like_count);
          setPlayCount(apiResponse.response.data.episode.play_count);
        }
      }
    } catch (err) {
      handleError(err, params, PODCAST_EPISODE_BY_ID, NAVIGATION_ROUTES.PODCASTS_EPISODE_DETAIL);
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: false,
        })
      );
    }
  }, [dispatch, episodeId]);

  useEffect(() => {
    callEpisodeApi();
  }, [callEpisodeApi]);

  const callUpdatePlayCountApi = useCallback(async () => {
    const params = { episode_id: episodeId };
    try {
      const apiResponse = await apiRequest(PODCAST_UPDATE_PLAY_COUNT, REQUEST_TYPE.GET, params);
      if (Object.keys(apiResponse).length > 0) {
        if (apiResponse.response.status === true) {
          if (apiResponse.response.message) {
            const message = apiResponse.response.message;
            if (message && playCount !== apiResponse.response.play_count) {
              setPlayCount(apiResponse.response.play_count);
            }
          }
        }
      }
    } catch (err) {
      handleError(
        err,
        params,
        PODCAST_UPDATE_PLAY_COUNT,
        NAVIGATION_ROUTES.PODCASTS_EPISODE_DETAIL
      );
      //
    }
  }, [episodeId, playCount]);

  const toggle = () => {
    if (!playing) {
      logEvent(
        ANALYTICS_EVENT_TYPES.PODCAST_EPISODE_PLAY,
        episodeId.toString(),
        ANALYTICS_ITEM_NAMES.PODCASTS
      );
      callUpdatePlayCountApi();
    }
    setPlaying(!playing);
  };

  const postLikeFunction = useCallback(async () => {
    dispatch(
      saveProgressLoadingState({
        isProgressLoading: true,
        progressMessage: LOADING_MESSAGES.LIKE_UPDATE,
      })
    );
    logEvent(
      ANALYTICS_EVENT_TYPES.PODCAST_EPISODE_LIKE,
      episodeId.toString(),
      ANALYTICS_ITEM_NAMES.LIKE
    );
    const params = new URLSearchParams();
    params.append('type', FEATURE_TYPE.PODCAST_EPISODE);
    try {
      const apiResponse = await apiRequest(
        COMMENT_BY_ID + episodeId + '/like',
        REQUEST_TYPE.POST,
        params
      );

      if (Object.keys(apiResponse).length > 0) {
        if (apiResponse.response.status === true) {
          if (apiResponse.response.data) {
            const data = apiResponse.response.data;
            setLikeCount(data.like_count);
            setLiked(data.liked);
          }
        }
      }
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: false,
        })
      );
    } catch (err) {
      handleError(
        err,
        params,
        COMMENT_BY_ID + episodeId + '/like',
        NAVIGATION_ROUTES.PODCASTS_EPISODE_DETAIL
      );
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: false,
        })
      );
    }
  }, [dispatch, episodeId]);

  const handleSeek = (value) => {
    if (audio) {
      audio.currentTime = value;
      setProgressPosition(value);
    }
  };

  const handleHtmlClicks = (e) => {
    const target = e.target.getAttribute('href');
    if (target) {
      if (target.startsWith('/') || target.startsWith('?')) {
        if (target.includes(NAVIGATION_ROUTES.EMPLOYEE_PROFILE_DETAIL)) {
          logEvent(
            ANALYTICS_EVENT_TYPES.USER_MENTION_CLICK,
            e?.target?.innerText,
            ANALYTICS_ITEM_NAMES.USER_MENTION
          );
          e.stopPropagation();
          e.preventDefault();
          history.push(target);
        } else {
          logEvent(
            ANALYTICS_EVENT_TYPES.HASHTAG_CLICK,
            e?.target?.innerText,
            ANALYTICS_ITEM_NAMES.HASHTAG
          );
          dispatch(saveSearchText(''));
          e.preventDefault();
          history.push(NAVIGATION_ROUTES.PODCASTS_SEARCH);
          history.push(target);
        }
      } else {
        e.stopPropagation();
        e.preventDefault();
        window.open(target, '_blank');
      }
    }
  };

  return (
    <div className="container">
      <SectionHeader identifier={ACCESSIBILITY_IDENTIFIERS.PODCASTS} />
      <div className="header-view justify-content-between">
        <BackButton backTitle={location.state.channelTitle} isFullRow={false} />
        {episodeData && (
          <div className="d-flex align-items-center">
            {episodeData.enable_like && showLikeButton && (
              <div className="broadcast-icon-row mr-3">
                <img
                  src={getHeaderComponentUrl(
                    accessibilityData,
                    liked
                      ? ACCESSIBILITY_HEADER_COMPONENTS.ACTIVE_LIKE
                      : ACCESSIBILITY_HEADER_COMPONENTS.INACTIVE_LIKE
                  )}
                  className="header-icon"
                  onClick={postLikeFunction}
                />
                <div
                  className="broadcast-icon-text"
                  onClick={() => {
                    if (likeCount > 0) {
                      history.push(NAVIGATION_ROUTES.LIKED_PEOPLE, {
                        referId: episodeId,
                        parentType: FEATURE_TYPE.PODCAST_EPISODE,
                      });
                    }
                  }}>
                  {likeCount}
                </div>
              </div>
            )}
            {episodeData.enable_comment && showCommentButton && (
              <div className="broadcast-icon-row mr-3">
                <img
                  src={getHeaderComponentUrl(
                    accessibilityData,
                    ACCESSIBILITY_HEADER_COMPONENTS.COMMENT
                  )}
                  className="header-icon"
                  onClick={() => {
                    history.push(NAVIGATION_ROUTES.COMMENTS, {
                      referId: episodeId,
                      likeType: FEATURE_TYPE.PODCAST_EPISODE,
                    });
                  }}
                />
                <div
                  className="broadcast-icon-text"
                  onClick={() => {
                    history.push(NAVIGATION_ROUTES.COMMENTS, {
                      referId: episodeId,
                      likeType: FEATURE_TYPE.PODCAST_EPISODE,
                    });
                  }}>
                  {commentCount}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
      <div className="main-content-container">
        {Object.keys(episodeData).length > 0 && (
          <div>
            <div className="row">
              <div className="col-sm-4">
                {showVideo ? (
                  <CustomYoutubePlayer videoId={getYoutubeIdFromUrl(video)} />
                ) : (
                  <div
                    className={mediaType === 'video' ? 'common-cursor-pointer' : ''}
                    onClick={() => mediaType === 'video' && setShowVideo(true)}>
                    {mediaType === 'video' && <PlayIconOverlay />}
                    <img
                      src={getUrlWithSpecificRendition(
                        episodeData.promo_image.base_url,
                        SCREEN_DENSITY_QUALIFIERS.HDPI,
                        episodeData.promo_image.image_path
                      )}
                      className="embed-responsive embed-responsive-16by9"
                    />
                  </div>
                )}
                {mediaType === 'audio' && (
                  <div className="mt-3 pt-3 pb-3 d-flex justify-content-between align-items-center">
                    <div onClick={toggle} className="common-cursor-pointer">
                      {playing ? (
                        <img
                          className="podcast-play-pause"
                          src={getFeatureComponentUrl(
                            accessibilityData,
                            ACCESSIBILITY_FEATURE_COMPONENTS.COMMON_PAUSE,
                            ACCESSIBILITY_IDENTIFIERS.COMMON
                          )}
                        />
                      ) : (
                        <img
                          className="podcast-play-pause"
                          src={getFeatureComponentUrl(
                            accessibilityData,
                            ACCESSIBILITY_FEATURE_COMPONENTS.COMMON_PLAY,
                            ACCESSIBILITY_IDENTIFIERS.COMMON
                          )}
                        />
                      )}
                    </div>
                    <Slider
                      min={0}
                      max={progressDuration}
                      step={1}
                      value={progressPosition}
                      onChange={handleSeek}
                      className="ml-3 mr-3"
                    />
                    <div className="mr-3 podcast-timer-text-style">{`${formatSecondsToHHMMSS(
                      progressPosition,
                      1
                    )}/${totalDuration}`}</div>
                  </div>
                )}
              </div>
              <div className="col-sm-8">
                <div className="podcast-title">{`${episodeData.title}`}</div>
                <div className="mt-3 d-flex">
                  <div className="">
                    <div className="podcast-published-duration">Published</div>
                    <div className="podcast-published-duration">Duration</div>
                  </div>
                  <div className="ml-4">
                    <div className="podcast-published-duration-value">
                      {formatDateDDMonthYYYYTimeAMPM(episodeData.pub_datetime)}
                    </div>
                    <div className="podcast-published-duration-value">{duration}</div>
                  </div>
                </div>
                <div>
                  {(!!playCount || !!episodeData.page_views) && (
                    <div className="d-flex mt-3">
                      {!!playCount && (
                        <div className="d-flex align-items-center">
                          <img
                            src={getFeatureComponentUrl(
                              accessibilityData,
                              ACCESSIBILITY_FEATURE_COMPONENTS.COMMON_CIRCULAR_PLAY_OUTLINE_BLUE,
                              ACCESSIBILITY_IDENTIFIERS.COMMON
                            )}
                            className="common-load-date-text-event-icon-sm"
                          />
                          <p className="mb-0 mr-4 common-load-date-text-event-date">
                            {playCount} {playCount === 1 ? 'play' : 'plays'}
                          </p>
                        </div>
                      )}

                      {!!episodeData.page_views && (
                        <div className="d-flex align-items-center">
                          <img
                            src={getFeatureComponentUrl(
                              accessibilityData,
                              ACCESSIBILITY_FEATURE_COMPONENTS.COMMON_OPEN_EYE_BLUE,
                              ACCESSIBILITY_IDENTIFIERS.COMMON
                            )}
                            className="common-load-date-text-event-icon-sm"
                          />
                          <p className="mb-0 common-load-date-text-event-date">
                            {episodeData.page_views}{' '}
                            {episodeData.page_views === 1 ? 'view' : 'views'}
                          </p>
                        </div>
                      )}
                    </div>
                  )}
                </div>
                {text === 'See more' ? (
                  <div className="mt-3 podcast-description common-ws-pre-line">
                    {episodeData.description.length > 200 ? (
                      <>
                        <HtmlView
                          html={episodeData.description.substring(0, 200) + '...'}
                          onClick={handleHtmlClicks}
                          htmlStyle="mt-3 podcast-content-html"
                        />
                        <span
                          className="common-cursor-pointer podcast-see-more-less"
                          onClick={onPressRead}>{`${text}`}</span>
                      </>
                    ) : (
                      <HtmlView
                        html={episodeData.description}
                        onClick={handleHtmlClicks}
                        htmlStyle="mt-3 podcast-content-html"
                      />
                    )}
                  </div>
                ) : (
                  <div className="mt-2 podcast-description common-ws-pre-line">
                    <HtmlView
                      html={episodeData.description}
                      onClick={handleHtmlClicks}
                      htmlStyle="mt-3 podcast-content-html"
                    />
                    <span
                      className="common-cursor-pointer podcast-see-more-less"
                      onClick={onPressRead}>{`${text}`}</span>
                  </div>
                )}
              </div>
            </div>
            <Divider style="mt-2" />
            <div className="mt-3 podcast-title">Participants</div>
            <div className="row">
              {participants.length > 0 &&
                participants.map((item, index) => {
                  return (
                    <div key={`${index}`} className="col-6 col-sm-3 col-lg-2 p-3">
                      <div className="">
                        {item.is_internal === true ? (
                          <EpisodeParticipantsListItem
                            imageBaseUrl={item.profile_picture.base_url}
                            imagePath={item.profile_picture.image_path}
                            employeeId={item.employee_id}
                            imageClass="common-user-profile-photo-xl mb-2"
                            linkedinUrl={''}
                          />
                        ) : (
                          <EpisodeParticipantsListItem
                            imageBaseUrl={item.profile_picture.base_url}
                            imagePath={item.profile_picture.image_path}
                            imageClass="common-user-profile-photo-xl mb-2"
                            linkedinUrl={item.linkedin_url}
                          />
                        )}
                        <div className="text-center podcast-host-name">
                          <p className="align-items-center m-0 p-0">
                            <span className="text-center podcast-host-name mb-0">{`${item.first_name} ${item.last_name}`}</span>
                            {!!item.pronouns && (
                              <span className="text-center podcast-host-pronoun ml-1">
                                {'(' + item.pronouns + ')'}
                              </span>
                            )}
                          </p>
                        </div>
                        <div className="text-center podcast-participants-location">
                          {item.position_title}
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
            <Divider />
          </div>
        )}
      </div>
    </div>
  );
};

export default PodcastEpisodeDetailScreen;
