import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectAnniversaryDayTabs } from '../../state/MasterData';
import SectionHeader from '../../common/ui/section_header/SectionHeader';
import {
  ACCESSIBILITY_HEADER_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  ANALYTICS_SCREEN_NAMES,
  ML_DAY_IDENTIFIERS,
  NAVIGATION_ROUTES,
} from '../../common/constants/AppConstants';
import { getHeaderComponentUrl } from '../../utils/accessibility';
import { selectAccessibilityData } from '../../state/AccessibilityData';
import { Modal, Form } from 'react-bootstrap';
import CustomButton from '../../common/ui/custom_button/CustomButton';
import { logEvent, trackScreen } from '../../utils/FirebaseAnalyticsUtils';
import { resetAllTabs, saveAnniversaryTabData } from '../../state/TabData';
import AnniversaryDayListItem from './components/AnniversaryDayListItem';
import { handleML35 } from '../../utils/featureNavigation';

const AnniversaryDayScreen = ({ history }) => {
  const dispatch = useDispatch();
  const [showNewsDialog, setShowNewsDialog] = useState(false);
  const [selectedDate, setSelectedDate] = useState('');
  const [dateArray, setDateArray] = useState([]);

  const anniversaryDayTitles = useSelector(selectAnniversaryDayTabs);
  const accessibilityData = useSelector(selectAccessibilityData);
  const [filteredArr, setFilteredArr] = useState(anniversaryDayTitles);

  useEffect(() => {
    trackScreen(ANALYTICS_SCREEN_NAMES.ANNIVERSARY_SCREEN, NAVIGATION_ROUTES.ANNIVERSARY_SCREEN);
  }, []);

  useEffect(() => {
    const arr = [];
    for (var date in anniversaryDayTitles) {
      arr.push(`${anniversaryDayTitles[date].year} - ${anniversaryDayTitles[date].title}`);
    }
    setDateArray(arr);
    if (anniversaryDayTitles && anniversaryDayTitles[0]) {
      setSelectedDate(
        anniversaryDayTitles && `${anniversaryDayTitles[0].year} - ${anniversaryDayTitles[0].title}`
      );
    }
  }, [anniversaryDayTitles]);

  const getEventType = (identifier) => {
    switch (identifier) {
      case ML_DAY_IDENTIFIERS.ML_35_IDENTIFIER:
        return ANALYTICS_EVENT_TYPES.ML_35_CLICK;
      case ML_DAY_IDENTIFIERS.ML_34_IDENTIFIER:
        return ANALYTICS_EVENT_TYPES.ML_34_CLICK;
      case ML_DAY_IDENTIFIERS.ML_33_IDENTIFIER:
        return ANALYTICS_EVENT_TYPES.ML_33_CLICK;
      case ML_DAY_IDENTIFIERS.ML_32_IDENTIFIER:
        return ANALYTICS_EVENT_TYPES.ML_32_CLICK;
      case ML_DAY_IDENTIFIERS.ML_31_IDENTIFIER:
        return ANALYTICS_EVENT_TYPES.ML_31_CLICK;
      case ML_DAY_IDENTIFIERS.ML_30_IDENTIFIER:
        return ANALYTICS_EVENT_TYPES.ML_30_CLICK;
    }
  };

  const onPressItem = (item) => {
    dispatch(saveAnniversaryTabData(''));
    dispatch(resetAllTabs());
    logEvent(getEventType(item.identifier), '', ANALYTICS_ITEM_NAMES.ANNIVERSARY);
    history.push(`${NAVIGATION_ROUTES.ANNIVERSARY_SCREEN}/${item.identifier}`, {
      identifier: item.identifier,
    });
  };

  const customYearView = () => {
    return (
      <div>
        <Form>
          <Form.Group controlId="exampleForm.SelectCustom">
            <Form.Label>Select a Microland Day</Form.Label>
            <div className="anniversary-year-container">
              <Form.Control
                as="select"
                custom
                value={selectedDate}
                onChange={(event) => {
                  setSelectedDate(event.target.value);
                }}>
                {dateArray &&
                  dateArray.map((item) => {
                    return (
                      <option key={item} defaultValue={item === selectedDate}>
                        {item}
                      </option>
                    );
                  })}
              </Form.Control>
            </div>
          </Form.Group>
        </Form>
        <div className="gcdo-row">
          <div>
            <CustomButton
              buttonStyle="gcdo-calender-button-style"
              onClick={() => {
                setShowNewsDialog(false);
                setFilteredArr(anniversaryDayTitles);
                setSelectedDate(
                  anniversaryDayTitles &&
                    `${anniversaryDayTitles[0].year} - ${anniversaryDayTitles[0].title}`
                );
              }}>
              CLEAR FILTERS
            </CustomButton>
          </div>
          <div className="d-flex align-items-end justify-content-end">
            <CustomButton
              buttonStyle="anniversary-calender-button-style"
              onClick={() => {
                setShowNewsDialog(false);
              }}>
              CANCEL
            </CustomButton>
            <CustomButton
              buttonStyle="anniversary-calender-button-style"
              onClick={() => {
                let arr = [];
                const selectedObj = anniversaryDayTitles.find(
                  (res) => `${res.year} - ${res.title}` === selectedDate
                );
                arr.push(selectedObj);
                setFilteredArr(arr);
                setShowNewsDialog(false);
              }}>
              DONE
            </CustomButton>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="container">
      <Modal
        show={showNewsDialog}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => setShowNewsDialog(false)}>
        <Modal.Body>{customYearView()}</Modal.Body>
      </Modal>
      <div className="gcdo-header-row header-view-absolute justify-content-end float-right">
        <div className="gcdo-icon-row mr-3">
          <img
            src={getHeaderComponentUrl(accessibilityData, ACCESSIBILITY_HEADER_COMPONENTS.CALENDER)}
            className="header-icon"
            onClick={() => setShowNewsDialog(true)}
          />
        </div>
      </div>
      <SectionHeader identifier={ACCESSIBILITY_IDENTIFIERS.ANNIVERSARY_DAY} />
      <div className="main-content-container">
        {filteredArr.map((item, index) => {
          return <AnniversaryDayListItem onPressItem={onPressItem} item={item} key={`${index}`} />;
        })}
      </div>
    </div>
  );
};

export default AnniversaryDayScreen;
