import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectEmployeeData } from '../../../state/EmployeeData';
import { TwitterIcon } from 'react-share';
import { LinkedinIcon, FacebookIcon, EmailIcon } from 'react-share';
import { getFeatureComponentUrl } from '../../../utils/accessibility';
import { selectAccessibilityData } from '../../../state/AccessibilityData';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
} from '../../../common/constants/AppConstants';
import { shareImage } from '../../../utils/common';
import { saveAs } from 'file-saver';
import { logEvent2 } from '../../../utils/FirebaseAnalyticsUtils';

const WallpapersListItem = ({ item, index, onPress, shareImageUrlOverEmail }) => {
  const employeeData = useSelector(selectEmployeeData);
  const accessibilityData = useSelector(selectAccessibilityData);
  const [isPortraitMode, setIsPortraitMode] = useState(true);

  const shareWallpaper = (platform, url) => {
    logEvent2({
      eventType: ANALYTICS_EVENT_TYPES.WALLPAPERS_SHARE,
      itemId: '',
      itemName: '',
      categoryId: ANALYTICS_ITEM_NAMES.ANNIVERSARY,
      categoryName: ANALYTICS_ITEM_NAMES.ANNIVERSARY,
    });
    shareImage(platform, url);
  };

  var leafname = item.split('\\').pop().split('/').pop();

  const downloadImage = async () => {
    logEvent2({
      eventType: ANALYTICS_EVENT_TYPES.WALLPAPERS_DOWNLOAD,
      itemId: '',
      itemName: '',
      categoryId: ANALYTICS_ITEM_NAMES.ANNIVERSARY,
      categoryName: ANALYTICS_ITEM_NAMES.ANNIVERSARY,
    });
    saveAs(item, leafname);
  };

  return (
    <div
      onClick={() => onPress(item, index)}
      className="anniversary-wallpaper-item col-sm-4 mb-3 common-cursor-pointer">
      <img src={item} className="embed-responsive embed-responsive-16by9" />
      <div className="anniversary-info-view ml-2 mr-2">
        <div>
          <div className="anniversary-name-text text-center">{`${employeeData.first_name} ${employeeData.last_name}`}</div>
          <div className="anniversary-name-text text-center mt-1">
            {`+${employeeData.mobile_number.isd_code} ${employeeData.mobile_number.number}`}
          </div>
          <div className="anniversary-name-text text-center mt-1">{employeeData.email}</div>
        </div>
      </div>
      <div
        className={`${
          isPortraitMode === true
            ? 'ecards-social-icons mb-2'
            : 'ecards-social-icons-landscape mr-1 mb-1 align-items-center w-100 d-flex justify-content-end flex-row position-absolute'
        }`}>
        <LinkedinIcon
          size={32}
          round={true}
          onClick={(e) => {
            e.stopPropagation();
            shareWallpaper('Linkedin', item);
          }}
          className={`${isPortraitMode === true ? 'mb-1' : 'mr-1'}`}
        />
        <FacebookIcon
          size={32}
          round={true}
          onClick={(e) => {
            e.stopPropagation();
            shareWallpaper('Facebook', item);
          }}
          className={`${isPortraitMode === true ? 'mb-1' : 'mr-1'}`}
        />
        <TwitterIcon
          size={32}
          round={true}
          onClick={(e) => {
            e.stopPropagation();
            shareWallpaper('Twitter', item);
          }}
          className={`${isPortraitMode === true ? 'mb-1' : 'mr-1'}`}
        />
        <EmailIcon
          size={32}
          round={true}
          onClick={(e) => {
            e.stopPropagation();
            shareImageUrlOverEmail(item);
          }}
          className={`${isPortraitMode === true ? 'mb-1' : 'mr-1'}`}
        />
        <div
          onClick={async (e) => {
            e.stopPropagation();
            downloadImage(item);
          }}>
          <img
            src={getFeatureComponentUrl(
              accessibilityData,
              ACCESSIBILITY_FEATURE_COMPONENTS.ECARDS_DOWNLOAD_CARD,
              ACCESSIBILITY_IDENTIFIERS.ECARDS
            )}
            className="ecards-download-icon"
          />
        </div>
      </div>
    </div>
  );
};

export default WallpapersListItem;
