import React, { useCallback, useEffect, useState } from 'react';
import {
  ACCESSIBILITY_HEADER_COMPONENTS,
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  ANALYTICS_SCREEN_NAMES,
  FEATURE_TYPE,
  LOADING_MESSAGES,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
  SCREEN_DENSITY_QUALIFIERS,
} from '../../common/constants/AppConstants';
import { logEvent, trackScreen } from '../../utils/FirebaseAnalyticsUtils';
import SectionHeader from '../../common/ui/section_header/SectionHeader';
import { getHeaderComponentUrl } from '../../utils/accessibility';
import BackButton from '../../common/ui/common_back_button/BackButton';
import { useDispatch, useSelector } from 'react-redux';
import { saveProgressLoadingState } from '../../state/UIState';
import { selectAccessibilityData } from '../../state/AccessibilityData';
import { AUCTION, COMMENT_BY_ID } from '../../services/ApiUrls';
import { apiRequest, handleError } from '../../services/Service';
import { getUrlWithSpecificRendition, showToast } from '../../utils/common';
import { useParams } from 'react-router-dom';
import UserProfilePhoto from '../../common/ui/user_profile_photo/UserProfilePhoto';
import HtmlView from '../../common/ui/html_view/HTMLView';
import firebase from 'firebase/compat/app';
import { selectEmployeeData } from '../../state/EmployeeData';
import dayjs from 'dayjs';
import { selectAccessData } from '../../state/UserAccessData';

const MiAuctionDetailsScreen = ({ history }) => {
  const { auctionId } = useParams();
  const dispatch = useDispatch();
  const accessibilityData = useSelector(selectAccessibilityData);

  const [auctionDetails, setAuctionDetails] = useState({});
  const [highestBidder, setHighestBidder] = useState({});
  const [isLandscape, setIsLandscape] = useState(true);
  const [auctionStatus, setAuctionStatus] = useState('soon');
  const [timer, setTimer] = useState('');
  const [bidsCount, setBidsCount] = useState(0);
  const [currentHighestBid, setCurrentHighestBid] = useState(0);
  const [nextBid, setNextBid] = useState(0);
  const employeeData = useSelector(selectEmployeeData);
  const accessData = useSelector(selectAccessData);
  const [showLikeButton, setShowLikeButton] = useState(true);
  const [showCommentButton, setShowCommentButton] = useState(true);
  const [showBid, setShowBid] = useState(true);
  const auctionAccessData = accessData?.data?.find((item) => item.feature_key === '_miAuction');

  useEffect(() => {
    const actions = auctionAccessData?.actions;
    setShowLikeButton(actions?.includes('_like'));
    setShowCommentButton(actions?.includes('_comment'));
    setShowBid(actions?.includes('_bid'));
  }, [auctionAccessData?.actions]);

  useEffect(() => {
    trackScreen(ANALYTICS_SCREEN_NAMES.MI_AUCTION_DETAILS);
  }, []);

  const callAuctionsDetails = useCallback(async () => {
    dispatch(
      saveProgressLoadingState({
        isProgressLoading: true,
      })
    );
    try {
      const apiResponse = await apiRequest(AUCTION + '/' + auctionId, REQUEST_TYPE.GET, {});
      if (Object.keys(apiResponse).length > 0) {
        if (apiResponse.response.status === true) {
          const data = apiResponse.response.data.item;
          setHighestBidder(data.highest_bidder);
          setAuctionDetails(data);
          setBidsCount(data.bids_count);
          setCurrentHighestBid(data.current_highest_bid);
          setNextBid(data.next_bid);
        }
      }
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: false,
        })
      );
    } catch (err) {
      handleError(err, {}, AUCTION + '/' + auctionId, NAVIGATION_ROUTES.MI_AUCTION);
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: false,
        })
      );
    }
  }, [dispatch, auctionId]);

  useEffect(() => {
    callAuctionsDetails();
  }, [callAuctionsDetails]);

  const callLikeApi = async (referId) => {
    dispatch(
      saveProgressLoadingState({
        isProgressLoading: true,
        progressMessage: LOADING_MESSAGES.LIKE_UPDATE,
      })
    );
    const params = new URLSearchParams();
    params.append('type', FEATURE_TYPE.MI_AUCTION);
    try {
      const apiResponse = await apiRequest(
        COMMENT_BY_ID + referId + '/like',
        REQUEST_TYPE.POST,
        params
      );
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: false,
        })
      );
      if (Object.keys(apiResponse).length > 0) {
        if (apiResponse.response.status === true) {
          if (apiResponse.response.data) {
            const data = apiResponse.response.data;
            const message = apiResponse.response.message;
            if (message) {
              showToast(message);
              let element = auctionDetails;
              element.liked = !element.liked;
              element.like_count = apiResponse.response.data.like_count;
              setAuctionDetails({ ...element });
            }
          }
        }
      }
    } catch (err) {
      handleError(err, params, COMMENT_BY_ID + referId + '/like', NAVIGATION_ROUTES.MI_AUCTION);
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: false,
        })
      );
    }
  };

  const onPressLikeButton = () => {
    logEvent(
      ANALYTICS_EVENT_TYPES.AUCTION_LIKE_CLICK,
      auctionDetails.item_id.toString(),
      ANALYTICS_ITEM_NAMES.LIKE
    );
    callLikeApi(auctionDetails.item_id);
  };

  const handleImageLoaded = ({ target: img }) => {
    setIsLandscape(img.offsetWidth > img.offsetHeight);
  };

  const getTimer = (date) => {
    const currentDateTime = dayjs();

    const timeDifference = date.diff(currentDateTime, 'ms');
    const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    const hoursDifference = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutesDifference = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
    const secondsDifference = Math.floor((timeDifference % (1000 * 60)) / 1000);

    let timerString = '';
    if (daysDifference) {
      timerString += `${daysDifference}d : `;
    }
    if (hoursDifference) {
      timerString += hoursDifference > 9 ? `${hoursDifference}h : ` : `0${hoursDifference}h : `;
    } else if (daysDifference) {
      timerString += '00h : ';
    }
    if (minutesDifference) {
      timerString +=
        minutesDifference > 9 ? `${minutesDifference}m : ` : `0${minutesDifference}m : `;
    } else if (daysDifference || hoursDifference) {
      timerString += '00m : ';
    }
    if (secondsDifference) {
      timerString += secondsDifference > 9 ? `${secondsDifference}s` : `0${secondsDifference}s`;
    } else if (daysDifference || hoursDifference || minutesDifference) {
      timerString += '00s';
    }
    return timerString;
  };

  const updateTimer = () => {
    const startDateObj = dayjs
      .utc(auctionDetails.bid_start_datetime)
      .subtract(330, 'minute')
      .local();
    const endDateObj = dayjs.utc(auctionDetails.bid_end_datetime).subtract(330, 'minute').local();
    const currentDate = dayjs();
    if (currentDate > endDateObj) {
      setAuctionStatus('expired');
    } else if (currentDate < startDateObj) {
      setAuctionStatus('soon');
      setTimer(getTimer(startDateObj));
    } else if (currentDate >= startDateObj && currentDate <= endDateObj) {
      setAuctionStatus('open');
      setTimer(getTimer(endDateObj));
    }
  };

  useEffect(() => {
    let dbListener;
    if (Object.keys(auctionDetails).length > 0 && auctionDetails.listener_endpoint) {
      dbListener = firebase
        .database()
        .ref(auctionDetails.listener_endpoint)
        .on('value', (snapshot) => {
          if (snapshot.exists()) {
            const data = snapshot.val();
            setBidsCount(data.bids_count);
            if (data.highest_bidder) {
              setHighestBidder(data.highest_bidder);
              setCurrentHighestBid(data.current_highest_bid);
            }
            setNextBid(data.next_bid);
          }
        });
    }
    return () => {
      if (dbListener !== undefined) {
        firebase.database().ref(auctionDetails.listener_endpoint).off('value', dbListener);
      }
    };
  }, [auctionStatus, auctionDetails]);

  useEffect(() => {
    if (Object.keys(auctionDetails).length > 0) {
      updateTimer();
      const timer = setInterval(() => {
        updateTimer();
      }, 1000);
      return () => clearInterval(timer);
    }
  }, [auctionDetails, auctionStatus]);

  const handleArtistClick = () => {
    logEvent(
      ANALYTICS_EVENT_TYPES.AUCTION_ARTIST_CLICK,
      auctionDetails.item_id.toString(),
      auctionDetails.artist?.employee_id ? 'INTERNAL_ARTIST' : 'EXTERNAL_ARTIST'
    );
    if (auctionDetails.artist?.employee_id) {
      if (auctionDetails.artist.employee_id === employeeData.employee_id) {
        history.push(NAVIGATION_ROUTES.MY_PROFILE_DETAIL);
      } else {
        history.push(
          `${NAVIGATION_ROUTES.EMPLOYEE_PROFILE_DETAIL}/${auctionDetails.artist.employee_id}`
        );
      }
    }
  };

  const goToTearmsAndConditions = () => {
    logEvent(
      ANALYTICS_EVENT_TYPES.AUCTION_MAKE_BID_BUTTON,
      auctionDetails.item_id.toString(),
      'MAKE_BID_DETAILS_SCREEN'
    );
    history.replace(NAVIGATION_ROUTES.MI_AUCTION_TC, {
      auctionDetails: { ...auctionDetails, next_bid: nextBid },
    });
  };

  const handleLikeCount = () => {
    if (auctionDetails.like_count > 0) {
      logEvent(
        ANALYTICS_EVENT_TYPES.AUCTION_LIKED_COUNT,
        auctionDetails.item_id.toString(),
        ANALYTICS_ITEM_NAMES.LIKE
      );
      history.push(NAVIGATION_ROUTES.LIKED_PEOPLE, {
        referId: auctionId,
        parentType: FEATURE_TYPE.MI_AUCTION,
      });
    }
  };

  const handleComment = () => {
    logEvent(
      ANALYTICS_EVENT_TYPES.AUCTION_COMMENT_CLICK,
      auctionDetails.item_id.toString(),
      ANALYTICS_ITEM_NAMES.COMMENT
    );
    history.push(NAVIGATION_ROUTES.COMMENTS, {
      referId: auctionId,
      likeType: FEATURE_TYPE.MI_AUCTION,
    });
  };

  return (
    <div className="container">
      <SectionHeader title={'MiAUCTION'} />
      {Object.keys(auctionDetails).length > 0 && (
        <div className="header-view justify-content-between">
          <BackButton isFullRow={false} />
          <div className="broadcast-header-row justify-content-end">
            {showLikeButton && (
              <div className="broadcast-icon-row mr-3">
                <img
                  src={getHeaderComponentUrl(
                    accessibilityData,
                    auctionDetails.liked
                      ? ACCESSIBILITY_HEADER_COMPONENTS.ACTIVE_LIKE
                      : ACCESSIBILITY_HEADER_COMPONENTS.INACTIVE_LIKE
                  )}
                  className="header-icon"
                  onClick={onPressLikeButton}
                />
                <div className="broadcast-icon-text" onClick={handleLikeCount}>
                  {auctionDetails.like_count}
                </div>
              </div>
            )}
            {showCommentButton && (
              <div className="broadcast-icon-row mr-3">
                <img
                  src={getHeaderComponentUrl(
                    accessibilityData,
                    ACCESSIBILITY_HEADER_COMPONENTS.COMMENT
                  )}
                  className="header-icon"
                  onClick={handleComment}
                />
                <div className="broadcast-icon-text" onClick={handleComment}>
                  {auctionDetails.comment_count}
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      {Object.keys(auctionDetails).length > 0 && (
        <div>
          <div className="d-flex flex-row mt-3 mb-3">
            <div className="width-45">
              <img
                src={getUrlWithSpecificRendition(
                  auctionDetails.image.base_url,
                  SCREEN_DENSITY_QUALIFIERS.XXXHDPI,
                  auctionDetails.image.image_path
                )}
                className="embed-responsive embed-responsive-12by7"
                onLoad={handleImageLoaded}
              />
            </div>
            <div className={`width-55 ${isLandscape && 'd-flex'}`}>
              <div className="auction-details-container p-2 w-100">
                <div
                  className={`auction-details-container-top ${
                    isLandscape
                      ? 'auction-details-top-container-height'
                      : 'auction-details-top-container-height-px'
                  }`}>
                  <div>
                    <div className="auction-name-category">
                      <div className="mi-auction-title">{auctionDetails.item_name}</div>
                      <div className="mi-auction-category-block">
                        {auctionDetails.category.category_name}
                      </div>
                    </div>
                    {auctionDetails.page_views > 0 && (
                      <div className="mi-auction-views-count">{`${auctionDetails.page_views} view${
                        auctionDetails.page_views > 1 ? 's' : ''
                      }`}</div>
                    )}
                  </div>
                  {(auctionDetails.artist.employee_name || auctionDetails.item_owner) && (
                    <div
                      className={`d-flex flex-row ${
                        auctionDetails.artist.employee_name ? 'common-cursor-pointer' : ''
                      }`}
                      onClick={handleArtistClick}>
                      <div className="mi-auction-artist">
                        Artist:&nbsp;
                        <span className="mi-auction-artist-value">
                          {auctionDetails.artist && auctionDetails.artist.employee_name
                            ? auctionDetails.artist.employee_name
                            : auctionDetails.item_owner}
                        </span>
                      </div>
                    </div>
                  )}
                  {auctionDetails.supporting && (
                    <div className="mi-auction-artist">
                      Supporting:&nbsp;
                      <span className="mi-auction-artist-value">{auctionDetails.supporting}</span>
                    </div>
                  )}
                  <div className="d-flex flex-row justify-content-between">
                    <div className="mi-auction-starting-price">
                      Starting price:&nbsp;
                      <span className="mi-auction-starting-price-value">{`${auctionDetails.currency.symbol} ${auctionDetails.starting_price} /-`}</span>
                    </div>

                    <div className="mi-auction-starting-price">
                      Bids:&nbsp;
                      <span className="mi-auction-starting-price-value">{bidsCount}</span>
                    </div>
                  </div>
                </div>
                <div
                  className={`timer-bidder-details pt-2 ${
                    isLandscape ? 'timer-bidder-details-height' : 'timer-bidder-details-height-px'
                  }`}>
                  {Object.keys(highestBidder).length > 0 && (
                    <div className="w-50 highest-bidder-block">
                      <div
                        className={
                          auctionStatus === 'expired' ? 'auction-winner' : 'current-highest-bid'
                        }>
                        {auctionStatus === 'expired' ? 'Winning bid' : 'Current Highest Bid'}
                      </div>
                      <div className="highest-bidder-details">
                        <UserProfilePhoto
                          imageBaseUrl={highestBidder.profile_picture.base_url}
                          imagePath={highestBidder.profile_picture.image_path}
                          imageClass="common-user-profile-photo-sm"
                          employeeId={highestBidder.employee_id}
                        />
                        <div className="ml-2">
                          <div className="highest-bidder-name">{highestBidder.employee_name}</div>
                          <div className="highest-bidder-location">{highestBidder.location}</div>
                          <div className="highest-bidder-bid-price">{`${auctionDetails.currency.symbol} ${currentHighestBid} /-`}</div>
                        </div>
                      </div>
                    </div>
                  )}
                  {(auctionStatus === 'soon' || auctionStatus === 'open') && timer ? (
                    <div
                      className={`${
                        Object.keys(highestBidder).length > 0 ? 'w-50' : 'w-100'
                      } auction-timer-make-bid-block`}>
                      <div>
                        <div className="auction-details-start-timer mb-2">
                          <div>
                            {auctionStatus === 'soon' ? 'AUCTION STARTS IN' : 'AUCTION ENDS IN'}
                          </div>
                          <div className="auction-timer-text">{timer}</div>
                        </div>
                        {auctionStatus === 'open' && showBid && (
                          <div
                            className="auction-make-bid-button common-cursor-pointer"
                            onClick={goToTearmsAndConditions}>
                            <div className="make-bid-button-title">{`MAKE A BID ${auctionDetails.currency.symbol} ${nextBid} /-`}</div>
                          </div>
                        )}
                        {auctionStatus === 'soon' && (
                          <div className="expired-bid-button">
                            <div className="starting-soon-button-title">STARTING SOON</div>
                          </div>
                        )}
                      </div>
                    </div>
                  ) : (
                    auctionStatus === 'expired' && (
                      <div className="w-50 p-3">
                        {auctionDetails.current_highest_bid <= 0 && (
                          <div className="expired-bid-button">
                            <div className="expired-button-title">EXPIRED</div>
                          </div>
                        )}
                      </div>
                    )
                  )}
                </div>
              </div>
              {!isLandscape && (
                <div className="p-2">
                  <HtmlView
                    html={auctionDetails.item_description}
                    htmlStyle="mi-auction-description-text"
                  />
                </div>
              )}
            </div>
          </div>
          {isLandscape && (
            <div className="p-2">
              <HtmlView
                html={auctionDetails.item_description}
                htmlStyle="mi-auction-description-text"
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default MiAuctionDetailsScreen;
