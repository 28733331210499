import React, { useCallback, useEffect, useState } from 'react';
import HtmlView from '../../common/ui/html_view/HTMLView';
import {
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  ANALYTICS_SCREEN_NAMES,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
} from '../../common/constants/AppConstants';
import { useDispatch, useSelector } from 'react-redux';
import SectionHeader from '../../common/ui/section_header/SectionHeader';
import { apiRequestEStore, apiRequestUser } from '../../services/Service';
import { saveProgressLoadingState } from '../../state/UIState';
import BackButtonStore from '../../common/ui/common_back_button/BackButtonStore';
import { useHistory } from 'react-router';
import CustomButton from '../../common/ui/custom_button/CustomButton';
import { showToast } from '../../utils/common';
import { logEvent, trackScreen } from '../../utils/FirebaseAnalyticsUtils';
import {
  CARTS_MINE_ORDER,
  GET_ESTORE_POLICY_BY_ID,
  PAYMENT_INFORMATION,
} from '../../services/ApiUrls';
import { saveSegments } from '../../state/EStoreData';
import { selectEStoreConfig } from '../../state/MasterData';

const OrderTermsAndConditions = ({ location }) => {
  const dispatch = useDispatch();
  const [content, setContent] = useState('');
  const history = useHistory();
  const { carrierCode } = location.state;
  const [totalPrice, setTotalPrice] = useState(0);
  const eStoreConfig = useSelector(selectEStoreConfig);

  useEffect(() => {
    trackScreen(
      ANALYTICS_SCREEN_NAMES.MICRO_STORE_ORDER_TC_SCREEN,
      NAVIGATION_ROUTES.MI_STORE_ORDER_TC
    );
  }, []);

  const fetchContent = useCallback(async () => {
    dispatch(
      saveProgressLoadingState({
        isProgressLoading: true,
      })
    );
    try {
      const [apiResponse, paymentInfo] = await Promise.all([
        apiRequestEStore(`${GET_ESTORE_POLICY_BY_ID}9`, REQUEST_TYPE.GET),
        apiRequestUser(PAYMENT_INFORMATION, REQUEST_TYPE.GET),
      ]);
      if (Object.keys(apiResponse).length) {
        if (apiResponse.response) {
          const content = apiResponse.response.content;
          // setContent(content.replace('{{amount}}', totalPrice));
          setContent(content);
        }
      }
      if (
        Object.keys(paymentInfo.response).length > 0 &&
        paymentInfo.response?.totals?.total_segments
      ) {
        const segments = paymentInfo.response?.totals?.total_segments;
        const grandTotal = segments.find((segment) => segment.code === 'grand_total')?.value;
        dispatch(saveSegments(segments));
        setTotalPrice(grandTotal);
      }
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: false,
        })
      );
    } catch (err) {
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: false,
        })
      );
    }
  }, [dispatch]);

  useEffect(() => {
    fetchContent();
  }, [fetchContent]);

  const placeOrder = async () => {
    dispatch(
      saveProgressLoadingState({
        isProgressLoading: true,
      })
    );
    try {
      logEvent(
        ANALYTICS_EVENT_TYPES.ESTORE_TERM_AND_CONDITION,
        '',
        ANALYTICS_ITEM_NAMES.TERMS_AND_CONDITIONS
      );
      const [paymentInfo, apiResponse] = await Promise.all([
        apiRequestUser(PAYMENT_INFORMATION, REQUEST_TYPE.GET),
        apiRequestUser(CARTS_MINE_ORDER, REQUEST_TYPE.PUT, {
          paymentMethod: {
            method: 'checkmo',
          },
        }),
      ]);

      if (
        Object.keys(paymentInfo.response).length > 0 &&
        paymentInfo.response?.totals?.total_segments
      ) {
        const segments = paymentInfo.response?.totals?.total_segments;
        const grandTotal = segments.find((segment) => segment.code === 'grand_total')?.value;
        dispatch(saveSegments(segments));
        setTotalPrice(grandTotal);
      }

      if (Object.keys(apiResponse).length) {
        if (apiResponse.response.status === false) {
          showToast(`${apiResponse.response.message} go back to your bag`);
        } else if (apiResponse.response > 0) {
          history.replace(NAVIGATION_ROUTES.MI_STORE_ORDER_SUCCESS, {
            orderId: apiResponse.response,
            carrierCode,
          });
        }
      }
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: false,
        })
      );
    } catch (err) {
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: false,
        })
      );
      showToast(eStoreConfig.place_order_error);
    }
  };

  return (
    <div className="container">
      <SectionHeader identifier={ACCESSIBILITY_IDENTIFIERS.MICRO_STORE} />
      <BackButtonStore backTitle={'TERMS & CONDITIONS'} />
      <div className="order-tc-container">
        <div className="e-store-cart-block order-tc-content-container">
          <div className="estore-tc-items-container mb-2">
            <div className="pb-2 delivery-estimate-header mb-1">
              <div className="d-flex estore-regular-text">
                Total Amount:
                <div className="ml-1 estore-bold-text">{`Rs. ${totalPrice}/-`}</div>
              </div>
            </div>
            <HtmlView
              html={content
                .replace(/{{amount}}/g, `${totalPrice}`)
                .replace(/&lt;/g, '<')
                .replace(/&gt;/g, '>')}
              htmlStyle="estore-regular-text-16-tc pt-2"
            />
          </div>
          <div className="estore-tc-place-order-button w-100">
            <CustomButton buttonStyle="estore-tc-place-ordeer-button" onClick={placeOrder}>
              I AGREE, PLACE MY ORDER
            </CustomButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderTermsAndConditions;
