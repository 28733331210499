import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectAnniversaryConfig } from '../../state/MasterData';
import CentralTab from './tabs/anniversaryTabs/CentralTab';
import ChiefMessageTab from './tabs/anniversaryTabs/ChiefMessageTab';
import CommentsTab from './tabs/anniversaryTabs/CommentsTab';
import MomentsTab from './tabs/anniversaryTabs/MomentsTab';
import SuperTab from './tabs/anniversaryTabs/SuperTab';
import { Tab } from 'react-bootstrap';
import { saveAnniversaryTabData, selectAnniversaryTabData } from '../../state/TabData';
import {
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_SCREEN_NAMES,
  ML_DAY_IDENTIFIERS,
  NAVIGATION_ROUTES,
} from '../../common/constants/AppConstants';
import SectionHeader from '../../common/ui/section_header/SectionHeader';
import { trackScreen } from '../../utils/FirebaseAnalyticsUtils';
import { sortBySequence } from '../../utils/common';
import NavTabs from '../../common/ui/tabs/NavTabs';
import ContestListTab from './tabs/anniversaryTabs/ContestListTab';
import AnniversaryGalleryTab from './tabs/anniversaryTabs/AnniversaryGalleryTab';
import BackButton from '../../common/ui/common_back_button/BackButton';
import MlDayPollsAndSurveysTab from '../esg/tabs/MlDayPollsAndSurveysTab';
import dayjs from 'dayjs';
import { saveProgressLoadingState, selectProgressLoadingState } from '../../state/UIState';
import { useParams } from 'react-router-dom';
import GalleryTab from '../breakouts/tabs/GalleryTab';

const AnniversaryScreen = ({ location }) => {
  const dispatch = useDispatch();
  const { identifier } = useParams();
  const [tabs, setTabs] = useState([]);
  const anniversaryConfig = useSelector(selectAnniversaryConfig).find(
    (element) => element.identifier === identifier
  );
  const anniversaryTabData = useSelector(selectAnniversaryTabData);

  const [showHideBeforeEventScenario, setShowHideBeforeEventScenario] = useState(false);
  const progressLoadingState = useSelector(selectProgressLoadingState);

  const getMilliSecondDifference = useCallback(() => {
    if (anniversaryConfig.config) {
      let streamingDate = JSON.parse(anniversaryConfig.config).streaming_date;
      let streamingStartTime = JSON.parse(anniversaryConfig.config).streaming_start_time;

      const convertedDate = `${streamingDate.split('-')[2]}/${streamingDate.split('-')[1]}/${
        streamingDate.split('-')[0]
      }`;
      const convertedTimestamp = `${convertedDate} ${streamingStartTime}:00`;
      const convertedUtcTimestamp = dayjs.utc(convertedTimestamp).subtract(330, 'minute');
      const convertedLocalTimestamp = convertedUtcTimestamp.local();
      const todayTimestamp = dayjs();
      const differenceMilliSeconds = convertedLocalTimestamp.diff(todayTimestamp, 'ms');
      return differenceMilliSeconds;
    }
  }, [anniversaryConfig?.config]);

  useEffect(() => {
    if (anniversaryConfig && anniversaryConfig.config) {
      let enableWatchVideo = JSON.parse(anniversaryConfig.config).enable_watch_video;
      if (enableWatchVideo && enableWatchVideo === true) {
        let milliSeconds = getMilliSecondDifference();

        if (milliSeconds < 0) {
          setShowHideBeforeEventScenario(false);
        } else {
          setShowHideBeforeEventScenario(true);
        }
      }
    }
  }, [anniversaryConfig, getMilliSecondDifference]);

  useEffect(() => {
    trackScreen(ANALYTICS_SCREEN_NAMES.ANNIVERSARY_SCREEN, NAVIGATION_ROUTES.ANNIVERSARY_SCREEN);
  }, []);

  useEffect(() => {
    let timeoutId;
    if (!showHideBeforeEventScenario) {
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: true,
        })
      );
      timeoutId = setTimeout(() => {
        handleModifiedLogic();
        dispatch(
          saveProgressLoadingState({
            isProgressLoading: false,
          })
        );
      }, 1);
    } else {
      handleModifiedLogic();
    }

    return () => {
      clearTimeout(timeoutId);
      dispatch(saveProgressLoadingState(false));
    };
  }, [anniversaryConfig, anniversaryTabData, dispatch, identifier, showHideBeforeEventScenario]);

  const handleModifiedLogic = () => {
    if (anniversaryConfig && anniversaryConfig.tabs && anniversaryConfig.tabs.length) {
      let tempArr = sortBySequence(anniversaryConfig.tabs).filter(
        (item) => item.status === 'active'
      );

      if (showHideBeforeEventScenario) {
        tempArr = tempArr.filter((item) => item.before_scenario === true);
      }

      setTabs(tempArr);

      if (anniversaryTabData === '') {
        if (showHideBeforeEventScenario) {
          if (tempArr.length > 1) {
            dispatch(saveAnniversaryTabData(tempArr[0].key));
          } else {
            dispatch(saveAnniversaryTabData(tempArr[0]?.key || ''));
          }
        } else {
          if (location?.state?.tabKey) {
            let index = tempArr.findIndex((item) => item.key === location.state.tabKey);
            dispatch(
              saveAnniversaryTabData(index !== -1 ? location.state.tabKey : tempArr[0]?.key || '')
            );
          } else {
            dispatch(saveAnniversaryTabData(tempArr[0]?.key || ''));
          }
        }
      }
    }
  };

  const handleChiefsTab = () => {
    if (anniversaryConfig && anniversaryConfig.config) {
      let enableWatchVideo = JSON.parse(anniversaryConfig.config).enable_watch_video;
      if (enableWatchVideo && enableWatchVideo === true) {
        let milliSeconds = getMilliSecondDifference();

        if (milliSeconds < 0) {
          setShowHideBeforeEventScenario(false);
        } else {
          setShowHideBeforeEventScenario(true);
          handleModifiedLogic();
        }
      }
    }
  };

  const renderTabs = (tab) => {
    switch (tab.key) {
      case 'chiefMessage':
        return <ChiefMessageTab identifier={identifier} />;
      case 'central':
        return <CentralTab identifier={identifier} handleChiefsTab={handleChiefsTab} />;
      case 'moments':
        return <MomentsTab identifier={identifier} />;
      case 'comments':
        return <CommentsTab identifier={identifier} />;
      case 'contests':
        return <ContestListTab identifier={identifier} />;
      case 'gallery':
        if (identifier === ML_DAY_IDENTIFIERS.ML_35_IDENTIFIER) {
          return <GalleryTab identifier={identifier} />;
        } else {
          return (
            <AnniversaryGalleryTab
              identifier={identifier}
              loading={progressLoadingState.isProgressLoading}
            />
          );
        }
      case 'super':
        return <SuperTab identifier={identifier} />;
      case 'polls&surveys':
        return <MlDayPollsAndSurveysTab identifier={identifier} />;
    }
  };

  return (
    <div className="container">
      <SectionHeader identifier={ACCESSIBILITY_IDENTIFIERS.ANNIVERSARY_DAY} />
      <BackButton />
      <NavTabs
        activeKey={anniversaryTabData}
        onSelect={(k) => dispatch(saveAnniversaryTabData(k))}
        unmountOnExit>
        {tabs.map((tab, index) => {
          return (
            <Tab key={`${index}`} eventKey={tab.key} title={tab.title}>
              {renderTabs(tab, index)}
            </Tab>
          );
        })}
      </NavTabs>
    </div>
  );
};

export default AnniversaryScreen;
