import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectAnniversaryConfig } from '../../state/MasterData';
import {
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  ANALYTICS_SCREEN_NAMES,
  NAVIGATION_ROUTES,
} from '../../common/constants/AppConstants';

import WallpapersListItem from './components/WallpapersListItem';
import SectionHeader from '../../common/ui/section_header/SectionHeader';
import { logEvent2, trackScreen } from '../../utils/FirebaseAnalyticsUtils';
import ShareEmailModal from '../../common/components/shareEmailModal';
import BackButton from '../../common/ui/common_back_button/BackButton';

const WallpapersScreen = ({ location, history }) => {
  const { identifier } = location.state;

  const [wallPapersList, setWappPapersList] = useState([]);
  const shareEmailImageUrl = useRef('');
  const [showEmailModal, setShowEmailModal] = useState(false);

  const anniversaryConfig = useSelector(selectAnniversaryConfig).find(
    (element) => element.identifier === identifier
  );

  const shareImageUrlOverEmail = (imageUrl) => {
    shareEmailImageUrl.current = imageUrl;
    setShowEmailModal(true);
  };

  useEffect(() => {
    trackScreen(ANALYTICS_SCREEN_NAMES.WALL_PAPERS_SCREEN, NAVIGATION_ROUTES.WALL_PAPERS_SCREEN);
  }, []);

  useEffect(() => {
    if (
      anniversaryConfig &&
      anniversaryConfig.wallpapers &&
      anniversaryConfig.wallpapers.length > 0
    ) {
      setWappPapersList(anniversaryConfig.wallpapers);
    }
  }, [anniversaryConfig, identifier]);

  const onPress = (item, index) => {
    history.push(NAVIGATION_ROUTES.WALLPAPER_CONTENT_SCREEN, {
      cards: wallPapersList,
      cardIndex: index,
      identifier: identifier,
    });
  };

  return (
    <div className="container">
      <SectionHeader identifier={ACCESSIBILITY_IDENTIFIERS.ANNIVERSARY_DAY} />
      <BackButton />
      <div className="main-content-container">
        <div className="row">
          {wallPapersList.map((item, index) => {
            return (
              <WallpapersListItem
                item={item}
                key={`${index}`}
                index={index}
                onPress={onPress}
                shareImageUrlOverEmail={shareImageUrlOverEmail}
              />
            );
          })}
        </div>
      </div>
      <ShareEmailModal
        showModal={showEmailModal}
        imageUrl={shareEmailImageUrl.current}
        hideModal={() => setShowEmailModal(false)}
        logEvent={() => {
          logEvent2({
            eventType: ANALYTICS_EVENT_TYPES.WALLPAPERS_SHARE,
            itemId: '',
            itemName: '',
            categoryId: ANALYTICS_ITEM_NAMES.ANNIVERSARY,
            categoryName: ANALYTICS_ITEM_NAMES.ANNIVERSARY,
          });
        }}
      />
    </div>
  );
};

export default WallpapersScreen;
