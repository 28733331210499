import React, { useEffect, useState } from 'react';
import { getUrlWithSpecificRendition } from '../../../utils/common';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  FEATURE_TYPE,
  NAVIGATION_ROUTES,
  SCREEN_DENSITY_QUALIFIERS,
} from '../../../common/constants/AppConstants';
import { getFeatureComponentUrl, getHeaderComponentUrl } from '../../../utils/accessibility';
import { useSelector } from 'react-redux';
import { selectAccessibilityData } from '../../../state/AccessibilityData';
import { useHistory } from 'react-router-dom';
import dayjs from 'dayjs';
import { logEvent } from '../../../utils/FirebaseAnalyticsUtils';

const AuctionItem = ({
  auction,
  onPressLikeButton,
  onPressCommentButton,
  showLikeButton,
  showCommentButton,
  showBid,
}) => {
  const accessibilityData = useSelector(selectAccessibilityData);
  const history = useHistory();
  const [auctionStatus, setAuctionStatus] = useState('soon');
  const [timer, setTimer] = useState('');

  const handleAuction = () => {
    logEvent(
      ANALYTICS_EVENT_TYPES.AUCTION_ITEM_CLICK,
      auction.item_id.toString(),
      ANALYTICS_ITEM_NAMES.AUCTION_ITEM
    );
    history.push(`${NAVIGATION_ROUTES.MI_AUCTION}/${auction.item_id}`, {
      fromRoute: NAVIGATION_ROUTES.MI_AUCTION,
    });
  };

  const getTimer = (date) => {
    const currentDateTime = dayjs();

    const timeDifference = date.diff(currentDateTime, 'ms');
    const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    const hoursDifference = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutesDifference = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
    const secondsDifference = Math.floor((timeDifference % (1000 * 60)) / 1000);

    let timerString = '';
    if (daysDifference) {
      timerString += `${daysDifference}d `;
    }
    if (hoursDifference) {
      timerString += hoursDifference > 9 ? `${hoursDifference}h ` : `0${hoursDifference}h `;
    } else if (daysDifference) {
      timerString += '00h ';
    }
    if (minutesDifference) {
      timerString += minutesDifference > 9 ? `${minutesDifference}m` : `0${minutesDifference}m`;
    } else if (daysDifference || hoursDifference) {
      timerString += '00m';
    }

    if (timerString.length <= 3) {
      timerString += secondsDifference > 9 ? ` ${secondsDifference}s` : ` 0${secondsDifference}s`;
    }

    return timerString;
  };

  const updateTimer = () => {
    const startDateObj = dayjs.utc(auction.bid_start_datetime).subtract(330, 'minute').local();
    const endDateObj = dayjs.utc(auction.bid_end_datetime).subtract(330, 'minute').local();
    const currentDate = dayjs();
    if (currentDate > endDateObj) {
      setAuctionStatus('expired');
    } else if (currentDate < startDateObj) {
      setAuctionStatus('soon');
      const timer = getTimer(startDateObj);
      setTimer(timer);
    } else if (currentDate >= startDateObj && currentDate <= endDateObj) {
      setAuctionStatus('open');
      const timer = getTimer(endDateObj);
      setTimer(timer);
    }
  };

  useEffect(() => {
    updateTimer();
    const timer = setInterval(() => {
      updateTimer();
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  const getStatus = () => {
    return auctionStatus === 'expired' && auction.current_highest_bid > 0
      ? `SOLD AT ${auction.currency.symbol} ${auction.current_highest_bid} /-`
      : auctionStatus === 'soon'
      ? 'STARTING SOON'
      : auctionStatus === 'open'
      ? `BID NOW ${auction.currency.symbol} ${auction.next_bid} /-`
      : 'EXPIRED';
  };

  const handleLikeCount = () => {
    logEvent(
      ANALYTICS_EVENT_TYPES.AUCTION_LIKED_COUNT,
      auction.item_id.toString(),
      ANALYTICS_ITEM_NAMES.LIKE
    );
    history.push(NAVIGATION_ROUTES.LIKED_PEOPLE, {
      referId: auction.item_id,
      parentType: FEATURE_TYPE.MI_AUCTION,
    });
  };

  const renderLikeCommentView = () => {
    return (
      <div className="d-flex align-items-center mt-1">
        {showLikeButton && (
          <>
            {auction.liked ? (
              <div onClick={(e) => onPressLikeButton(e, auction)}>
                <img
                  src={getHeaderComponentUrl(
                    accessibilityData,
                    ACCESSIBILITY_FEATURE_COMPONENTS.COMMUNITIES_ACTIVE_LIKE,
                    ACCESSIBILITY_IDENTIFIERS.COMMUNITIES
                  )}
                  className="mi-auction-normal-icon"
                />
              </div>
            ) : (
              <div onClick={(e) => onPressLikeButton(e, auction)}>
                <img
                  src={getHeaderComponentUrl(
                    accessibilityData,
                    ACCESSIBILITY_FEATURE_COMPONENTS.COMMUNITIES_INACTIVE_LIKE,
                    ACCESSIBILITY_IDENTIFIERS.COMMUNITIES
                  )}
                  className="mi-auction-normal-icon common-cursor-pointer"
                />
              </div>
            )}
            {auction.like_count > 0 ? (
              <div
                className="ml-2 mi-auction-count common-cursor-pointer"
                onClick={handleLikeCount}>
                {auction.like_count}
              </div>
            ) : (
              <div className="ml-2 mi-auction-count common-cursor-pointer"> 0 </div>
            )}
          </>
        )}
        {showCommentButton && (
          <div
            className="communities-flex ml-3 mr-3 common-cursor-pointer"
            onClick={(e) => onPressCommentButton(e, auction)}>
            <img
              src={getFeatureComponentUrl(
                accessibilityData,
                ACCESSIBILITY_FEATURE_COMPONENTS.COMMUNITIES_POST_COMMENT,
                ACCESSIBILITY_IDENTIFIERS.COMMUNITIES
              )}
              className="mi-auction-normal-icon"
            />
            <div className="ml-2 mi-auction-count">
              {auction.comment_count > 0 ? auction.comment_count : '0'}
            </div>
          </div>
        )}
        {auction.page_views > 0 && (
          <div className="mi-auction-count">{`${auction.page_views} view${
            auction.page_views > 1 ? 's' : ''
          }`}</div>
        )}
      </div>
    );
  };
  return (
    <div className="mi-auction-list-item common-cursor-pointer" onClick={handleAuction}>
      <div>
        <img
          src={getUrlWithSpecificRendition(
            auction.image.base_url,
            SCREEN_DENSITY_QUALIFIERS.XXXHDPI,
            auction.image.image_path
          )}
          className="embed-responsive embed-responsive-12by7"
        />
      </div>
      <div className="mi-auction-item-details-container">
        <div className="mi-auction-name-views-likes-category-block">
          <div className="mi-auction-title">{auction.item_name}</div>
          <div className="mi-auction-like-comment-views-category">
            <div className="d-flex d-row">{renderLikeCommentView(auction)}</div>
            <div className="mi-auction-category-block">{auction.category.category_name}</div>
          </div>
        </div>
        <div className="price-bids-timer-view">
          <div className="mi-auction-regular-text">
            Starting Price&nbsp;
            <span className="mi-auction-semibold-text">{`${auction.currency.symbol} ${auction.starting_price}/-`}</span>
          </div>
          {auctionStatus !== 'expired' ? (
            <div className="mi-auctionbids-timer-view">
              <div className="mi-auction-regular-text">
                Bids:&nbsp;
                <span className="mi-auction-semibold-text">{auction.bids_count}</span>
              </div>

              <div className="mi-auction-regular-text">
                {`${auctionStatus === 'open' ? 'Ends in:' : 'Starts in:'}`}&nbsp;
                <span className="mi-auction-semibold-text">{timer}</span>
              </div>
            </div>
          ) : (
            <div className="mi-auction-regular-text">
              Bids:&nbsp;
              <span className="mi-auction-semibold-text">{auction.bids_count}</span>
            </div>
          )}
        </div>
        {auctionStatus === 'open' && showBid === false ? null : (
          <div
            className={`auction-bid-now-button ${
              auctionStatus === 'expired' ? 'action-expired-color' : 'action-active-color'
            }`}>
            {getStatus()}
          </div>
        )}
      </div>
    </div>
  );
};

export default AuctionItem;
