import React, { useCallback, useEffect, useState } from 'react';
import SectionHeader from '../../common/ui/section_header/SectionHeader';
import BackButton from '../../common/ui/common_back_button/BackButton';
import {
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  ANALYTICS_SCREEN_NAMES,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
} from '../../common/constants/AppConstants';
import { useDispatch, useSelector } from 'react-redux';
import { resetLoaderState, saveProgressLoadingState } from '../../state/UIState';
import { apiRequestEStore, apiRequestUser } from '../../services/Service';
import { saveCartItemsCount, saveSegments, selectTempAddress } from '../../state/EStoreData';
import CustomButton from '../../common/ui/custom_button/CustomButton';
import { useHistory } from 'react-router';
import { selectEStoreConfig } from '../../state/MasterData';
import PriceInformationTable from './components/PriceInformationTable';
import DeliveryNote from './components/DeliveryNote';
import { logEvent, trackScreen } from '../../utils/FirebaseAnalyticsUtils';
import {
  CARTS_MINE_ITEMS,
  CUSTOMERS_ME,
  ESTIMATE_SHIPPING_METHODS,
  GET_PRODUCT_IMAGES,
  INVENTORY_LEFT,
  PAYMENT_INFORMATION,
  SHIPPING_INFORMATION,
} from '../../services/ApiUrls';
import { showToast } from '../../utils/common';

const DeliveryEstimateScreen = () => {
  const [defaultAddress, setDefaultAddress] = useState({});
  const [carrierCode, setCarrierCode] = useState('flatrate');
  const dispatch = useDispatch();
  const tempAddress = useSelector(selectTempAddress);
  const history = useHistory();
  const estoreConfig = useSelector(selectEStoreConfig);
  const [isOutOfStock, setIsOutOfStock] = useState(false);
  const [cartItems, setCartItems] = useState([]);
  const [loaders, setLoaders] = useState([]);
  const [email, setEmail] = useState('');

  useEffect(() => {
    trackScreen(
      ANALYTICS_SCREEN_NAMES.MICRO_STORE_REVIEW_ORDER_SCREEN,
      NAVIGATION_ROUTES.MI_STORE_CONFIRM_ADDERSS
    );
  }, []);

  const fetchItemImages = useCallback(async (skuList, cartItems) => {
    setLoaders((prev) => [...prev, 1]);
    try {
      const [apiResponse, inventoryLeft] = await Promise.all([
        apiRequestEStore(GET_PRODUCT_IMAGES, REQUEST_TYPE.POST, {
          skus: skuList,
        }),
        apiRequestEStore(INVENTORY_LEFT, REQUEST_TYPE.POST, {
          skus: skuList,
        }),
      ]);
      const tempCartItems = [];
      let isAnyItemOutOfStock = false;
      cartItems.map((item) => {
        const img = apiResponse.response?.find((res) => res.sku === item.sku);
        let size = '';
        if (img.size_value && img.attributes.length) {
          const sizeObj = img.attributes?.find((attr) => attr.label === 'Size');
          sizeObj.attributes_map[`${sizeObj.id}:${img.size_value}`];
          size = sizeObj.attributes_map[`${sizeObj.id}:${img.size_value}`].label;
        }
        const quantity = inventoryLeft.response?.find((res) => res.sku === item.sku)?.qty;
        if (!isAnyItemOutOfStock && quantity === 0) {
          isAnyItemOutOfStock = true;
        }
        tempCartItems.push({
          ...item,
          image: img?.image ?? '',
          size,
          shortDescription: img?.short_description,
          quantity,
          parentSku: img.parent_sku,
        });
      });
      setIsOutOfStock(isAnyItemOutOfStock);
      setCartItems(tempCartItems);

      setLoaders((prev) => [...prev].slice(0, -1));
    } catch (err) {
      setLoaders((prev) => [...prev].slice(0, -1));
    }
  }, []);

  const fetchPaymentInformation = useCallback(async () => {
    setLoaders((prev) => [...prev, 1]);
    try {
      const apiResponse = await apiRequestUser(PAYMENT_INFORMATION, REQUEST_TYPE.GET);
      if (
        Object.keys(apiResponse.response).length > 0 &&
        apiResponse.response?.totals?.total_segments
      ) {
        dispatch(saveSegments(apiResponse.response?.totals?.total_segments));
      }
      setLoaders((prev) => [...prev].slice(0, -1));
    } catch (err) {
      setLoaders((prev) => [...prev].slice(0, -1));
    }
  }, [dispatch]);

  const confirmAddresses = useCallback(
    async (method) => {
      setCarrierCode(method ? 'tablerate' : 'flatrate');
      setLoaders((prev) => [...prev, 1]);
      try {
        let adderss = {};
        if (Object.keys(tempAddress).length > 0) {
          adderss = {
            region: tempAddress.region.region,
            region_id: tempAddress.region.region_id,
            region_code: tempAddress.region.region_code,
            country_id: tempAddress.country_id,
            street: tempAddress.street,
            postcode: tempAddress.postcode,
            city: tempAddress.city,
            firstname: tempAddress.firstname,
            lastname: tempAddress.lastname,
            email: email,
            telephone: tempAddress.telephone,
          };
        } else if (Object.keys(defaultAddress).length > 0) {
          adderss = {
            region: defaultAddress.region.region,
            region_id: defaultAddress.region.region_id,
            region_code: defaultAddress.region.region_code,
            country_id: defaultAddress.country_id,
            street: defaultAddress.street,
            postcode: defaultAddress.postcode,
            city: defaultAddress.city,
            firstname: defaultAddress.firstname,
            lastname: defaultAddress.lastname,
            email: email,
            telephone: defaultAddress.telephone,
          };
        }
        const body = {
          addressInformation: {
            shipping_address: adderss,
            billing_address: adderss,
            shipping_method_code: method ? 'bestway' : 'flatrate',
            shipping_carrier_code: method ? 'tablerate' : 'flatrate',
          },
        };
        const apiResponse = await apiRequestUser(SHIPPING_INFORMATION, REQUEST_TYPE.POST, body);
        if (Object.keys(apiResponse.response).length) {
          if (apiResponse.response.message) {
            showToast(apiResponse.response.message);
          } else {
            fetchPaymentInformation();
          }
        }
        setLoaders((prev) => [...prev].slice(0, -1));
      } catch (err) {
        setLoaders((prev) => [...prev].slice(0, -1));
      }
    },
    [defaultAddress, email, fetchPaymentInformation, tempAddress]
  );

  const estimateShippingMethods = useCallback(async () => {
    setLoaders((prev) => [...prev, 1]);
    try {
      let address = {};
      if (Object.keys(tempAddress).length > 0) {
        address = {
          region: tempAddress.region.region,
          region_id: tempAddress.region.region_id,
          region_code: tempAddress.region.region_code,
          country_id: tempAddress.country_id,
          street: tempAddress.street,
          postcode: tempAddress.postcode,
          city: tempAddress.city,
          firstname: tempAddress.firstname,
          lastname: tempAddress.lastname,
          email: email,
          telephone: tempAddress.telephone,
        };
      } else if (Object.keys(defaultAddress).length > 0) {
        address = {
          region: defaultAddress.region.region,
          region_id: defaultAddress.region.region_id,
          region_code: defaultAddress.region.region_code,
          country_id: defaultAddress.country_id,
          street: defaultAddress.street,
          postcode: defaultAddress.postcode,
          city: defaultAddress.city,
          firstname: defaultAddress.firstname,
          lastname: defaultAddress.lastname,
          email: email,
          telephone: defaultAddress.telephone,
        };
      }
      const body = {
        address,
      };
      const apiResponse = await apiRequestUser(ESTIMATE_SHIPPING_METHODS, REQUEST_TYPE.POST, body);
      if (Object.keys(apiResponse.response).length > 0) {
        const tableRate = apiResponse.response.find(
          (method) => method.carrier_code === 'tablerate'
        );
        confirmAddresses(tableRate);
      }
      setLoaders((prev) => [...prev].slice(0, -1));
    } catch (err) {
      setLoaders((prev) => [...prev].slice(0, -1));
    }
  }, [confirmAddresses, defaultAddress, email, tempAddress]);

  useEffect(() => {
    if (
      email !== '' &&
      (Object.keys(tempAddress).length > 0 || Object.keys(defaultAddress).length > 0) &&
      cartItems.length > 0
    ) {
      estimateShippingMethods();
    }
  }, [cartItems.length, estimateShippingMethods, defaultAddress, email, tempAddress]);

  const fetchContent = useCallback(async () => {
    setLoaders((prev) => [...prev, 1]);
    try {
      dispatch(saveCartItemsCount(0));
      const [apiResponse, addressesResponse] = await Promise.all([
        apiRequestUser(CARTS_MINE_ITEMS, REQUEST_TYPE.GET),
        apiRequestUser(CUSTOMERS_ME, REQUEST_TYPE.GET),
      ]);
      if (Object.keys(apiResponse).length && apiResponse.response?.length) {
        if (apiResponse.response) {
          let itemsCount = 0;
          apiResponse.response.map((item) => {
            itemsCount += item.qty;
          });
          dispatch(saveCartItemsCount(itemsCount));
          const skuList = [];
          apiResponse.response.map((item) => {
            skuList.push(item.sku);
          });

          if (apiResponse.response.length) {
            fetchItemImages(skuList, apiResponse.response);
          } else {
            setCartItems([]);
          }
        }
      }
      if (Object.keys(addressesResponse.response).length) {
        setEmail(addressesResponse.response.email);
        addressesResponse.response?.addresses.map((address) => {
          if (address.default_shipping) {
            setDefaultAddress(address);
          }
        });
      }
      setLoaders((prev) => [...prev].slice(0, -1));
    } catch (err) {
      console.log(err);
      setLoaders((prev) => [...prev].slice(0, -1));
    }
  }, [dispatch, fetchItemImages]);

  useEffect(() => {
    if (loaders.length > 0) {
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: true,
        })
      );
    } else {
      dispatch(resetLoaderState());
    }
  }, [dispatch, loaders]);

  useEffect(() => {
    fetchContent();
  }, [fetchContent]);

  const getAddressView = (address) => (
    <div className="w-50">
      <div className="estore-bold-text">{`${address.firstname} ${address.lastname}`}</div>
      <div className="estore-regular-text">{address.street}</div>
      <div className="estore-regular-text">{`${address.city}-${address.postcode}`}</div>
      <div className="estore-regular-text">{address.region?.region}</div>
      <div className="d-flex estore-regular-text mt-3">
        Mobile:
        <div className="ml-1 estore-bold-text">{address.telephone}</div>
      </div>
    </div>
  );

  const handleContinue = () => {
    if (isOutOfStock) {
      showToast('Please go back to cart screen and remove out of stock items');
      logEvent(
        ANALYTICS_EVENT_TYPES.ESTORE_CONFIRM_ADDRESS,
        '',
        ANALYTICS_ITEM_NAMES.DELIVERY_ESTIMATE
      );
    } else {
      logEvent(
        ANALYTICS_EVENT_TYPES.ESTORE_CONFIRM_ADDRESS,
        '',
        ANALYTICS_ITEM_NAMES.DELIVERY_ESTIMATE
      );
      history.replace(NAVIGATION_ROUTES.MI_STORE_ORDER_TC, { carrierCode });
    }
  };

  return (
    <div className="container">
      <SectionHeader identifier={ACCESSIBILITY_IDENTIFIERS.MICRO_STORE} />
      <BackButton backTitle="REVIEW ORDER" />
      <div className="e-store-cart-block">
        <div className="estore-confirm-address-note-block">
          <div className="estore-confirm-address-container mb-2">
            <div className="pb-2 mb-2 delivery-estimate-header">
              <div className="estore-bold-text">DELIVERY ADDRESS</div>
            </div>
            <div className="d-flex flex-row justify-content-between">
              {(Object.keys(tempAddress).length > 0 || Object.keys(defaultAddress).length > 0) &&
                getAddressView(Object.keys(tempAddress).length > 0 ? tempAddress : defaultAddress)}
            </div>
          </div>
          {carrierCode === 'tablerate' && <DeliveryNote />}
        </div>
        <div className="estore-items-container">
          <div className="pb-2 delivery-estimate-header">
            <div className="estore-bold-text">DELIVERY ESTIMATES</div>
          </div>
          {cartItems.map((item, index) => (
            <div
              key={index}
              className={
                index !== 0
                  ? 'estore-cart-item-block align-items-center'
                  : 'estore-cart-item-block-pos-0'
              }>
              <div className="e-store-address-confirm-item-image">
                <img src={item.image} className="e-store-item-image-address" />
              </div>
              <div className="pl-3">
                <div className="estore-confirm-address-item-description">
                  {carrierCode === 'tablerate'
                    ? estoreConfig.estimated_delivery_banglore
                    : estoreConfig.estimated_delivery}
                </div>
                {item.quantity < 5 && (
                  <div className="estore-out-of-stock-font mt-1">
                    {item.quantity <= 0 ? 'Out of stock' : `Only ${item.quantity} left`}
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
        <PriceInformationTable />
        <div className="estore-confirm-address-button">
          <div className="estore-cart-place-order-button w-50">
            <CustomButton buttonStyle="estore-add-to-cart-button" onClick={handleContinue}>
              CONTINUE
            </CustomButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeliveryEstimateScreen;
