import React, { useEffect, useState } from 'react';
import {
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  ANALYTICS_SCREEN_NAMES,
  NAVIGATION_ROUTES,
} from '../../common/constants/AppConstants';
import SectionHeader from '../../common/ui/section_header/SectionHeader';
import BackButton from '../../common/ui/common_back_button/BackButton';
import { useHistory } from 'react-router';
import { formatDateDDMonthYYYYEStore, getOrderId } from '../../utils/common';
import { useSelector } from 'react-redux';
import { selectEStoreConfig } from '../../state/MasterData';
import DeliveryNote from './components/DeliveryNote';
import Collections from './components/Collections';
import { logEvent, trackScreen } from '../../utils/FirebaseAnalyticsUtils';
import HtmlView from '../../common/ui/html_view/HTMLView';

const OrderDetailsScreen = ({ location }) => {
  const history = useHistory();
  const { orderDetails, images } = location.state;
  const estoreConfig = useSelector(selectEStoreConfig);
  const [showDeliveryNote, setShowDeliveryNote] = useState(false);

  useEffect(() => {
    trackScreen(
      ANALYTICS_SCREEN_NAMES.MICRO_STORE_ORDER_DETAILS_SCREEN,
      NAVIGATION_ROUTES.MI_STORE_ORDER_DETAILS
    );
  }, []);

  useEffect(() => {
    setShowDeliveryNote(
      orderDetails?.extension_attributes?.shipping_assignments[0]?.shipping.method ===
        'tablerate_bestway'
    );
  }, [orderDetails?.extension_attributes?.shipping_assignments]);

  const handleOrder = (item) => {
    const image = images.find((img) => img.sku === item.sku);
    const parentSku = image.parentSku;
    logEvent(ANALYTICS_EVENT_TYPES.ESTORE_PRODUCT_CLICK, parentSku, ANALYTICS_ITEM_NAMES.PRODUCT);
    history.push(`${NAVIGATION_ROUTES.MICRO_STORE_PRODUCT_SCREEN}/${parentSku}`);
  };

  const getShortDescription = (sku) => {
    const item = images.find((img) => img.sku === sku);
    return item?.shortDescription ?? '';
  };

  const getImage = (sku) => {
    const image = images.find((img) => img.sku === sku);
    return image?.image ?? '';
  };

  const getSize = (sku) => {
    const image = images.find((img) => img.sku === sku);
    return image.size;
  };

  return (
    <div className="container">
      <SectionHeader identifier={ACCESSIBILITY_IDENTIFIERS.MICRO_STORE} />
      <BackButton backTitle="ORDER DETAILS" />
      <div className="e-store-product-list-main">
        <div className="shipment-details-main-container">
          <div className="estore-order-history-container">
            <div className="estore-order-detail-block">
              <div className="estore-size-color-title-block">
                <div className="estore-size-color-font pb-1">ORDER DETAILS</div>
              </div>
              <div className="ecart-prices-list-container">
                <div className="ecart-order-details-item">
                  <div className="ecart-order-details-item-title">Order On</div>
                  <div className="ecart-order-details-item-colon">:</div>
                  <div className="estore-cart-item-price-text">
                    {formatDateDDMonthYYYYEStore(orderDetails.created_at)}
                  </div>
                </div>
                <div className="ecart-order-details-item">
                  <div className="ecart-order-details-item-title">Order No.</div>
                  <div className="ecart-order-details-item-colon">:</div>
                  <div className="estore-cart-item-price-text">
                    {getOrderId(orderDetails.items[0].order_id)}
                  </div>
                </div>
                <div className="ecart-order-details-item">
                  <div className="ecart-order-details-item-title">Order Total</div>
                  <div className="ecart-order-details-item-colon">:</div>
                  <div className="estore-cart-item-price-text">{`\u20b9 ${
                    orderDetails.subtotal + orderDetails.shipping_amount
                  }`}</div>
                </div>
              </div>
            </div>
          </div>
          <div className="order-details-item-list mb-2">
            {orderDetails.items.map((item, index) => (
              <div
                key={index}
                className={`order-details-item ${
                  index + 1 != orderDetails.items.length ? 'delivery-estimate-header mb-2' : ''
                }`}>
                <div
                  className="order-item-image-block common-cursor-pointer"
                  onClick={() => handleOrder(item)}>
                  <img src={getImage(item.sku)} className="order-item-image-block" />
                </div>
                <div className="w-100">
                  <div className="pb-2">
                    <div className="estore-bold-text">{item.name}</div>
                    <HtmlView
                      html={getShortDescription(item.sku)}
                      htmlStyle="estore-regular-text"
                    />
                    <div className="estore-regular-text margin-bottom-3">{`Qty: ${item.qty_ordered}`}</div>
                    {getSize(item.sku) && (
                      <div className="estore-regular-text margin-bottom-3">{`Size: ${getSize(
                        item.sku
                      )}`}</div>
                    )}
                    {orderDetails.status.length > 0 && (
                      <div className="estore-regular-text margin-bottom-3">
                        {orderDetails.status === 'canceled'
                          ? 'Cancelled'
                          : orderDetails.status[0].toUpperCase() + orderDetails.status.substring(1)}
                      </div>
                    )}
                    <div className="estore-bold-text pt-1">{`₹ ${
                      item.price * item.qty_ordered
                    }`}</div>
                    {(orderDetails.status === 'pending' ||
                      orderDetails.status === 'processing') && (
                      <div className="estore-regular-text pt-2">
                        {showDeliveryNote
                          ? estoreConfig.estimated_delivery_banglore
                          : estoreConfig.estimated_delivery}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
          <table className="estore-shipping-details-container">
            <tr>
              <td className="estore-shipping-details-header table-right-border">
                <div className="estore-bold-text">ORDER SUMMARY</div>
              </td>
              <td className="estore-shipping-details-header">
                <div className="estore-bold-text">SHIPPING ADDRESS</div>
              </td>
            </tr>
            <tr>
              <td className="estore-shipping-details-block table-right-border">
                <div className="estore-shipping-details">
                  <div className="ecart-prices-list-container">
                    <div className="ecart-price-details-item">
                      <div className="estore-cart-item-price-text">Subtotal</div>
                      <div className="estore-cart-item-price-text">{orderDetails.subtotal}</div>
                    </div>
                    <div className="ecart-price-details-item">
                      <div className="estore-cart-item-price-text">Shipping Fee</div>
                      <div className="estore-cart-item-price-text">{`\u20b9 ${orderDetails.shipping_amount}`}</div>
                    </div>
                  </div>
                  <div className="ecart-price-details-item store-top-border">
                    <div className="estore-cart-item-price-text-bold">Order Total</div>
                    <div className="estore-cart-item-price-text-bold">{`\u20b9 ${orderDetails.base_grand_total}`}</div>
                  </div>
                </div>
              </td>
              <td className="estore-shipping-details-block">
                <div>
                  <div className="estore-shipping-details mt-1">
                    <div className="d-flex estore-bold-text">
                      {orderDetails.billing_address.firstname}{' '}
                      {orderDetails.billing_address.lastname}
                    </div>
                    <div className="estore-regular-text">
                      {orderDetails.billing_address.street.join(', ')}
                    </div>
                    <div className="estore-regular-text">
                      {orderDetails.billing_address.city} - {orderDetails.billing_address.postcode}
                    </div>
                    <div className="estore-regular-text">{orderDetails.billing_address.region}</div>
                    <div className="d-flex estore-regular-text">
                      Mobile:
                      <div className="ml-1 estore-bold-text">
                        {orderDetails.billing_address.telephone}
                      </div>
                    </div>
                  </div>
                  {showDeliveryNote && <DeliveryNote />}
                </div>
              </td>
            </tr>
          </table>
        </div>
        <Collections />
      </div>
    </div>
  );
};

export default OrderDetailsScreen;
