import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  ERROR_MESSAGES,
  MICROGIVE_EVENT_TYPE,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
} from '../../../../common/constants/AppConstants';
import { selectMicrogiveCategories } from '../../../../state/MasterData';
import { apiRequest, handleError } from '../../../../services/Service';
import { GET_INITIATIVES } from '../../../../services/ApiUrls';
import VolunteerListItem from '../../components/VolunteerListItem';
import DonationFilterItem from '../../components/DonationFilterItem';
import LoadMoreBtn from '../../../../common/ui/load_more_btn/LoadMoreBtn';
import {
  saveProgressLoadingState,
  saveShowEmptyContentView,
  selectProgressLoadingState,
  selectShowEmptyContentView,
} from '../../../../state/UIState';
import DefaultContentView from '../../../../common/ui/default_content_view/DefaultContentView';
import {
  saveMicrogiveVolunteerCategoryId,
  selectMicrogiveVolunteerCategoryId,
} from '../../../../state/TabData';
import { logEvent } from '../../../../utils/FirebaseAnalyticsUtils';

const VolunteerTab = ({ onSetVolunteerMarkedDates, tabKey }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [volunteerList, setVolunteerList] = useState([]);
  const [pageId, setPageId] = useState(1);
  const [loadMoreFlag, setLoadMoreFlag] = useState(true);

  const volunteerCategoryId = useSelector(selectMicrogiveVolunteerCategoryId);

  const microGiveCategories = useSelector(selectMicrogiveCategories);
  const showEmptyContentView = useSelector(selectShowEmptyContentView);
  const progressLoadingState = useSelector(selectProgressLoadingState);

  useEffect(() => {
    logEvent(ANALYTICS_EVENT_TYPES.VOLUNTEER_TAB_VISIT, tabKey, ANALYTICS_ITEM_NAMES.MICROGIVE);
  }, [tabKey]);

  const callVolunteerApi = useCallback(
    async (id, loadMore, catId) => {
      dispatch(saveShowEmptyContentView(true));
      if (loadMore) {
        const params = {
          page_id: id,
          type: MICROGIVE_EVENT_TYPE.VOLUNTEER,
          category_id: catId,
        };
        try {
          dispatch(saveProgressLoadingState({ isProgressLoading: true }));
          const apiResponse = await apiRequest(GET_INITIATIVES, REQUEST_TYPE.GET, params);
          dispatch(saveProgressLoadingState({ isProgressLoading: false }));
          if (Object.keys(apiResponse).length > 0) {
            if (apiResponse.response.status === true) {
              if (apiResponse.response.data.initiatives.length > 0) {
                setVolunteerList((oldArray) => [
                  ...oldArray,
                  ...apiResponse.response.data.initiatives,
                ]);
                setLoadMoreFlag(true);
                if (apiResponse.response.data.dates.length > 0) {
                  let markedDatas = [];
                  apiResponse.response.data.dates.forEach((element) => {
                    markedDatas.push(element);
                  });
                  onSetVolunteerMarkedDates(markedDatas);
                }
              } else {
                setLoadMoreFlag(false);
              }
            }
          }
        } catch (err) {
          handleError(err, params, GET_INITIATIVES, NAVIGATION_ROUTES.VOLUNTEER_TABS_SCREEN);
          dispatch(saveProgressLoadingState({ isProgressLoading: false }));
        }
      }
    },
    [dispatch, onSetVolunteerMarkedDates]
  );

  useEffect(() => {
    callVolunteerApi(pageId, loadMoreFlag, volunteerCategoryId);
  }, [callVolunteerApi, loadMoreFlag, pageId, volunteerCategoryId]);

  const onPress = (item) => {
    dispatch(saveMicrogiveVolunteerCategoryId(item.category_id));
    setVolunteerList([]);
    setPageId(1);
    setLoadMoreFlag(true);
  };

  const onPressItem = (item) => {
    history.push(`${NAVIGATION_ROUTES.VOLUNTEER_TABS_SCREEN}/${item.initiative_id}`);
  };

  const onPressLoadMore = () => {
    setPageId((prev) => prev + 1);
  };

  const emptyListView = () => {
    return <DefaultContentView message={ERROR_MESSAGES.STAY_TUNED_FOR_UPCOMING_EVENTS} />;
  };

  return (
    <div className="main-content-container">
      <div className="microgive-category-container">
        {microGiveCategories.map((item, index) => {
          return (
            <DonationFilterItem
              key={`${index}`}
              item={item}
              onPress={onPress}
              categoryId={volunteerCategoryId}
            />
          );
        })}
      </div>
      {showEmptyContentView && !volunteerList.length && !progressLoadingState.isProgressLoading ? (
        emptyListView()
      ) : (
        <div>
          <div className="row">
            {volunteerList.map((item, index) => {
              return <VolunteerListItem item={item} onPress={onPressItem} key={`${index}`} />;
            })}
          </div>
          {loadMoreFlag && (
            <LoadMoreBtn
              onClick={onPressLoadMore}
              isLoading={progressLoadingState.isProgressLoading}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default VolunteerTab;
